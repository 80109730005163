import { LeadStatus, LeadSource } from 'models/Leads';

export const TRANSPARENT_STATUSES = [LeadStatus.ON_HOLD, LeadStatus.PENDING];

export const SOURCES_WITHOUT_PAYOUT = [
  LeadSource.IMPORT_HOMEAWAY,
  LeadSource.IMPORT_VRBO,
  LeadSource.IMPORT_BOOKINGCOM,
  LeadSource.IMPORT_ABRITEL,
  LeadSource.IMPORT_AIRBNB,
  LeadSource.IMPORT_TRIPADVISOR,
];

export const SOURCES_ORDER_NOT_EDITABLE = [
  LeadSource.DIRECT_AIRBNB,
  LeadSource.DIRECT_TRIPPING,
  LeadSource.DIRECT_BOOKINGDOTCOM,
];
