import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const Container = styled.section`
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 22px;

  ${mediaQuery.md} {
    flex-wrap: wrap;
    justify-content: center;
  }

  ${mediaQuery.sm} {
    flex-direction: column;
  }
`;

export const Card = styled.div`
  width: 358px;
  height: 222px;
  padding: 36px;
  font-size: 20px;
  font-weight: bold;
  border-radius: 10px;
  box-shadow: 10px 15px 25px 0px rgba(0, 0, 0, 0.15);

  img {
    margin-bottom: 15px;
  }

  ${mediaQuery.sm} {
    width: 100%;
  }
`;
