import { useContext } from 'react';
import TextField from 'components/forms/textField/TextField';
import { format, parse } from 'date-fns';
import RemoteLockImage from 'assets/images/partners/remotelock-logo.png';
import IglooHomeImage from 'assets/images/partners/igloohome.png';
import VirtualKeyImage from 'assets/images/partners/virtualkey.png';
import PointCentralImage from 'assets/images/partners/pointcentral.png';
import { PinCodeType, PinCode } from 'models/PinCode';
import { useTranslation } from 'react-i18next';
import LeadModalContext from '../../LeadModalContext';
import {
  PinCodeContainer,
  PinCodeInputWrapper,
  PinCodeWrapper,
} from './LeadModalStayDetailsTab.styles';

const pinCodeImages = {
  [PinCodeType.REMOTELOCK]: (
    <img alt="RemoteLock" width="100px" src={RemoteLockImage} />
  ),
  [PinCodeType.IGLOOHOME]: (
    <img alt="IglooHome" width="75px" src={IglooHomeImage} />
  ),
  [PinCodeType.VIRTUALKEY]: (
    <img alt="VirtualKey" width="100px" src={VirtualKeyImage} />
  ),
  [PinCodeType.POINTCENTRAL]: (
    <img alt="PointCentral" width="100px" src={PointCentralImage} />
  ),
};

const getPinCodeImage = (pinCode: PinCode) => {
  if (!pinCode) {
    return null;
  }

  const pinCodeImage = pinCodeImages[pinCode.lockType];

  if (pinCodeImage) {
    return pinCodeImage;
  }

  return null;
};

const PinCodeForm = () => {
  const { t } = useTranslation();
  const { pinCode } = useContext(LeadModalContext);

  const formatParsedDate = (date: string) => {
    return format(
      parse(date, 'yyyy-MM-dd HH:mm:ss', new Date()),
      'yyyy/MM/dd hh:mm a',
    );
  };

  return (
    <PinCodeContainer xs={9} xsOffset={3}>
      <p style={{ textAlign: 'center' }}>{getPinCodeImage(pinCode)}</p>

      <PinCodeWrapper $color="#ff6633">
        <span>
          {t('componentLead.modal.stayDetailsTab.pinCodeForm.accessPinCode')}
        </span>
        <PinCodeInputWrapper>
          <TextField
            name="pinCode.pinCode"
            placeholder="- - - - -"
            readOnly={pinCode?.lockType === PinCodeType.POINTCENTRAL}
          />
        </PinCodeInputWrapper>

        {pinCode?.lockName && <strong>{pinCode.lockName}</strong>}
      </PinCodeWrapper>

      <PinCodeWrapper>
        <span>
          {t('componentLead.modal.stayDetailsTab.pinCodeForm.accessPinCodeURL')}
        </span>
        <TextField
          name="pinCode.pinCodeUrl"
          placeholder="https://"
          readOnly={pinCode?.lockType === 'POINTCENTRAL'}
        />
      </PinCodeWrapper>

      {pinCode?.startDateTime && pinCode?.endDateTime && (
        <span className="pin-code-valid">
          {t('componentLead.modal.stayDetailsTab.pinCodeForm.pinValidFrom')}:{' '}
          {formatParsedDate(pinCode.startDateTime)}{' '}
          {t('componentLead.modal.stayDetailsTab.pinCodeForm.pinValidTo')}{' '}
          {formatParsedDate(pinCode.endDateTime)}
        </span>
      )}
    </PinCodeContainer>
  );
};

export default PinCodeForm;
