import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfoIcon from 'components/icons/InfoIcon';
import ToggleButton from 'components/button/ToggleButton';
import useFetchAgencyChannelSettings from '../../channelSettings/useFetchAgencyChannelSettings';
import useFetchPropertyChannelSettings from '../../channelSettings/useFetchPropertyChannelSettings';
import {
  PropertyChannelsList,
  PropertyChannelsListWrapper,
  PropertyChannelsWidgetContainer,
  PropertyChannelsWidgetInfoIconWrapper,
} from './PropertyChannelsWidget.styles';
import { channelsToShow } from './PropertyChannelsWidget.constants';
import PropertyChannelsWidgetListItem from './PropertyChannelsWidgetListItem';

const PropertyChannelsWidget = ({
  propertyName,
  propertyUid,
}: {
  propertyName: string;
  propertyUid: string;
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const {
    channelSettings: agencyChannelSettings,
    isFetching: isFetchingAgencyChannelSettings,
  } = useFetchAgencyChannelSettings(!!propertyUid);
  const {
    channelSettings: propertyChannelSettings,
    isFetching: isFetchingPropertyChannelSettings,
  } = useFetchPropertyChannelSettings(propertyUid);
  const { t } = useTranslation();

  if (isFetchingAgencyChannelSettings || isFetchingPropertyChannelSettings) {
    return null;
  }

  const toggleExpand = () => {
    setIsExpanded((current) => !current);
  };

  return (
    <PropertyChannelsWidgetContainer>
      <PropertyChannelsWidgetInfoIconWrapper $isExpanded={isExpanded}>
        <InfoIcon
          id="property-channel-widget-info"
          tooltipPlacement="bottom"
          tooltipText={t('componentPropertyChannels.tooltipInfo')}
        />
      </PropertyChannelsWidgetInfoIconWrapper>
      <PropertyChannelsListWrapper $isExpanded={isExpanded}>
        <ToggleButton onClick={toggleExpand} isExpanded={isExpanded} />
        <PropertyChannelsList>
          {channelsToShow.map((channel) => (
            <PropertyChannelsWidgetListItem
              channel={channel}
              agencyChannelSettings={agencyChannelSettings}
              key={channel}
              propertyChannelSettings={propertyChannelSettings}
              propertyName={propertyName}
              propertyUid={propertyUid}
            />
          ))}
        </PropertyChannelsList>
      </PropertyChannelsListWrapper>
    </PropertyChannelsWidgetContainer>
  );
};

export default PropertyChannelsWidget;
