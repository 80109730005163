import { DiscountCodeType } from './Discounts';
import { FeeAmountType as PropertyFeeAmountType } from './PropertyFees';

export enum FeeAmountType {
  AMOUNT = 'AMOUNT',
  TAX = 'TAX',
}

export enum FeeScopeType {
  PER_NIGHT = 'PER_NIGHT',
  PER_STAY = 'PER_STAY',
  PER_GUEST_PER_NIGHT = 'PER_GUEST_PER_NIGHT',
  PER_GUEST = 'PER_GUEST',
}

export enum FeeType {
  CLEANING,
  EARLY_ARRIVAL,
  LATE_ARRIVAL,
  PET,
  TAX,
  CUSTOM,
}
export enum AdjustmentType {
  RENT = 'RENT',
  TAX = 'TAX',
  FEE = 'FEE',
  SERVICE = 'SERVICE',
  RESOLUTION = 'RESOLUTION',
}

export enum AdjustmentTransactionType {
  CHARGE = 'CHARGE',
  REFUND = 'REFUND',
}

export interface QuoteFeeOverride {
  feeId?: number;
  feeUid?: string;
  name: string;
  amountType: PropertyFeeAmountType;
  amount: number;
  taxationRate?: number;
  scope?: FeeScopeType;
  type?: FeeType;
  isOptional?: boolean;
  netPrice: number;
  enabled: boolean;
  removed: boolean;
}

export interface QuoteFeesOverride {
  cleaningFee: Price;
  otherFees: QuoteFeeOverride[];
}

export interface QuotRentOverride {
  rentNetPrice: number;
  extraGuestsNetPrice: number;
  taxationRate: number;
  taxAmount: number;
}

export interface QuoteOverride {
  fees: QuoteFeesOverride;
  rent: QuotRentOverride;
  force100PercentPaymentAtReservation: boolean;
  totalPrice: number;
  securityDeposit: number;
}

export interface QuoteRentBreakdown {
  nightlyAmount: number;
  nightlyBaseAmount: number;
  nightlyDate: string;
}

export interface Discount {
  discountCode: string;
  discountType: DiscountCodeType;
  amount: number;
  percent: number;
}

export interface QuoteRent {
  rentNetPrice: number;
  rentBaseNetPrice: number;
  rentBreakdowns?: QuoteRentBreakdown[];
  extraGuestsNetPrice: number;
  extraGuestsBaseNetPrice: number;
  rateMultiplier: number;
  rateMultiplierAmount: number;
  listPrice: number;
  discount: Discount;
  netPrice: number;
  taxationRate: number;
  taxAmount: number;
  grossPrice: number;
}

export interface Price {
  netPrice: number;
  taxationRate: number;
  taxAmount: number;
  grossPrice: number;
}

export interface QuoteFee {
  feeId: number;
  name: string;
  amountType: FeeAmountType;
  isEditable: boolean;
  netPrice: number;
  taxationRate: number;
  taxAmount: number;
  grossPrice: number;
}

export interface QuoteFees {
  cleaningFee: Price;
  otherFees: QuoteFee[];
}

export interface QuoteService {
  title: string;
  grossPrice: number;
}

export interface QuoteAdjustment {
  id: number;
  isPaid: boolean;
  amount: number;
}

export interface QuoteAdjustmentItem {
  uid: string;
  amount: number;
  note: string;
  adjustmentType: AdjustmentType;
  transactionType: AdjustmentTransactionType;
}

export interface QuoteResponse {
  quote: Quote;
}

export interface Quote {
  rent: QuoteRent;
  securityDeposit: number;
  includeSecurityDepositInTotal: boolean;
  fees: QuoteFees;
  services: QuoteService[];
  adjustments: QuoteAdjustment[];
  totalAmount: number;
  totalPrice: number;
  currency: string;
  overrides?: QuoteOverride;
  adjustmentItems: QuoteAdjustmentItem[];
}
