import { useTranslation } from 'react-i18next';
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Lead } from 'models/Leads';
import { normalizeKey } from 'utils/localeUtils';
import useLeadCountdownDisplayValue from '../countdown/useLeadCountdownDisplayValue';
import {
  statusesApplicableForShowingIcon,
  statusIndicatorAvailableIcons,
  statusIndicatorColorToHex,
  statusIndicatorInactiveColor,
  statusToIndicatorPropsMap,
} from './LeadCircle.constants';
import LeadCircleIcon from './leadCircleIcon/LeadCircleIcon';
import { LeadCircleContainer, LeadStatusContainer } from './LeadCircle.styles';

interface Props {
  inactiveLabelKey?: string;
  lead: Lead;
}

const LeadCircle = ({ inactiveLabelKey, lead }: Props) => {
  const countdownDisplayValue = useLeadCountdownDisplayValue(lead);
  const { t } = useTranslation();

  if (inactiveLabelKey) {
    const styles = buildStyles({
      pathColor: statusIndicatorInactiveColor,
      textColor: statusIndicatorInactiveColor,
    });
    const text = t(
      normalizeKey(
        `componentLead.statusIndicator.inactive.${inactiveLabelKey}`,
      ),
    );

    return (
      <LeadCircleContainer>
        <CircularProgressbarWithChildren styles={styles} value={0}>
          <LeadStatusContainer $color={statusIndicatorInactiveColor}>
            {text}
          </LeadStatusContainer>
        </CircularProgressbarWithChildren>
      </LeadCircleContainer>
    );
  }

  const { countdown, source, status } = lead;
  const indicatorProps = statusToIndicatorPropsMap[status];

  if (!countdown || !indicatorProps) {
    return null;
  }

  const icons = statusesApplicableForShowingIcon.includes(status)
    ? statusIndicatorAvailableIcons
        .filter(({ shouldDisplay }) => shouldDisplay(lead))
        .map(({ position, src, tooltipKey }) => (
          <LeadCircleIcon
            id={tooltipKey}
            key={`lead-status-indicator-icon-${tooltipKey}`}
            src={src}
            tooltip={t(normalizeKey(`componentLead.details.${tooltipKey}`))}
            position={position}
          />
        ))
    : [];

  const { color, countdownPercentage } = countdown;
  const { labelKey, sourceSpecificProps, labelKeyFactory } = indicatorProps;
  const finalLabelKey =
    (sourceSpecificProps &&
      sourceSpecificProps[source] &&
      sourceSpecificProps[source].labelKey) ||
    labelKeyFactory?.(lead) ||
    labelKey;

  const colorHex = statusIndicatorColorToHex[color];
  const styles = buildStyles({
    pathColor: colorHex,
    textColor: colorHex,
  });

  const text = t(
    normalizeKey(`componentLead.statusIndicator.${finalLabelKey}`),
    {
      value: countdownDisplayValue,
    },
  );

  const value =
    typeof countdownPercentage === 'number' && countdownPercentage >= 0
      ? countdownPercentage * 100
      : 100;

  return (
    <LeadCircleContainer className="lead-status-indicator-active">
      {icons}
      <CircularProgressbarWithChildren styles={styles} value={value}>
        <LeadStatusContainer $color={colorHex}>{text}</LeadStatusContainer>
      </CircularProgressbarWithChildren>
    </LeadCircleContainer>
  );
};

LeadCircle.defaultProps = {
  inactiveLabelKey: undefined,
};

export default LeadCircle;
