import { useEffect, useRef, useState } from 'react';
import ReactSelect, {
  components,
  ValueContainerProps,
  MultiValueGenericProps,
} from 'react-select';
import GenericFormField from '../genericFormField/GenericFormField';
import { GenericFormFieldBaseProps } from '../genericFormField/GenericFormField.types';
import { BaseOption, ReactMultiSelectProps } from './MultiSelectField.types';
import useHandleSelectAllOption from './useHandleSelectAllOption';
import {
  SELECT_ALL_OPTION_VALUE,
  isSelectAll,
} from './MultiSelectField.constants';

export interface MultiSelectFieldProps
  extends Omit<GenericFormFieldBaseProps, 'fieldRenderer' | 'placeholder'> {
  disabled?: boolean;
  formatOptionLabel?: ReactMultiSelectProps['formatOptionLabel'];
  isSelectAllEnabled?: boolean;
  name: string;
  options: ReactMultiSelectProps['options'];
  placeholder?: ReactMultiSelectProps['placeholder'];
}

const { MultiValueContainer, ValueContainer } = components;

const CustomValueContainer = ({ children, ...rest }: ValueContainerProps) => {
  const hasSelectAllValue = !!rest.getValue().find(isSelectAll);
  const selectedCount = rest.getValue().length - (hasSelectAllValue ? 1 : 0);

  return (
    <ValueContainer {...rest}>
      {selectedCount > 2 ? (
        <div className="selected-count">{selectedCount} selected</div>
      ) : (
        children
      )}
    </ValueContainer>
  );
};

const CustomMultiValueContainer = (
  props: MultiValueGenericProps<BaseOption, true>,
) => {
  const { data } = props;

  // Does not render pill if the option is "Select All"
  if (data.value === SELECT_ALL_OPTION_VALUE) {
    return null;
  }

  return <MultiValueContainer {...props} />;
};

const MultiSelectField = ({
  disabled,
  formatOptionLabel,
  isSelectAllEnabled,
  name,
  options,
  placeholder,
  ...rest
}: MultiSelectFieldProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const selectRef = useRef(null);
  const { selectAllOption, handleOnChangeWithSelectAll } =
    useHandleSelectAllOption({ name, options: options as BaseOption[] });

  useEffect(() => {
    const handleClick = (event: any) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [selectRef]);

  const optionsWithSelectAll = options?.length
    ? [isSelectAllEnabled && selectAllOption, ...options].filter(Boolean)
    : [];

  const fieldRenderer = ({ field: { onChange, value } }) => (
    <div ref={selectRef}>
      <ReactSelect
        isMulti
        onChange={(...args) => {
          if (isSelectAllEnabled) {
            handleOnChangeWithSelectAll(...args);
          } else {
            onChange(...args);
          }
        }}
        value={value}
        options={optionsWithSelectAll}
        className="basic-multi-select"
        classNamePrefix="select"
        isSearchable
        menuIsOpen={isMenuOpen}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
        components={{
          ValueContainer: CustomValueContainer,
          MultiValueContainer: CustomMultiValueContainer,
        }}
        formatOptionLabel={formatOptionLabel}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        placeholder={placeholder}
        styles={{
          menu: (base) => ({
            ...base,
            zIndex: 999,
          }),
        }}
        isDisabled={disabled}
      />
    </div>
  );

  return (
    <GenericFormField name={name} fieldRenderer={fieldRenderer} {...rest} />
  );
};

MultiSelectField.defaultProps = {
  disabled: undefined,
  formatOptionLabel: undefined,
  isSelectAllEnabled: undefined,
  placeholder: undefined,
};

export default MultiSelectField;
