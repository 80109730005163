export const CURRENCIES = [
  'AED',
  'ARS',
  'AUD',
  'BGN',
  'BRL',
  'CAD',
  'CHF',
  'CLP',
  'CNY',
  'COP',
  'CZK',
  'DKK',
  'EUR',
  'GBP',
  'GTQ',
  'HKD',
  'HUF',
  'IDR',
  'IKR',
  'ILS',
  'INR',
  'JPY',
  'KRW',
  'MXN',
  'MYR',
  'NOK',
  'NONE',
  'NZD',
  'PEN',
  'PHP',
  'PLN',
  'RON',
  'RUB',
  'SEK',
  'SGD',
  'THB',
  'TRY',
  'TWD',
  'USD',
  'UYU',
  'VEF',
  'VND',
  'VUV',
  'JOD',
  'ZAR',
] as const;

export const CURRENCIES_WITHOUT_NONE = CURRENCIES.filter(
  (currency) => currency !== 'NONE',
);

export type Currency = (typeof CURRENCIES)[number];

export type CurrencyWithoutNone = Exclude<Currency, 'NONE'>;
