import styled from 'styled-components';
import { Breadcrumb } from 'react-bootstrap';
import theme from 'styles/theme';

export const PropertySettingsTabHeaderContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
`;

export const PropertySettingsTabHeaderButtons = styled.div`
  display: flex;
  gap: 1rem;

  & a,
  & button {
    align-items: center;
    display: flex;
    gap: 8px;
    height: fit-content;
    padding-left: 0;
    padding-right: 0;
  }
`;

export const PropertySettingsBreadcrumb = styled(Breadcrumb)`
  align-items: center;
  background-color: #fff;
  display: flex;
  padding: 0;

  & > li {
    position: relative;

    &.active > span:last-child {
      color: ${theme.colors.hostfullyGreen};
    }
  }

  & > li + li {
    margin-left: 25px;
  }

  & > li + li:before {
    content: '>';
    position: absolute;
    top: 50%;
    left: -12px;
    transform: translate(-50%, -50%);
  }

  .form-field-container {
    display: inline-block;
    margin: 2px 0 0 0;
  }

  .hotel-link {
    margin: 0;
    padding: 0;
  }
`;
