import { CustomData, GetCustomDataResponseTO } from 'models/CustomData';
import useAppUser from '../../../hooks/useAppUser';
import useAppQuery from '../../../hooks/useAppQuery';
import API from '../../../services/API';

const useFetchLeadCustomData = (leadUid: string) => {
  const { isEmployee } = useAppUser();
  const { data: customData, isInitialLoading: isFetching } = useAppQuery(
    ['custom-data', leadUid],
    async (): Promise<CustomData[]> => {
      const response = await API.get<GetCustomDataResponseTO>(
        `api/v3/custom-data?leadUid=${leadUid}`,
      );
      return response.data.customData;
    },
    {
      enabled: isEmployee && !!leadUid,
    },
  );

  return { isFetching, customData };
};

export default useFetchLeadCustomData;
