import { useTranslation } from 'react-i18next';
import useFetchAllProperties from 'components/domain/property/useFetchAllProperties';
import MultiSelectField from 'components/forms/multiSelectField/MultiSelectField';
import { formatOptionLabelWithHighlight } from 'components/forms/multiSelectField/formatOptionLabel';
import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import getPropertiesQuery from 'utils/property/getPropertiesQuery';
import { API_MAX_LIMIT } from 'services/API.constants';
import { PropertyMultiSelectFieldProps } from './PropertyMultiSelectField.types';

const PropertyMultiSelectField = ({
  graphQlFilters,
  filterPropertyBy,
  ...props
}: PropertyMultiSelectFieldProps) => {
  const { t } = useTranslation();
  const { allProperties, isFetching } = useFetchAllProperties({
    conditions: getPropertiesQuery({
      filters: { limit: API_MAX_LIMIT, ...(graphQlFilters ?? {}) },
    }),
    fields: 'uid,name,businessType,numberOfSubUnits',
    operationName: 'MultiSelectFieldProperties',
    enabled: true,
  });

  if (isFetching) {
    return <LoadingPlaceholder />;
  }

  const options = allProperties
    ?.filter(filterPropertyBy ?? (() => true))
    .map(({ uid, name }) => ({
      label: name,
      value: uid,
    }));

  return (
    <MultiSelectField
      placeholder={t(
        'form.propertyMultiSelectField.placeholderSearchPropertyName',
      )}
      options={options}
      formatOptionLabel={formatOptionLabelWithHighlight}
      {...props}
    />
  );
};

PropertyMultiSelectField.defaultProps = {
  graphQlFilters: undefined,
  filterPropertyBy: undefined,
};

export default PropertyMultiSelectField;
