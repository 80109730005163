import { Glyphicon } from 'react-bootstrap';
import styled from 'styled-components';

export const PropertyChannelsWidgetContainer = styled.div.attrs({
  'data-testid': 'property-channels-widget-container',
})`
  position: fixed;
  right: 0px;
  top: 210px;
  width: 154px;
  z-index: 99;
`;

export const PropertyChannelsWidgetInfoIconWrapper = styled.div<{
  $isExpanded: boolean;
}>`
  position: absolute;
  top: -10px;
  left: ${({ $isExpanded }) => ($isExpanded ? 0 : 90)}px;
  z-index: 9;
  transition: left 0.5s linear;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
`;

export const PropertyChannelsWidgetToggleButton = styled.button`
  background-color: transparent;
  border: none;
  padding-bottom: 10px;
`;

export const PropertyChannelsListWrapper = styled.div<{ $isExpanded: boolean }>`
  height: 308px;
  position: absolute;
  right: ${({ $isExpanded }) => ($isExpanded ? 0 : -90)}px;
  transition: right 0.5s linear;
  background-color: white;
  padding: 10px;
  box-shadow: 0 0 2px rgb(0 0 0 / 40%);
  border-radius: 8px 0px 0px 8px;
  overflow: auto;
  top: 0px;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const PropertyChannelsList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;

  > li:not(:last-child) {
    margin-bottom: 6px;
  }

  > li:not(:last-child) > div:after {
    content: ' ';
    position: absolute;
    bottom: 0;
    width: 50%;
    left: 25%;
    border-bottom: 1px solid #eee;
  }
`;

export const PropertyChannelsListItem = styled.div.attrs({
  'data-testid': 'property-channels-list-item',
})`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-bottom: 10px;
  position: relative;
`;

export const PropertyChannelIconContainer = styled.div.attrs({
  'data-testid': 'property-channel-icon',
})`
  margin-right: 15px;
`;

export const PropertyChannelIcon = styled.img<{ $isActive: boolean }>`
  border: 0.09em solid #e6e6e6;
  border-radius: 50% 50% 50% 50%;
  height: 35px;
  padding: 0.2em;
  width: 35px;

  ${({ $isActive }) =>
    !$isActive &&
    `
    filter: grayscale(100%);
    opacity: 0.7;
  `}
`;

export const PropertyChannelNameContainer = styled.div.attrs({
  'data-testid': 'property-channel-name',
})``;

export const PropertyChannelNameInactive = styled.span`
  color: #cac7c7;
`;

export const PropertyChannelLinkEditIcon = styled(Glyphicon).attrs({
  'data-testid': 'property-channel-link-edit-icon',
  glyph: 'pencil',
})`
  cursor: pointer;
  font-size: 12px;
  margin-left: 4px;
`;
