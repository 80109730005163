import FilterContainer from 'components/filters/FilterContainer';
import PageHeader from 'components/pageHeader/PageHeader';
import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';
import theme from 'styles/theme';

export const AgencyName = styled.span`
  color: ${theme.colors.hostfullyGreen};
`;

export const PropertiesCount = styled.span``;

export const ActionsWrapper = styled.div`
  display: flex;
  flex: 1;
  gap: 15px;
  justify-content: end;

  .property-search-field {
    margin-bottom: 0;

    input[role='combobox'] {
      width: 160px;
    }
  }

  ${mediaQuery.xs} {
    width: 100%;

    .property-search-field {
      flex: 1;

      input[role='combobox'] {
        width: 100%;
      }
    }
  }
`;

export const PageHeaderStyled = styled(PageHeader)`
  align-items: center;
  column-gap: 15px;
  display: flex;
  flex-wrap: wrap;

  h1 {
    align-self: start;
  }

  .divider {
    order: 4;
    width: 100%;
  }

  ${ActionsWrapper} {
    align-self: flex-end;
  }

  ${mediaQuery.xs} {
    flex-direction: column;

    h1 {
      order: 1;
    }

    .divider {
      order: 2;
    }

    ${ActionsWrapper} {
      order: 3;
    }
  }
`;

export const PropertiesFilterFormContainer = styled(FilterContainer)`
  padding-bottom: 0.5em;
  margin-bottom: 1.5em;
`;
