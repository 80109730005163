import { Lead, LeadStatus } from 'models/Leads';
import { ThreadFilterFormLeadStatusesMap } from './InboxFilter.constants';

const formValuesToThreadFilterFieldMappers = {
  lead: (leads: Lead[]) => ({
    leadUid: leads?.length ? leads[0].uid : '',
  }),
  leadStatus: (leadStatus: LeadStatus | string) => ({
    leadStatuses: ThreadFilterFormLeadStatusesMap[leadStatus] || [],
  }),
  showUnreadOnly: (showUnreadOnly: string) => ({
    showOnlyUnread: !!showUnreadOnly, // showOnlyUnread is the param name expected by the backend
  }),
};

export const inboxGraphQLQuery = `
    uid,
    name,
    businessType,
    subUnits{
      uid,
      name,
      businessType,
    },
    unitTypes{
      uid,
      name,
      businessType,
    },
    pricing{currency},
  `;

export const inboxCalendarConditionGraphQLQuery = `offset:"0",limit:"9999",isActive:true, topLevelOnly:true,returnTopLevelUnitsWithMatchingSubLevels:true,sortStrategy:"SORT_BY_NAME"`;

export const getUpdatedThreadFilter = (formValues) => {
  return Object.entries(formValues).reduce((values, entry) => {
    const key = entry[0];
    const value = entry[1];
    const fieldMapper = formValuesToThreadFilterFieldMappers[key];
    return {
      ...values,
      ...(fieldMapper ? fieldMapper(value) : { [key]: value }),
    };
  }, {});
};
