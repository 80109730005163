import { FormControl } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import GenericFormField from '../genericFormField/GenericFormField';
import {
  FieldRendererProps,
  GenericFormFieldProps,
} from '../genericFormField/GenericFormField.types';
import TextFieldCharacterCounter from './TextFieldCharacterCounter';

interface Props extends Omit<GenericFormFieldProps, 'fieldRenderer'> {
  maxCharacters?: number;
  trimOnBlur?: boolean;
}

/**
 * This is a wrapper around the react-bootstrap FormControl component. It should be
 * the standard way to use Input in the app.
 * Any new modifications in the UI (changing design library) should be done here.
 */
const TextField: React.FC<Props> = ({
  name,
  id = name,
  maxCharacters,
  trimOnBlur,
  ...props
}: Props) => {
  const { setValue } = useFormContext();
  const fieldRenderer = ({
    field: { value, ref, onChange, onBlur },
  }: FieldRendererProps) => {
    return (
      <>
        <FormControl
          id={id}
          name={name}
          data-testid={id}
          value={value}
          onChange={onChange}
          onBlur={() => {
            onBlur();
            if (trimOnBlur) setValue(name, value.trim());
          }}
          inputRef={ref}
          {...props}
        />
        {!!maxCharacters && (
          <TextFieldCharacterCounter
            name={name}
            maxCharacters={maxCharacters}
          />
        )}
      </>
    );
  };

  const { containerClassName: containerClassNameOrig } = props;
  const containerClassName = maxCharacters
    ? `has-character-counter${
        containerClassNameOrig ? ` ${containerClassNameOrig}` : ''
      }`
    : containerClassNameOrig;

  return (
    <GenericFormField
      name={name}
      fieldRenderer={fieldRenderer}
      containerClassName={containerClassName}
      {...props}
    />
  );
};

TextField.defaultProps = {
  maxCharacters: undefined,
  trimOnBlur: false,
};

export default TextField;
