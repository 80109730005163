import { useContext, useEffect } from 'react';
import { createThreadRelatedLeadFromLead } from 'utils/lead/leadUtils';
import useAppUser from 'hooks/useAppUser';
import useAppQuery from 'hooks/useAppQuery';
import API from 'services/API';
import {
  GetThreadRelatedLeadsResponseTO,
  ThreadRelatedLead,
} from 'models/inbox/Threads';
import { Lead } from 'models/Leads';
import useSearchURLParams, { URLParamsName } from 'hooks/useSearchURLParams';
import InboxContext from '../InboxContext';
import InboxLeadsContext from './InboxLeadsContext';
import useInboxLeadMissingProperties from './useInboxLeadMissingProperties';

const useInboxActiveLead = () => {
  const { agencyUid } = useAppUser();
  const { activeThread, activeThreadLeadUid, updateActiveLead } =
    useContext(InboxContext);
  const {
    leadsMap,
    selectedLeadUid,
    threadRelatedLeadsMap,
    setSelectedLeadUid,
    updateLeadsMap,
    updateThreadRelatedLeadsMap,
  } = useContext(InboxLeadsContext);
  const fetchMissingProperties = useInboxLeadMissingProperties();
  const { setParameter, deleteParameter } = useSearchURLParams();
  const { uid: activeThreadUid } = activeThread || {};

  const threadRelatedLeads = activeThreadUid
    ? threadRelatedLeadsMap[activeThreadUid]
    : null;

  const {
    data: fetchedThreadRelatedLeads,
    isInitialLoading: isThreadRelatedLeadsLoading,
  } = useAppQuery(
    ['threadRelatedLeads', activeThreadUid],
    async (): Promise<ThreadRelatedLead[]> => {
      const threadLeadsResponse =
        await API.get<GetThreadRelatedLeadsResponseTO>(
          '/v3/thread-related-leads',
          {
            params: {
              limit: 20,
              threadUid: activeThreadUid,
            },
          },
        );
      return threadLeadsResponse.data.threadRelatedLeads;
    },
    {
      enabled: !!activeThreadUid && !threadRelatedLeads,
    },
  );

  const { data: fetchedLead, isInitialLoading: isActiveLeadLoading } =
    useAppQuery(
      ['activeLead', selectedLeadUid],
      async (): Promise<Lead> => {
        const leadsResponse = await API.post('/v3/graphql', {
          // for all the available fields check com.orbirental.api.v3.TOs.graphql.LeadGraphQLApiTO
          query: `{leads (agencyUid:"${agencyUid}",uids:["${selectedLeadUid}"]){
            uid,
            adultCount,
            agreementSigned,
            assignee{bestProfile, uid},
            availablePipelineActions,
            channel{bestProfile, channel},
            checkInLocalDateTime,
            checkOutLocalDateTime,
            childrenCount,
            countdown{color, countdownMinutes, countdownPercentage, visualCountdownValue},
            email,
            hasCompletedPreArrivalForm,
            infantCount,
            order{currency, totalAmount},
            petCount,
            property{uid},
            source,
            status,
            wereCheckInInstructionsSent
          }}`,
          variables: {},
        });
        return leadsResponse?.data?.data?.leads?.length
          ? leadsResponse.data.data.leads[0]
          : null;
      },
      {
        enabled:
          !!selectedLeadUid &&
          !!threadRelatedLeads &&
          !leadsMap[selectedLeadUid],
      },
    );

  useEffect(() => {
    const matchedLeadUid =
      activeThreadLeadUid ||
      (threadRelatedLeads?.length && threadRelatedLeads[0].leadUid);

    setSelectedLeadUid(matchedLeadUid);
  }, [threadRelatedLeads]);

  useEffect(() => {
    if (selectedLeadUid) {
      updateActiveLead(leadsMap[selectedLeadUid]);
      setParameter(URLParamsName.LEAD_UID, selectedLeadUid);
    } else {
      updateActiveLead(null);
      deleteParameter(URLParamsName.LEAD_UID);
    }
  }, [selectedLeadUid]);

  useEffect(() => {
    if (fetchedThreadRelatedLeads) {
      updateThreadRelatedLeadsMap({
        threadUid: activeThreadUid,
        leads: fetchedThreadRelatedLeads,
      });

      fetchMissingProperties(fetchedThreadRelatedLeads);
    }
  }, [fetchedThreadRelatedLeads]);

  useEffect(() => {
    if (fetchedLead) {
      updateLeadsMap(fetchedLead);

      const threadLeads = threadRelatedLeadsMap[activeThreadUid];
      if (threadLeads) {
        const { uid } = fetchedLead;
        const threadLead = threadLeads.find(({ leadUid }) => leadUid === uid);

        if (!threadLead) {
          updateThreadRelatedLeadsMap({
            threadUid: activeThreadUid,
            leads: [
              ...threadLeads,
              createThreadRelatedLeadFromLead(fetchedLead, activeThreadUid),
            ],
          });
        }
      } else {
        updateThreadRelatedLeadsMap({
          threadUid: activeThreadUid,
          leads: [
            createThreadRelatedLeadFromLead(fetchedLead, activeThreadUid),
          ],
        });
      }

      updateActiveLead(fetchedLead);
    }
  }, [fetchedLead]);

  return {
    isLoading: !!activeThreadUid && isThreadRelatedLeadsLoading,
    isLoadingLead: !!activeThreadUid && isActiveLeadLoading,
  };
};

export default useInboxActiveLead;
