import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Loader from 'components/loader/Loader';
import Button from 'components/button/Button';
import Tooltip from 'components/tooltip/Tooltip';
import { LeadAvailablePipelineAction } from 'models/Leads';
import useLeadActions, { buttonStyles } from '../actions/useLeadActions';
import useLeadActionsPermission from '../actions/useLeadActionsPermission';
import LeadModalContext from './LeadModalContext';
import {
  LeadModalFooterActionsContainer,
  LeadModalFooterAirbnbDocsMessage,
} from './footer/LeadModalFooterMessages.styles';
import LeadModalFooterMessages from './footer/LeadModalFooterMessages';

const LeadModalFooterActionButtons = ({
  updateLeadCallback,
  errorMessage,
}: {
  updateLeadCallback: () => void;
  errorMessage: string;
}) => {
  const { lead } = useContext(LeadModalContext);
  const { t } = useTranslation();
  const { airbnbActions, standardActions, isExecutingAction, takeAction } =
    useLeadActions({ lead });
  const canExecutePipelineActions = useLeadActionsPermission(lead);

  if (!airbnbActions.length && !standardActions.length) {
    return null;
  }

  if (isExecutingAction) {
    return (
      <LeadModalFooterActionsContainer>
        <Loader />
      </LeadModalFooterActionsContainer>
    );
  }

  const handleAction = (action: LeadAvailablePipelineAction) => {
    takeAction({
      action,
      lead,
      updateLeadCallback,
    });
  };

  // prettier breaks locale Trans component interpolation, so it must be excluded from formatting
  // prettier-ignore
  const docsLink = (
    <Trans i18nKey="componentLead.actionsInfo.airbnb.docDetails">
      Please see <a
        href="https://help.hostfully.com/en/articles/4623220-airbnb-inquiry-booking-request-auto-responses"
        rel="noreferrer"
        target="_blank"
      >
        online documentation
      </a> for details
    </Trans>
  );

  const buttonsDisabled = !canExecutePipelineActions;

  const buttons = [...airbnbActions, ...standardActions].map((action) => (
    <Button
      bsStyle={buttonStyles[action] || 'success'}
      disabled={buttonsDisabled}
      onClick={() => handleAction(action)}
      data-testid={`lead-action-button-${action}`}
      key={`lead-action-button-${action}`}
    >
      {t(`componentLead.actions.${action}`)}
    </Button>
  ));

  return (
    <LeadModalFooterActionsContainer>
      {!!airbnbActions.length && (
        <LeadModalFooterAirbnbDocsMessage>
          <div>{t('componentLead.actionsInfo.airbnb.declineNote')}</div>
          <div>{docsLink}</div>
        </LeadModalFooterAirbnbDocsMessage>
      )}

      <LeadModalFooterMessages errorMessage={errorMessage} />

      {buttonsDisabled
        ? buttons.map((button) => (
            <Tooltip
              id={`${button.key}-tooltip`}
              key={`${button.key}-tooltip`}
              text={t('componentLead.actionsInfo.noPermission')}
            >
              {button}
            </Tooltip>
          ))
        : buttons}
    </LeadModalFooterActionsContainer>
  );
};

export default LeadModalFooterActionButtons;
