import { useMemo } from 'react';
import AdjustmentModalContext from './PropertyPricingModalContext';

const PropertyPricingModalContextProvider = ({ children, modalId }) => {
  const contextValue = useMemo(
    () => ({
      modalId,
    }),
    [modalId],
  );

  return (
    <AdjustmentModalContext.Provider value={contextValue}>
      {children}
    </AdjustmentModalContext.Provider>
  );
};

export default PropertyPricingModalContextProvider;
