import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  input[type='file'] {
    display: none;
  }

  button {
    svg {
      margin-bottom: -2px;
      margin-right: 10px;
    }
  }
`;
