import { LeadAvailablePipelineAction } from '../../../../models/Leads';

export const leadMessagingActions = [
  LeadAvailablePipelineAction.PLACE_ON_HOLD,
  LeadAvailablePipelineAction.SEND_ALTERNATIVE_OPTIONS,
  LeadAvailablePipelineAction.SEND_BALANCE_REQUEST,
  LeadAvailablePipelineAction.SEND_CHECK_IN_INSTRUCTIONS,
  LeadAvailablePipelineAction.SEND_MINIMUM_STAY_NOTE,
  LeadAvailablePipelineAction.SEND_PAYMENT_REMINDER,
  LeadAvailablePipelineAction.SEND_PRE_ARRIVAL_FORM,
  LeadAvailablePipelineAction.SEND_QUOTE,
  LeadAvailablePipelineAction.SEND_REMINDER,
  LeadAvailablePipelineAction.PLACE_ON_HOLD,
  LeadAvailablePipelineAction.SEND_CHECK_IN_INSTRUCTIONS,
];

export const leadPendingActions = [
  LeadAvailablePipelineAction.ACCEPT_PENDING,
  LeadAvailablePipelineAction.AIRBNB_PRE_APPROVE,
  LeadAvailablePipelineAction.AIRBNB_PRE_APPROVE_WITH_HOLD,
  LeadAvailablePipelineAction.DECLINE_PENDING,
];
export const leadActionsPopoverContainerClassName =
  'lead-actions-popover-container';

export const LeadAvailablePipelineActionPriorities: {
  [key in LeadAvailablePipelineAction]: number;
} = {
  ASSIGN_TO_ME: 1000,
  OPEN_INBOX: 1000,
  ACCEPT_PENDING: 900,
  REVIEW: 900,
  AIRBNB_PRE_APPROVE: 900,
  AIRBNB_PRE_APPROVE_WITH_HOLD: 900,
  DECLINE_PENDING: 900,
  REOPEN: 800,
  PLACE_ON_HOLD: 800,
  EXTEND_HOLD: 800,
  MARK_AS_BOOKED: 800,
  REBOOK: 800,
  REBOOK_AS_NEW_LEAD: 800,
  MARK_AS_PAID: 700,
  RECORD_PAYMENT: 700,
  SEND_ALTERNATIVE_OPTIONS: 600,
  SEND_BALANCE_REQUEST: 600,
  SEND_CHECK_IN_INSTRUCTIONS: 600,
  SEND_MINIMUM_STAY_NOTE: 600,
  SEND_PAYMENT_REMINDER: 600,
  SEND_PRE_ARRIVAL_FORM: 600,
  SEND_QUOTE: 600,
  SEND_REMINDER: 600,
  CANCEL_BOOKING: 500,
  CLOSE_LEAD: 500,
  IGNORE_LEAD: 500,
  REVOKE_HOLD: 500,
};
