import { useTranslation } from 'react-i18next';
import { PropertyBase } from 'models/Properties';
import { PropertyStatusStyled } from './PropertyListItem.styles';

interface PropertyStatusProps {
  property: Partial<Pick<PropertyBase, 'isActive'>>;
}

const PropertyStatus = ({ property }: PropertyStatusProps) => {
  const { t } = useTranslation();
  return (
    <PropertyStatusStyled
      $isActive={property.isActive}
      data-testid="property-status"
    >
      {property.isActive
        ? t('pageProperties.body.active')
        : t('pageProperties.body.inactive')}
    </PropertyStatusStyled>
  );
};

export default PropertyStatus;
