// TODO: react-bootstrap migration, use Bootstrap 5 switch
import { forwardRef } from 'react';
import { Container } from './Switch.styles';

export interface SwitchProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const Switch = forwardRef<HTMLInputElement, SwitchProps>(
  ({ ...props }, ref) => {
    return (
      <Container>
        <input
          className="toggle-switch"
          type="checkbox"
          aria-label="Switch"
          ref={ref}
          {...props}
        />
      </Container>
    );
  },
);

export default Switch;
