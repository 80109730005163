import { useTranslation } from 'react-i18next';
import Button from 'components/button/Button';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { removeActiveModal } from 'store/slices/modal';
import ModalRenderer from './ModalRenderer';
import { modalIds } from './Modal.constants';
import { ConfirmModalData } from './Modal.types';
import { ModalErrorMessage } from './ModalRenderer.styles';

const ConfirmModal = () => {
  const modalData = useAppSelector(
    (state) => state.modal.activeModals[modalIds.confirm],
  );
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const {
    body,
    cancelColor,
    cancelLabel,
    confirmColor,
    confirmLabel,
    onCancel,
    onConfirm,
    onConfirmAsync,
    title,
    errorMessage,
    hideCancel,
  } = modalData as ConfirmModalData;

  const cancelHandler = () => {
    if (onCancel) {
      onCancel();
    }
    dispatch(removeActiveModal({ modalId: modalIds.confirm }));
  };

  const confirmHandler = () => {
    if (onConfirmAsync) {
      onConfirmAsync().then(() => {
        dispatch(removeActiveModal({ modalId: modalIds.confirm }));
      });
    } else {
      onConfirm();
      dispatch(removeActiveModal({ modalId: modalIds.confirm }));
    }
  };

  const footer = (
    <>
      {!hideCancel && (
        <Button
          data-testid="cancel-button"
          bsStyle={cancelColor || 'default'}
          onClick={cancelHandler}
        >
          {cancelLabel || t('common.cancel')}
        </Button>
      )}
      <Button
        data-testid="confirm-button"
        bsStyle={confirmColor || 'primary'}
        onClick={confirmHandler}
        disabled={!!errorMessage}
      >
        {confirmLabel || t('common.confirm')}
      </Button>
    </>
  );

  const bodyWithErrorMessage = (
    <>
      {body}
      {errorMessage && <ModalErrorMessage>{errorMessage}</ModalErrorMessage>}
    </>
  );

  return (
    <ModalRenderer
      body={bodyWithErrorMessage}
      footer={footer}
      modalId={modalIds.confirm}
      title={title}
    />
  );
};

export default ConfirmModal;
