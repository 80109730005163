import { useTranslation } from 'react-i18next';
import useOrderViewFetchedData from 'pages/orderView/useOrderViewFetchedData';
import { Fee } from 'models/order/Fees';
import { PriceDetailsList } from '../OrderViewPriceDetails.styles';
import OrderViewPriceDetailsListItem from '../OrderViewPriceDetailsListItem';
import { PriceDetailsItem } from '../OrderViewPriceDetailsListItemRow';

const Fees = () => {
  const { lead } = useOrderViewFetchedData();
  const { t } = useTranslation();

  const { fees } = lead.order;

  const getFees = (otherFees: Fee[]): PriceDetailsItem[] => {
    return otherFees.reduce((acc: PriceDetailsItem[], fee: Fee) => {
      const feeItem: PriceDetailsItem = {
        label: fee.name,
        amount: fee.netPrice,
      };

      acc.push(feeItem);

      return acc;
    }, []);
  };

  return (
    <PriceDetailsList data-testid="price-details-list-fees">
      <OrderViewPriceDetailsListItem
        title={t('componentOrder.fees')}
        priceDetailsItems={[
          fees.cleaningFee && {
            label: t('componentOrder.cleaning'),
            amount: fees.cleaningFee.netPrice,
          },
          ...getFees(fees.otherFees),
          {
            label: t('componentOrder.totalFees'),
            amount:
              fees.otherFees.reduce((acc, fee) => acc + fee.netPrice, 0) +
              fees.cleaningFee.netPrice,
            isTotalAmount: true,
          },
        ]}
      />
    </PriceDetailsList>
  );
};

export default Fees;
