import FormWithProvider from 'components/forms/form/Form';
import { Alert, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  getPropertyDetailLocalizedIntValue,
  getPropertyListLocalizedValue,
} from 'utils/property/propertyUtils';
import useCalendarBodySelection from 'pages/calendar/common/body/selection/useCalendarBodySelection';
import {
  PropertiesPricingForm,
  PropertyPricingModalParams,
} from './PropertyPricingModal.types';
import {
  Container,
  PropertyPricingModalTitle,
} from './PropertyPricingModal.styles';
import propertyPricingModalSchema from './PropertyPricingModal.schema';
import usePropertyPricingForm from './usePropertyPricingForm';
import PropertyPricingModalBodyInner from './PropertyPricingModalBodyInner';
import usePropertiesWithDynamicPricingEnabled from './usePropertiesWithDynamicPricingEnabled';

const PropertyPricingModalBody = ({
  params,
}: {
  params: PropertyPricingModalParams;
}) => {
  const { t } = useTranslation();

  const { resetSelection } = useCalendarBodySelection();
  const { handleSubmit, getDefaultValues } = usePropertyPricingForm();
  const { propertiesPricingData, propertyUids, min, max } = params;
  const { isPropertiesLoading, propertiesWithDynamicPricing } =
    usePropertiesWithDynamicPricingEnabled(propertyUids);

  return (
    <Container>
      <Modal.Header closeButton>
        <PropertyPricingModalTitle>
          {getPropertyDetailLocalizedIntValue(
            propertyUids.length,
            'editProperty',
            t,
          )}
        </PropertyPricingModalTitle>
      </Modal.Header>
      {!isPropertiesLoading &&
        propertiesWithDynamicPricing &&
        !!propertiesWithDynamicPricing.length && (
          <Alert bsStyle="warning">
            {getPropertyListLocalizedValue(
              propertiesWithDynamicPricing,
              'componentProperty.modal.dynamicPricingWarning',
              t,
            )}
          </Alert>
        )}
      <FormWithProvider
        data-testid="pricing-modal-form"
        horizontal
        onSubmit={(formValues: PropertiesPricingForm) => {
          resetSelection();
          handleSubmit(formValues);
        }}
        key={`${JSON.stringify(min)}`}
        resolver={propertyPricingModalSchema(t)}
        defaultValues={getDefaultValues(min, max, propertiesPricingData)}
      >
        <PropertyPricingModalBodyInner
          propertiesPricingData={propertiesPricingData}
        />
      </FormWithProvider>
    </Container>
  );
};

export default PropertyPricingModalBody;
