import { FeatureFlag } from 'models/Config';
import useAppConfig from './useAppConfig';

export interface AllowedFlags {
  allowAnalyticsWebapp: boolean;
  allowMultiUnit: boolean;
  allowPropertiesWebapp: boolean;
  allowOrderViewWebapp: boolean;
  allowAnalyticsComparisonWebapp: boolean;
}

const useFeatures = (): AllowedFlags => {
  const { featureFlags } = useAppConfig();
  return {
    allowAnalyticsWebapp: !featureFlags.includes(
      FeatureFlag.ALLOW_ANALYTICS_WEBAPP,
    ),
    allowMultiUnit: featureFlags.includes(FeatureFlag.ALLOW_MULTI_UNIT),
    allowPropertiesWebapp: !featureFlags.includes(
      FeatureFlag.ALLOW_PROPERTIES_WEBAPP,
    ),
    allowOrderViewWebapp: featureFlags.includes(
      FeatureFlag.ALLOW_ORDER_VIEW_WEBAPP,
    ),
    allowAnalyticsComparisonWebapp: featureFlags.includes(
      FeatureFlag.ALLOW_ANALYTICS_COMPARISON_WEBAPP,
    ),
  };
};

export default useFeatures;
