import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import useAppMutation from 'hooks/useAppMutation';
import useNotify from 'hooks/useNotify';
import API from 'services/API';
import { PROPERTIES_FOR_PROPERTIES_BODY_BASE_QUERY_KEY } from 'pages/agencyProperties/body/useFetchPropertiesForPropertiesBody';

interface AddSubUnitsArgs {
  propertyUid: string;
  units: number;
  tag: string;
  isNonBookable: boolean;
}

const addSubUnits = async ({
  propertyUid,
  tag,
  units,
  isNonBookable,
}: AddSubUnitsArgs) => {
  return API.post(`/api/internal/properties/${propertyUid}/create-sub-units`, {
    numberOfUnitsToCreate: units,
    tag,
    isNonBookable,
  });
};

const useAddSubUnitsMutation = ({
  onSuccess,
}: {
  onSuccess: (data: unknown, variables: AddSubUnitsArgs) => void;
}) => {
  const { notifyError, notifySuccess } = useNotify();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useAppMutation({
    mutationFn: addSubUnits,
    onSuccess: (data, variables) => {
      onSuccess(data, variables);
      notifySuccess(
        t('pageProperties.actions.addSubUnitsModal.onSubmitSuccess'),
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries([
        PROPERTIES_FOR_PROPERTIES_BODY_BASE_QUERY_KEY,
      ]);
    },
    onError: () => {
      notifyError(t('pageProperties.actions.addSubUnitsModal.onSubmitError'));
    },
  });
};

export default useAddSubUnitsMutation;
