import styled, { css } from 'styled-components';
import { ModalHeader } from 'react-bootstrap';
import IconCleaning from 'assets/images/icons/icon-cleaning.png';
import IconSendChecked from 'assets/images/icons/send-checked.png';
import Button from '../../../button/Button';
import { LeadStatus } from '../../../../models/Leads';
import theme from '../../../../styles/theme';

const leadStatusToColor = {
  [LeadStatus.NEW]: '#FF9900',
  [LeadStatus.ON_HOLD]: '#FF9900',
  [LeadStatus.BOOKED_BY_AGENT]: '#FF6633',
  [LeadStatus.BLOCKED]: '#999',
};

export const LeadModalHeaderStyled = styled(ModalHeader).attrs({
  closeButton: true,
  'data-testid': 'lead-modal-header',
})`
  border-bottom: none;
  height: 90px;

  > .close {
    position: absolute;
    right: 15px;
    top: 15px;
  }

  .form-field-container {
    margin-bottom: 0;
    max-width: 50%;
  }

  .tags-selector .rbt-input {
    border: none;
    box-shadow: none;

    .rbt-input-main {
      min-width: 60px;
    }
  }
`;

export const LeadModalHeaderContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding-right: 15px;
  width: 100%;
`;

export const LeadModalHeaderSection = styled.div`
  align-items: center;
  display: flex;
`;

export const LeadModalChannelIconContainer = styled.div`
  height: 30px;
  margin: 0 5px;
  width: 30px;
`;

const roundedButtonBase = css`
  border-radius: 25px;
  margin-right: 5px;
  min-width: 80px;
`;

const roundedButtonBlue = css`
  background-color: white;
  border: 1px solid rgb(69, 97, 157);
  color: rgb(69, 97, 157);

  :hover,
  :focus {
    background-color: white;
    color: rgb(69, 97, 157, 0.7);
  }
`;

const iconRoundedButtonBlue = css`
  background-repeat: no-repeat;
  background-size: 22px;
  padding-left: 40px;
`;

export const CreateJobButton = styled(Button).attrs({
  'data-testid': 'create-job-button',
})`
  ${roundedButtonBase}
  ${roundedButtonBlue}
  ${iconRoundedButtonBlue}
  background-image: url(${IconCleaning});
  background-position: 8px 3px;
`;

export const SendMessageButton = styled(Button).attrs({
  'data-testid': 'send-message-button',
})`
  ${roundedButtonBase}
  ${roundedButtonBlue}
  ${iconRoundedButtonBlue}
  background-image: url(${IconSendChecked});
  background-position: 10px 6px;
`;

export const StatusButtonsContainer = styled.div`
  padding-left: 10px;
`;

export const StatusButton = styled(Button).attrs(
  ({ $status }: { $status: LeadStatus }) => ({
    'data-testid': `lead-status-button-${$status}`,
  }),
)<{
  $fill: boolean;
  $status: LeadStatus;
}>`
  ${roundedButtonBase}

  ${({ $fill, $status }) =>
    $fill &&
    `
      background-color: ${leadStatusToColor[$status]};
      border-color: ${leadStatusToColor[$status]};
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
      color: #fff;
      outline: none;
    `}
  ${({ $fill, $status }) =>
    !$fill &&
    `
      background-color: #fff;
      border-color: ${leadStatusToColor[$status]};
      color: ${leadStatusToColor[$status]};
    `}
    
    
    ${({ $status }) => `
      :active, :focus, :hover {
        background-color: ${leadStatusToColor[$status]};
        border-color: ${leadStatusToColor[$status]};
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        color: #fff;
        outline: none;
      }
    `}
`;

export const GuestLink = styled.a`
  color: ${theme.colors.fontDark};
`;
