import styled from 'styled-components';

export const LeadCircleContainer = styled.div.attrs({
  'data-testid': 'lead-status-indicator-container',
})`
  position: relative;
  min-width: 70px;
  min-height: 70px;
  width: 70px;
  height: 70px;
`;

export const LeadStatusContainer = styled.div.attrs({
  'data-testid': 'lead-status-text',
})<{ $color: string }>`
  ${(props) => `color: ${props.$color};`}
  font-size: 12px;
  text-align: center;
  width: 52px;
`;
