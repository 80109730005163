import { useTranslation } from 'react-i18next';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { getLeadDetailLocalizedIntValue } from 'utils/lead/leadUtils';
import { DiscountCodeType } from 'models/Discounts';
import useOrderViewFetchedData from 'pages/orderView/useOrderViewFetchedData';
import { PriceDetailsList } from '../OrderViewPriceDetails.styles';
import { AmountType } from '../OrderViewPriceDetailsListItemRow';
import OrderViewPriceDetailsListItem from '../OrderViewPriceDetailsListItem';

const Rent = ({ isImported }: { isImported: boolean }) => {
  const { lead } = useOrderViewFetchedData();
  const { t } = useTranslation();

  const { rent, totalAmount } = lead.order;
  const showRentBreakdowns =
    rent.rentBreakdowns?.length > 0 &&
    rent.rentBaseNetPrice ===
      rent.rentBreakdowns.reduce(
        (acc, current) => acc + current.nightlyBaseAmount,
        0,
      );
  const nightsCount = differenceInCalendarDays(
    lead.checkOutDate,
    lead.checkInDate,
  );

  const title = isImported
    ? t('componentOrder.total')
    : t('componentOrder.rent');

  return (
    !!rent && (
      <PriceDetailsList data-testid="price-details-list-rent">
        <OrderViewPriceDetailsListItem
          title={title}
          priceDetailsItems={[
            {
              label: getLeadDetailLocalizedIntValue(nightsCount, 'night', t),
              amount: !isImported ? rent.rentBaseNetPrice : totalAmount,
              breakdowns:
                showRentBreakdowns &&
                rent.rentBreakdowns?.map((b) => ({
                  label: b.nightlyDate,
                  amount: b.nightlyBaseAmount,
                })),
            },
            rent.extraGuestsBaseNetPrice > 0 && {
              label: t('componentOrder.extraGuests'),
              amount: rent.extraGuestsBaseNetPrice,
            },
            rent.rateMultiplierAmount > 0 && {
              label: t('componentOrder.rateMultiplierWithPercentage', {
                value: rent.rateMultiplier,
              }),
              amount: rent.rateMultiplierAmount,
            },
            rent.discount && {
              label: t('componentOrder.discountCodeWithCode', {
                value: rent.discount.discountCode,
              }),
              amount:
                rent.discount.discountType === DiscountCodeType.PERCENT
                  ? rent.discount.percent * -1
                  : rent.discount.amount,
              amountType:
                rent.discount.discountType === DiscountCodeType.PERCENT
                  ? AmountType.PERCENTAGE
                  : AmountType.AMOUNT,
            },
            {
              label: t('componentOrder.totalRent'),
              amount: rent.listPrice,
              isTotalAmount: true,
            },
          ]}
        />
      </PriceDetailsList>
    )
  );
};

export default Rent;
