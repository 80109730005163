import { PropertyBase } from 'models/Properties';

export enum DailyRateUpdateType {
  FIXED_AMOUNT = 'FIXED_AMOUNT',
  PERCENTAGE_CHANGE = 'PERCENTAGE_CHANGE',
}

export interface PropertyPricingModalContextProps {
  modalId: string;
}

export interface PropertyPricingModalParams {
  min: Date;
  max: Date;
  propertyUids: string[];
  propertiesPricingData: PropertiesPricingData;
}

export interface PropertyPricingProps {
  modalId: string;
  params: PropertyPricingModalParams;
}

export interface PropertyPricingData {
  minDailyRate: number;
  maxDailyRate: number;
}

export interface PropertiesPricingData {
  pricing: {
    [currency: string]: {
      maxDailyRate?: number;
      minDailyRate?: number;
      propertyUids: Set<string>;
    };
  };
  minMinimumStay: number;
  maxMinimumStay: number;
}

export interface PropertiesPricingItem {
  currency: string;
  dailyRateUpdateType: DailyRateUpdateType;
  dailyRateValue: number;
  propertyUids: Set<string>;
}

export interface PropertiesPricingForm {
  startDate: Date;
  endDate: Date;
  pricing: PropertiesPricingItem[];
  minimumStay: number;
}

export interface PricingPeriodsUpdatePayload {
  startDate: string;
  endDate: string;
  dailyRateUpdates?: (Omit<PropertiesPricingItem, 'propertyUids'> & {
    propertyUids: string[];
  })[];
  minimumStayUpdates?: {
    minimumStay: number;
    propertyUids: string[];
  };
}

export type PricingProperty = Pick<PropertyBase, 'uid' | 'name'> & {
  pricing: Pick<PropertyBase['pricing'], 'automatedPricingEnabled'>;
};

export interface PricingPropertyResponse {
  data: {
    properties: PricingProperty[];
  };
}
