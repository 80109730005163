import { Lead } from 'models/Leads';
import styled from 'styled-components';
import { getZIndexFromOpacity, getOpacity } from 'utils/lead/leadUtils';

export const WrapperEvent = styled.div<{
  $lead: Lead;
  $left: number;
}>`
  position: absolute;
  // prettier-ignore
  width: 100${({ $left }) => ($left ? 'px' : '%')};
  height: 100%;
  left: ${({ $left }) => $left || 0}px;
  top: 0px;
  cursor: pointer;
  ${({ $lead }) =>
    $lead &&
    `z-index: ${getZIndexFromOpacity(getOpacity($lead.status)) + 200}`};
`;
