import { useTranslation } from 'react-i18next';
import useAppMutation from 'hooks/useAppMutation';
import API from 'services/API';
import useNotify from 'hooks/useNotify';

interface PinCodePayload {
  pinCode: string;
  pinCodeUrl: string;
  uid?: string;
}

const usePinCodeMutations = () => {
  const { notifyError } = useNotify();
  const { t } = useTranslation();

  const { mutate: createPinCode, isLoading: isCreatingPinCode } =
    useAppMutation(
      ({
        pinCode,
        leadUid,
        propertyUid,
      }: {
        pinCode: PinCodePayload;
        leadUid: string;
        propertyUid: string;
        successCallback?: Function;
      }) => {
        return API.post('/v2/pincodes', {
          leadUid,
          propertyUid,
          pinCode: pinCode.pinCode,
          pinCodeUrl: pinCode.pinCodeUrl,
        });
      },
      {
        onSuccess: (response, { successCallback }) => {
          successCallback?.();
        },
        onError: () =>
          notifyError(
            t(
              'componentLead.modal.stayDetailsTab.pinCodeForm.errorCreatePinCode',
            ),
          ),
      },
    );

  const { mutate: updatePinCode, isLoading: isUpdatingPinCode } =
    useAppMutation(
      ({
        pinCode,
      }: {
        pinCode: PinCodePayload;
        successCallback?: Function;
      }) => {
        return API.put(`/v2/pincodes/${pinCode.uid}`, {
          pinCode: pinCode.pinCode,
          pinCodeUrl: pinCode.pinCodeUrl,
        });
      },
      {
        onSuccess: (response, { successCallback }) => {
          successCallback?.();
        },
        onError: () =>
          notifyError(
            t(
              'componentLead.modal.stayDetailsTab.pinCodeForm.errorUpdatePinCode',
            ),
          ),
      },
    );

  return {
    createPinCode,
    updatePinCode,
    isCreatingPinCode,
    isUpdatingPinCode,
  };
};

export default usePinCodeMutations;
