import TextField from 'components/forms/textField/TextField';
import { Lead } from 'models/Leads';
import { useTranslation } from 'react-i18next';
import { PropertyBusinessType } from 'models/Properties';
import {
  CustomDataColumn,
  CustomDataPageHeader,
  CustomDataWrapper,
  LeadDataFormRow,
} from './LeadModalDataTab.styles';

const BookingComData = ({
  lead,
  getCopyAddon,
}: {
  lead: Lead;
  getCopyAddon: (value: string) => JSX.Element;
}) => {
  const { t } = useTranslation();

  return (
    <CustomDataWrapper data-testid="custom-data-wrapper-bookingCom">
      <CustomDataColumn md={12}>
        <CustomDataPageHeader
          title={`${t(
            'componentLead.modal.dataTab.customDataBookingComTitle',
          )}`}
        />
      </CustomDataColumn>

      <CustomDataColumn md={10}>
        <LeadDataFormRow>
          <TextField
            disabled
            label={t('componentLead.modal.dataTab.reservationID')}
            name="externalBookingID"
            rightAddons={[getCopyAddon(lead?.externalBookingID)]}
            colSmInput={5}
            colSmLabel={3}
          />
        </LeadDataFormRow>
      </CustomDataColumn>

      {lead?.bookingDotComData && lead?.bookingDotComData?.bookingDotComId && (
        <CustomDataColumn md={10}>
          <LeadDataFormRow>
            <TextField
              disabled
              label={t(
                'componentLead.modal.dataTab.bookingDotComData.bookingDotComHotelId',
              )}
              value={lead?.bookingDotComData?.bookingDotComId}
              name="bookingDotComData.bookingDotComId"
              rightAddons={[
                getCopyAddon(lead?.bookingDotComData?.bookingDotComId),
              ]}
              colSmInput={5}
              colSmLabel={3}
            />
          </LeadDataFormRow>
        </CustomDataColumn>
      )}
      {lead?.bookingDotComData &&
        lead?.bookingDotComData?.bookingDotComRoomId && (
          <CustomDataColumn md={10}>
            <LeadDataFormRow>
              <TextField
                disabled
                label={t(
                  'componentLead.modal.dataTab.bookingDotComData.bookingDotComRoomId',
                )}
                value={lead?.bookingDotComData?.bookingDotComRoomId}
                name="bookingDotComData.bookingDotComRoomId"
                rightAddons={[
                  getCopyAddon(lead?.bookingDotComData?.bookingDotComRoomId),
                ]}
                colSmInput={5}
                colSmLabel={3}
              />
            </LeadDataFormRow>
          </CustomDataColumn>
        )}
      {lead?.bookingDotComData && lead?.bookingDotComData?.rateId && (
        <CustomDataColumn md={10}>
          <LeadDataFormRow>
            <TextField
              disabled
              value={lead?.bookingDotComData?.rateId}
              label={t('componentLead.modal.dataTab.bookingDotComData.rateId')}
              name="bookingDotComData.rateId"
              rightAddons={[getCopyAddon(lead?.bookingDotComData?.rateId)]}
              colSmInput={5}
              colSmLabel={3}
            />
          </LeadDataFormRow>
        </CustomDataColumn>
      )}
    </CustomDataWrapper>
  );
};

export default BookingComData;
