import { useParams } from 'react-router-dom';
import Loader from 'components/loader/Loader';
import useFetchPropertyMainSettingsData from './useFetchPropertyMainSettingsData';

const PropertySettingsMainTab = () => {
  const { uid: propertyUid } = useParams();

  const { isFetching, mainSettingsData } =
    useFetchPropertyMainSettingsData(propertyUid);

  if (isFetching) {
    return <Loader />; // TODO replace with loading placeholder
  }

  return (
    <div data-testid="main-settings-tab">
      TODO Main settings
      {!!mainSettingsData && (
        <pre>{JSON.stringify(mainSettingsData, null, 1)}</pre>
      )}
    </div>
  );
};

export default PropertySettingsMainTab;
