import { useContext } from 'react';
import { FeeAmountType, FeeScopeType } from 'models/Quote';
import useFormatCurrency from 'hooks/useFormatCurrency';
import { useTranslation } from 'react-i18next';
import { Col } from 'react-bootstrap';
import CheckboxField from 'components/forms/checkboxField/CheckboxField';
import { normalizeKey } from 'utils/localeUtils';
import { useFormContext } from 'react-hook-form';
import LeadModalContext from '../../../LeadModalContext';

interface OrderPropertyFeesProps {
  isEditable: boolean;
}

const propertyFeeSuffix = {
  [FeeScopeType.PER_STAY]: 'perStay',
  [FeeScopeType.PER_NIGHT]: 'perNight',
  [FeeScopeType.PER_GUEST_PER_NIGHT]: 'perGuestperNight',
};

const OrderPropertyFees = ({ isEditable }: OrderPropertyFeesProps) => {
  const { quote } = useContext(LeadModalContext);
  const formatCurrency = useFormatCurrency();
  const { t } = useTranslation();

  const { getValues, setValue } = useFormContext();

  const { overrides } = getValues();

  return (
    <div className="form-group" data-testid="order-property-fees">
      {overrides &&
        overrides.fees &&
        overrides.fees.otherFees.map((propertyFee, i) => {
          if (propertyFee.feeId !== null) {
            const value =
              propertyFee.amountType === FeeAmountType.TAX
                ? `${propertyFee.name} - ${propertyFee.amount}%`
                : `${propertyFee.name} - ${formatCurrency({
                    value: propertyFee.amount,
                    currency: quote.currency,
                  })}`;

            return (
              <Col key={`propertyFee_${propertyFee.name}`} xs={12}>
                <CheckboxField
                  disabled={!isEditable}
                  checked={propertyFee.enabled}
                  name={`fees[${i}].enabled`}
                  onChange={(e) => {
                    const otherFees = getValues('overrides.fees.otherFees');
                    otherFees.map((_fee) => {
                      if (_fee.name === propertyFee.name) {
                        _fee.enabled = (e.target as HTMLInputElement).checked;
                      }
                      return _fee;
                    });

                    setValue('overrides.fees.otherFees', otherFees);
                  }}
                >
                  {t(
                    normalizeKey(
                      `componentOrder.scope_${
                        propertyFeeSuffix[propertyFee.scope]
                      }`,
                    ),
                    {
                      amount: value,
                    },
                  )}
                </CheckboxField>
              </Col>
            );
          }

          return null;
        })}
    </div>
  );
};

export default OrderPropertyFees;
