import styled from 'styled-components';
import theme from 'styles/theme';
import mediaQuery from '../../../styles/mediaQuery';

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 30px;

  h2 {
    font-size: 30px;
    font-weight: 300;
    margin: 0;

    a,
    span {
      color: ${theme.colors.hostfullyGreen};
      font-weight: 300;
    }
  }

  ${mediaQuery.xs} {
    padding: 0 0 6px;
    margin: 0 12px 12px;

    flex-direction: column;
  }
`;

export const HeaderControlsContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;

  ${mediaQuery.xs} {
    width: 100%;
    margin: 12px;
    justify-content: flex-end;
  }
`;

export const FilterForCalendar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
