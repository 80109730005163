import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { DailyRateUpdateType } from './PropertyPricingModal.types';

const propertyPricingModalSchema = (t: TFunction) => {
  return Yup.object()
    .shape({
      startDate: Yup.date()
        .required()
        .typeError(t('form.validation.invalidDate')),
      endDate: Yup.date()
        .required()
        .typeError(t('form.validation.invalidDate')),
      pricing: Yup.array().of(
        Yup.object().shape({
          dailyRateUpdateType: Yup.mixed<DailyRateUpdateType>()
            .oneOf(Object.values(DailyRateUpdateType))
            .required(),
          dailyRateValue: Yup.number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable()
            .notRequired(),
          currency: Yup.string().notRequired(),
          propertyUids: Yup.mixed<Set<string>>().required(),
        }),
      ),
      minimumStay: Yup.number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .nullable()
        .notRequired(),
    })
    .test(({ pricing, minimumStay }, ctx) => {
      const dailyRateUpdates = pricing.filter(
        ({ dailyRateValue }) => !!dailyRateValue,
      );

      if (dailyRateUpdates.length || minimumStay) {
        return true;
      }

      return ctx.createError({
        path: 'invalid',
        message: t('pageCalendar.pricingModal.invalidError'),
      });
    });
};

export default propertyPricingModalSchema;
