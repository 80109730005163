import { TFunction } from 'react-i18next';
import { differenceInCalendarDays, isDate, parseISO } from 'date-fns';
import {
  CalendarLead,
  StackedCalendarMode,
} from 'pages/stackedCalendar/Calendar.types';
import { Property } from 'models/Properties';
import {
  ChannelToSourceMap,
  Lead,
  LeadChannel,
  LeadSavingResult,
  LeadSource,
  LeadStatus,
  SourceColor,
  SourceToColorMap,
} from '../../models/Leads';
import { ThreadRelatedLead } from '../../models/inbox/Threads';
import { normalizeKey } from '../localeUtils';
import { TemplateName } from '../../components/domain/template/modal/TemplateModal.types';
import { TRANSPARENT_STATUSES } from './leadUtils.constant';

export function getLeadCheckInDate(
  lead: Lead | CalendarLead | LeadSavingResult,
) {
  if (!lead.checkInDate || !isDate(lead.checkInDate)) {
    lead.checkInDate = parseISO(lead.checkInLocalDateTime);
  }

  return lead.checkInDate;
}

export function getLeadCheckOutDate(
  lead: Lead | CalendarLead | LeadSavingResult,
) {
  if (!lead.checkOutDate || !isDate(lead.checkOutDate)) {
    lead.checkOutDate = parseISO(lead.checkOutLocalDateTime);
  }

  return lead.checkOutDate;
}

export function getLeadCheckInUtcDate(
  lead: Lead | CalendarLead | LeadSavingResult,
) {
  if (!lead.checkInUtcDate || !isDate(lead.checkInUtcDate)) {
    lead.checkInUtcDate = parseISO(lead.checkInUtcDateTime);
  }

  return lead.checkInUtcDate;
}

export function getLeadCheckOutUtcDate(
  lead: Lead | CalendarLead | LeadSavingResult,
) {
  if (!lead.checkOutUtcDate || !isDate(lead.checkOutUtcDate)) {
    lead.checkOutUtcDate = parseISO(lead.checkOutUtcDateTime);
  }

  return lead.checkOutUtcDate;
}

export function getLeadArrivalDate(lead: Lead) {
  if (!lead.arrivalDate || !isDate(lead.arrivalDate)) {
    lead.arrivalDate = parseISO(lead.arrivalLocalDateTime);
  }

  return lead.arrivalDate;
}

export function getLeadDepartureDate(lead: Lead) {
  if (!lead.departureDate || !isDate(lead.departureDate)) {
    lead.departureDate = parseISO(lead.departureLocalDateTime);
  }

  return lead.departureDate;
}

export function getLeadCreatedDate(lead: Lead) {
  if (
    lead.createdDateTimeString &&
    (!lead.createdDate || !isDate(lead.createdDate))
  ) {
    lead.createdDate = parseISO(lead.createdDateTimeString);
  }

  return lead.createdDate;
}

export function getLeadBookedDate(lead: Lead) {
  if (
    lead?.bookedDateTimeString &&
    (!lead.bookedDate || !isDate(lead.bookedDate))
  ) {
    lead.bookedDate = parseISO(lead.bookedDateTimeString);
  }

  return lead?.bookedDate;
}

export function getLeadDetailLocalizedIntValue(
  value: number,
  baseLocaleKey: string,
  t: TFunction,
) {
  if (!value || value < 0) {
    return '';
  }

  if (value === 1) {
    return t(normalizeKey(`componentLead.details.${baseLocaleKey}Single`));
  }

  return t(normalizeKey(`componentLead.details.${baseLocaleKey}Number`), {
    number: value,
  });
}

export const getLeadDetailLocalizedObjectValue = (
  value: number,
  baseLocaleKey: string,
  t: TFunction,
): {
  counter: number;
  label: string;
} => {
  const localizedIntValue = getLeadDetailLocalizedIntValue(
    value,
    baseLocaleKey,
    t,
  );

  const match = localizedIntValue.match(/^(\d+)\s*(\S+)/);

  if (!match) return null;

  const counter = parseInt(match[1], 10);
  const label = match[2];

  return { counter, label };
};

export function getLeadLocalizedNightsCount(lead: Lead, t: TFunction) {
  const checkInDate = getLeadCheckInDate(lead);
  const checkOutDate = getLeadCheckOutDate(lead);
  const nightCount = differenceInCalendarDays(checkOutDate, checkInDate);

  return getLeadDetailLocalizedIntValue(nightCount, 'night', t);
}

export function getThreadRelatedLeadCheckInDate(lead: ThreadRelatedLead) {
  if (!lead.leadCheckInDateObj) {
    lead.leadCheckInDateObj = parseISO(lead.leadCheckInDate);
  }

  return lead.leadCheckInDateObj;
}

export function getThreadRelatedLeadCheckOutDate(lead: ThreadRelatedLead) {
  if (!lead.leadCheckOutDateObj) {
    lead.leadCheckOutDateObj = parseISO(lead.leadCheckOutDate);
  }

  return lead.leadCheckOutDateObj;
}

export function createThreadRelatedLeadFromLead(
  lead: Lead | null,
  threadUid: string,
): ThreadRelatedLead {
  if (!lead) {
    return null;
  }

  const {
    uid: leadUid,
    channel: { channel: leadChannel },
    checkInLocalDateTime: leadCheckInDate,
    checkOutLocalDateTime: leadCheckOutDate,
    property: { uid: propertyUid },
    source: leadSource,
    status: leadStatus,
  } = lead;

  return {
    leadUid,
    threadUid,
    leadChannel,
    leadCheckInDate,
    leadCheckOutDate,
    leadSource,
    leadStatus,
    propertyUid,
  };
}

export function convertLeadChannelFromLeadSource(
  leadSource: LeadSource,
): LeadChannel {
  switch (leadSource) {
    case LeadSource.DIRECT_HOMETOGO:
      return LeadChannel.HOMETOGO;
    case LeadSource.IMPORT_TRIPADVISOR:
      return LeadChannel.TRIP_ADVISOR;
    case LeadSource.IMPORT_BOOKINGCOM:
    case LeadSource.DIRECT_BOOKINGDOTCOM:
      return LeadChannel.BOOKING_COM;
    case LeadSource.IMPORT_AIRBNB:
    case LeadSource.DIRECT_AIRBNB:
    case LeadSource.AIRBNB_BLOCK_IMPORT:
      return LeadChannel.AIRBNB;
    case LeadSource.DIRECT_HOLIDU:
      return LeadChannel.HOLIDU;
    case LeadSource.DIRECT_GOOGLE:
      return LeadChannel.GOOGLE;
    case LeadSource.MisterBnB:
      return LeadChannel.MISTERBNB;
    case LeadSource.DIRECT_REDAWNING:
      return LeadChannel.REDAWNING;
    case LeadSource.DIRECT_HVMI:
      return LeadChannel.HVMI;
    case LeadSource.DIRECT_HOMEAWAY:
    case LeadSource.IMPORT_HOMEAWAY:
    case LeadSource.IMPORT_ABRITEL:
    case LeadSource.IMPORT_VRBO:
      return LeadChannel.VRBO;
    default:
      return LeadChannel.HOSTFULLY;
  }
}

export function convertLeadSourceFromLeadChannel(
  leadChannel: LeadChannel,
): LeadSource {
  return ChannelToSourceMap.get(leadChannel) || LeadSource.ORBIRENTAL_FORM;
}

export function shouldHandleSecurityDeposit(lead: Lead) {
  if (!lead.order?.securityDeposit) {
    return false;
  }

  return (
    lead.source !== LeadSource.IMPORT_AIRBNB &&
    lead.source !== LeadSource.AIRBNB_BLOCK_IMPORT &&
    lead.source !== LeadSource.DIRECT_AIRBNB &&
    lead.source !== LeadSource.DIRECT_TRIPPING &&
    lead.source !== LeadSource.IMPORT_TRIPADVISOR
  );
}
export function getLabelSource(source: LeadSource) {
  switch (source) {
    case LeadSource.ORBIRENTAL_FORM:
    case LeadSource.ORBIRENTAL_WIDGET:
    case LeadSource.ORBIRENTAL_API:
    case LeadSource.OWNER_PORTAL:
    case LeadSource.ORBIRENTAL_LINKED:
      return 'Hostfully';

    case LeadSource.IMPORT_HOMEAWAY:
    case LeadSource.IMPORT_VRBO:
    case LeadSource.IMPORT_ABRITEL:
    case LeadSource.DIRECT_HOMEAWAY:
      return 'VRBO';

    case LeadSource.IMPORT_TRIPADVISOR:
      return 'Tripadvisor';

    case LeadSource.DIRECT_TRIPPING:
      return 'Tripping';

    case LeadSource.DIRECT_HOMETOGO:
      return 'Hometogo';

    case LeadSource.IMPORT_BOOKINGCOM:
    case LeadSource.DIRECT_BOOKINGDOTCOM:
      return 'Booking.com';

    case LeadSource.AIRBNB_BLOCK_IMPORT:
    case LeadSource.IMPORT_AIRBNB:
    case LeadSource.DIRECT_AIRBNB:
      return 'Airbnb';

    case LeadSource.DIRECT_HOLIDU:
      return 'Holidu';

    case LeadSource.DIRECT_HVMI:
      return 'HVMI';

    case LeadSource.DIRECT_GOOGLE:
      return 'Google';

    default:
      return source.toLowerCase();
  }
}

export function isFromICS(source: LeadSource) {
  let result = false;

  switch (source) {
    case LeadSource.IMPORT_HOMEAWAY:
    case LeadSource.IMPORT_VRBO:
    case LeadSource.IMPORT_ABRITEL:
    case LeadSource.IMPORT_TRIPADVISOR:
    case LeadSource.IMPORT_AIRBNB:
    case LeadSource.IMPORT_BOOKINGCOM:
      result = true;
      break;
    default:
      break;
  }

  return result;
}

export function isLeadFieldDisabled(lead: Lead, fieldName: string): boolean {
  if (!lead) return false;

  return (
    lead.fieldsPermissionsMetadata?.find((fpm) => fpm.fieldName === fieldName)
      ?.metadata?.editable === false || false
  );
}

export function getOpacity(status: LeadStatus) {
  if (TRANSPARENT_STATUSES.includes(status)) {
    return 0.5;
  }
  return 1.0;
}

export function getZIndexFromOpacity(opacity) {
  if (opacity === 0) return 0;
  return (1 / opacity) * 98;
}

export const getZIndexFromLeadSource = (
  source: string,
  status: string,
): number => {
  if (source.includes('IMPORT')) {
    return 50;
  }

  if (status === LeadStatus.BLOCKED) {
    return 100;
  }

  return 200;
};

export const getLabelZIndexFromLeadSource = (
  source: string,
  status: string,
): number => {
  return getZIndexFromLeadSource(source, status) + 1;
};

export function getFullName(lead: Lead) {
  const fullName = [];

  if (lead.firstName) fullName.push(lead.firstName);
  if (lead.lastName) fullName.push(lead.lastName);
  if (!lead.firstName && !lead.lastName) fullName.push(lead.email);
  return fullName.join(' ');
}

export function getColorByLeadSource(source: LeadSource) {
  return SourceToColorMap.get(source) || SourceColor.DEFAULT;
}

export const blockedLeadGradientPrimaryColor = {
  owner: 'rgba(119, 55, 154, 0.7)',
  other: 'rgba(48, 175, 139, 0.7)',
};

export function getColorSource(mode: StackedCalendarMode, lead) {
  if (lead.status === LeadStatus.BLOCKED) {
    const { owner, other } = blockedLeadGradientPrimaryColor;

    if (lead.creator && lead.creator.type === 'OWNER') {
      if (mode === StackedCalendarMode.BOOKING) {
        return `repeating-linear-gradient(45deg, ${owner}, ${owner} 6px, rgba(119, 55, 154,1) 5px, rgba(119, 55, 154,1) 12px)`;
      }

      return `repeating-linear-gradient(45deg, ${owner}, ${owner} 5px, rgba(119, 55, 154,1) 5px, rgba(119, 55, 154,1) 10px)`;
    }

    if (mode === StackedCalendarMode.BOOKING) {
      return `repeating-linear-gradient(45deg, ${other}, ${other} 6px,rgba(48,175,139,1) 5px,rgba(48,175,139,1) 12px)`;
    }
    return `repeating-linear-gradient(45deg, ${other}, ${other} 5px, rgba(48, 175, 139,1) 5px, rgba(48, 175, 139,1) 10px)`;
  }

  return getColorByLeadSource(lead.source);
}

const sourcesAllowedForSendingMessage = [
  LeadSource.DIRECT_AIRBNB,
  LeadSource.DIRECT_BOOKINGDOTCOM,
  LeadSource.DIRECT_HOMEAWAY,
  LeadSource.DIRECT_HVMI,
  LeadSource.DIRECT_GOOGLE,
  LeadSource.ORBIRENTAL_API,
  LeadSource.ORBIRENTAL_FORM,
  LeadSource.ORBIRENTAL_WIDGET,
  LeadSource.DIRECT_REDAWNING,
];

export function canSendMessage({ source, status }: Lead) {
  return (
    status !== LeadStatus.BLOCKED &&
    sourcesAllowedForSendingMessage.includes(source)
  );
}

const bookedStatuses = [
  LeadStatus.BOOKED_BY_AGENT,
  LeadStatus.BOOKED_BY_CUSTOMER,
  LeadStatus.BOOKED_BY_OWNER,
  LeadStatus.ARCHIVED,
  LeadStatus.STAY,
  LeadStatus.PAID_IN_FULL,
  LeadStatus.BOOKED_EXTERNALLY,
];

export const isLeadBooked = (lead: Lead) => {
  if (!lead) {
    return false;
  }

  return bookedStatuses.includes(lead.status);
};

export function canForceFullPayment(lead: Lead) {
  if (
    lead.source === LeadSource.ORBIRENTAL_FORM ||
    lead.source === LeadSource.ORBIRENTAL_WIDGET ||
    lead.source === LeadSource.ORBIRENTAL_API ||
    lead.source === LeadSource.IMPORT_BOOKINGCOM
  ) {
    switch (lead.status) {
      case LeadStatus.NEW:
      case LeadStatus.QUOTE_SENT:
      case LeadStatus.ON_HOLD:
      case LeadStatus.HOLD_EXPIRED:
      case LeadStatus.BOOKED_EXTERNALLY:
        return true;
      default:
        return false;
    }
  }

  return false;
}

export function canEditQuote(lead: Lead) {
  if (!lead.uid) return true;

  if (
    lead.source === LeadSource.DIRECT_AIRBNB ||
    lead.source === LeadSource.DIRECT_TRIPPING ||
    lead.source === LeadSource.DIRECT_BOOKINGDOTCOM
  ) {
    return false;
  }

  switch (lead.status) {
    case LeadStatus.NEW:
    case LeadStatus.QUOTE_SENT:
    case LeadStatus.ON_HOLD:
    case LeadStatus.HOLD_EXPIRED:
    case LeadStatus.BOOKED_BY_AGENT:
    case LeadStatus.BOOKED_BY_CUSTOMER:
    case LeadStatus.BLOCKED:
    case LeadStatus.SAMPLE:
    case LeadStatus.BOOKED_EXTERNALLY:
    case LeadStatus.PAID_IN_FULL:
    case LeadStatus.STAY:
    case LeadStatus.ARCHIVED:
      return true;
    default:
      return false;
  }
}

export const canSetHomeAwayAsSource = (lead?: Lead) => {
  if (!lead) return false;

  return (
    (lead.source === LeadSource.ORBIRENTAL_FORM ||
      lead.source === LeadSource.ORBIRENTAL_WIDGET ||
      lead.source === LeadSource.ORBIRENTAL_API) &&
    isLeadBooked(lead)
  );
};

const sourcesNotAllowedToBeDeleted = [
  LeadSource.DIRECT_AIRBNB,
  LeadSource.DIRECT_TRIPPING,
  LeadSource.DIRECT_EXPEDIA,
  LeadSource.DIRECT_HOLIDU,
  LeadSource.DIRECT_TRIPPING,
  LeadSource.DIRECT_HOMEAWAY,
  LeadSource.DIRECT_BOOKINGDOTCOM,
];
const statusesAlwaysAllowedToBeDeleted = [
  LeadStatus.CANCELLED,
  LeadStatus.CANCELLED_BY_OWNER,
  LeadStatus.CANCELLED_BY_TRAVELER,
];
const statusesAllowedToBeDeletedIfSourceAllows = [
  LeadStatus.NEW,
  LeadStatus.HOLD_EXPIRED,
  LeadStatus.BLOCKED,
  LeadStatus.QUOTE_SENT,
  LeadStatus.DECLINED,
  LeadStatus.CANCELLED,
  LeadStatus.CANCELLED_BY_TRAVELER,
  LeadStatus.CANCELLED_BY_OWNER,
  LeadStatus.IGNORED,
  LeadStatus.DUPLICATE,
  LeadStatus.SAMPLE,
  LeadStatus.CLOSED_QUOTE,
  LeadStatus.CLOSED_HOLD,
  LeadStatus.PENDING,
  LeadStatus.ARCHIVED,
];

export function canBeDeleted(lead: Lead | undefined) {
  if (!lead || !lead.uid) {
    return false;
  }

  const { source, status } = lead;

  return (
    statusesAlwaysAllowedToBeDeleted.includes(status) ||
    (status === LeadStatus.BLOCKED &&
      source === LeadSource.DIRECT_BOOKINGDOTCOM) ||
    (!sourcesNotAllowedToBeDeleted.includes(source) &&
      statusesAllowedToBeDeletedIfSourceAllows.includes(status))
  );
}

export function canOpenMailerOnSave({
  source,
  status,
}: {
  source: LeadSource;
  status: LeadStatus;
}) {
  return (
    status === LeadStatus.NEW ||
    status === LeadStatus.QUOTE_SENT ||
    status === LeadStatus.BOOKED_BY_AGENT ||
    status === LeadStatus.ON_HOLD ||
    (status === LeadStatus.BOOKED_EXTERNALLY &&
      source === LeadSource.ORBIRENTAL_API)
  );
}

export function getTemplateNameByLeadStatus(
  status: LeadStatus,
): TemplateName | null {
  if (status === LeadStatus.NEW || status === LeadStatus.QUOTE_SENT) {
    return TemplateName.QUOTE;
  }

  if (status === LeadStatus.ON_HOLD) {
    return TemplateName.HOLD_CONFIRMATION;
  }

  if (
    status === LeadStatus.BOOKED_BY_AGENT ||
    status === LeadStatus.BOOKED_EXTERNALLY
  ) {
    return TemplateName.BALANCE_DUE;
  }

  return null;
}

export const canLeadViewPaymentLink = (lead: Lead) => {
  if (
    lead.source === LeadSource.ORBIRENTAL_FORM ||
    lead.source === LeadSource.ORBIRENTAL_WIDGET ||
    lead.source === LeadSource.ORBIRENTAL_API ||
    lead.source === LeadSource.IMPORT_BOOKINGCOM ||
    lead.source === LeadSource.DIRECT_BOOKINGDOTCOM ||
    lead.source === LeadSource.DIRECT_HOMEAWAY
  ) {
    if (
      lead.status === LeadStatus.HOLD_EXPIRED ||
      lead.status === LeadStatus.BLOCKED ||
      lead.status === LeadStatus.DECLINED ||
      lead.status === LeadStatus.CANCELLED ||
      lead.status === LeadStatus.CANCELLED_BY_TRAVELER ||
      lead.status === LeadStatus.CANCELLED_BY_OWNER ||
      lead.status === LeadStatus.IGNORED ||
      (lead.status === LeadStatus.PAID_IN_FULL && lead.order.balance <= 0) ||
      lead.status === LeadStatus.DUPLICATE ||
      lead.status === LeadStatus.SAMPLE ||
      lead.status === LeadStatus.CLOSED_QUOTE ||
      lead.status === LeadStatus.CLOSED_HOLD ||
      lead.status === LeadStatus.PENDING
    ) {
      return false;
    }

    if (lead.status === LeadStatus.ARCHIVED && lead.order.balance <= 0) {
      return false;
    }

    return true;
  }

  return false;
};

export const getPaymentLink = (lead: Lead) => {
  return lead.order?.paymentLinkUrl;
};

/**
 * Helper function to get the unit type from a property uid (unit uid),
 * useful to send the unitType as the propertyUid and unitUid in the backend.
 * @param availableProperties List of properties.
 * @param propertyUid The property uid that can be a unit, to find the unit type from it.
 * @returns Return the unit type, if available, null if not available.
 */
export const getUnitType = (
  availableProperties: Property[],
  propertyUid: string,
) => {
  return (
    availableProperties
      ?.flatMap((property) => property?.unitTypes || [])
      ?.find((ut) => ut?.units?.some((unit) => unit?.uid === propertyUid)) ||
    null
  );
};
