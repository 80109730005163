import * as React from "react";
const SvgBooking = (props) => /* @__PURE__ */ React.createElement("svg", { width: "100%", height: "100%", viewBox: "0 0 59 55", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlSpace: "preserve", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinejoin: "round",
  strokeMiterlimit: 2
}, ...props }, /* @__PURE__ */ React.createElement("path", { d: "M21.682,44.495l-8.486,-0.008l0,-10.016c0,-2.14 0.841,-3.254 2.697,-3.509l5.789,0c4.129,0 6.799,2.57 6.799,6.729c0,4.272 -2.603,6.8 -6.799,6.804Zm-8.486,-21.237l0,-8.415c0,-2.307 0.989,-3.404 3.157,-3.545l4.344,-0c3.723,-0 5.955,2.198 5.955,5.881c-0,2.804 -1.53,6.079 -5.819,6.079l-7.637,-0Zm19.321,4.217l-1.534,-0.851l1.34,-1.131c1.559,-1.322 4.171,-4.296 4.171,-9.427c-0,-7.86 -6.174,-12.928 -15.729,-12.928l-10.903,-0l0,-0.004l-1.242,-0c-2.831,0.104 -5.101,2.378 -5.13,5.189l-0,44.595l17.49,-0c10.62,-0 17.474,-5.707 17.474,-14.547c0,-4.76 -2.214,-8.827 -5.937,-10.896Z", style: {
  fill: "#003580",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M43.01,46.758c0,-3.407 2.783,-6.163 6.213,-6.163c3.437,0 6.234,2.756 6.234,6.163c-0,3.402 -2.797,6.162 -6.234,6.162c-3.43,0 -6.213,-2.76 -6.213,-6.162Z", style: {
  fill: "#003580",
  fillRule: "nonzero"
} }));
export default SvgBooking;
