import { useWatch } from 'react-hook-form';
import { Trans } from 'react-i18next';
import Button from '../../../../button/Button';
import { requiresRebooking } from '../LeadModal.utils';
import useLeadRebook from '../useLeadRebook';
import { LeadModalFooterErrorMessage } from './LeadModalFooterMessages.styles';

const LeadModalFooterMessages = ({
  errorMessage,
}: {
  errorMessage: string | undefined;
}) => {
  const { prepareLeadRebookForm } = useLeadRebook();
  const { checkInDate, checkOutDate, status } = useWatch();

  return (
    <>
      {requiresRebooking({
        checkInDate,
        checkOutDate,
        status,
      }) && (
        <LeadModalFooterErrorMessage>
          {/* prettier breaks locale Trans component interpolation, so it must be excluded from formatting */}
          {/* prettier-ignore */}
          <Trans i18nKey="componentLead.modal.form.validation.requiresRebookingMessage">
            Archived bookings cannot be modified with future check-in or check-out dates.
            Please try <Button bsStyle="link" className="rebook-button" onClick={prepareLeadRebookForm}>re-booking</Button> instead
          </Trans>
        </LeadModalFooterErrorMessage>
      )}
      {!!errorMessage && (
        <LeadModalFooterErrorMessage>
          {errorMessage}
        </LeadModalFooterErrorMessage>
      )}
    </>
  );
};

export default LeadModalFooterMessages;
