import { useEffect, useState } from 'react';
import useFetchAgencyIntegrationSettings from 'components/domain/integrationSettings/useFetchAgencyIntegrationSettings';

interface Integration {
  affiliateEnum: string;
  agencyUid: string;
  enabled: boolean;
}

// Operto teams as vrscheduler
// EZcare as ez_inspections
// Turno as turnoverbnb
const cleaniningIntegrations = [
  'breezeway',
  'ez_inspections',
  'properly',
  'sojo',
  'turnoverbnb',
  'vrscheduler',
];

const useTurno = () => {
  const [showTurnoLink, setShowTurnoLink] = useState<boolean>(false);

  const { integrationSettings: agencyIntegrations } =
    useFetchAgencyIntegrationSettings();

  useEffect(() => {
    setShowTurnoLink(false);
    if (!agencyIntegrations) return;

    const integrations: Record<string, boolean> = agencyIntegrations.reduce(
      (accumulator: Record<string, boolean>, currentObject: Integration) => ({
        ...accumulator,
        [currentObject.affiliateEnum]: currentObject.enabled,
      }),
      {},
    );

    const isEveryIntegrationOff = cleaniningIntegrations.every(
      (integrationName) => !integrations[integrationName],
    );

    if (isEveryIntegrationOff) setShowTurnoLink(true);

    if (integrations.turnoverbnb) setShowTurnoLink(true);
  }, [agencyIntegrations]);

  return showTurnoLink;
};

export default useTurno;
