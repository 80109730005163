import styled from 'styled-components';
import { Badge, Col, DropdownButton } from 'react-bootstrap';
import Button from 'components/button/Button';
import theme from 'styles/theme';
import mediaQuery from 'styles/mediaQuery';

export const PropertyNameStyled = styled.a.attrs({
  'data-testid': 'property-list-item-name',
})`
  font-size: 1.5rem;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const PropertyNameWrapperStyled = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  column-gap: 5px;

  ${mediaQuery.xs} {
    flex-wrap: wrap;
  }
`;

export const PropertyCharacteristicsStyled = styled.div`
  color: ${theme.colors.gray600};
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const PropertyAddressStyled = styled.address`
  color: ${theme.colors.gray600};
  font-size: 1rem;
  margin-bottom: 0;
`;

export const PropertyInfoStyled = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 5px;
  min-width: 0;
`;

const PropertyBadge = styled(Badge)`
  border-radius: 5px;
  font-size: 1rem;
  font-weight: normal;
  padding: 5px 10px;
`;

export const PropertyStatusStyled = styled(PropertyBadge)<{
  $isActive: boolean;
}>`
  background-color: ${({ $isActive }) =>
    $isActive ? '#31b58e' : theme.colors.gray600};
`;

export const PropertyLabelStyled = styled(PropertyBadge).attrs({
  'data-testid': 'property-label',
})`
  align-items: center;
  border: 1px solid #3a84d8;
  display: inline-flex;
  background-color: #e7f1ff;
  color: #3a84d8;
  gap: 5px;
  min-width: min-content;

  svg {
    --size: 15px;
    height: var(--size);
    width: var(--size);
  }
`;

export const PropertyActionsDropdownButtonStyled = styled(DropdownButton)`
  align-items: center;
  border: none;
  display: flex;
  height: 100%;
  justify-content: center;
`;

export const PropertyActionsPlaceholderStyled = styled.div`
  height: 24px;
  width: 26px;
`;

export const ExpandCollapseButtonStyled = styled(Button)`
  align-items: center;
  display: flex;
  gap: 5px;
  margin-left: 78px;
`;

export const PropertyDetailsColumnStyled = styled(Col).attrs<{
  $canSeePermissions?: boolean;
}>(({ $canSeePermissions }) => ({
  xs: 9,
  md: $canSeePermissions ? 7 : undefined,
}))<{ $canSeePermissions?: boolean; $isChildProperty?: boolean }>`
  align-items: start;
  display: flex;
  gap: 15px;
  padding-left: ${({ $isChildProperty }) => $isChildProperty && '106px'};
`;

export const PropertyPermissionsColumnStyled = styled(Col).attrs<{
  $canSeePermissions?: boolean;
}>(({ $canSeePermissions }) => ({
  xsHidden: true,
  smHidden: true,
  mdHidden: !$canSeePermissions,
  md: $canSeePermissions ? 3 : undefined,
}))<{ $canSeePermissions?: boolean }>``;

export const PropertyActionsColumnStyled = styled(Col).attrs<{
  $canSeePermissions?: boolean;
}>(({ $canSeePermissions }) => ({
  xs: 3,
  md: $canSeePermissions ? 2 : undefined,
}))<{ $canSeePermissions?: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  gap: 5px;
  justify-content: end;
`;

export const PropertyListItemStyled = styled.li.attrs({
  className: 'property-list-item',
  'data-testid': 'property-list-item',
})`
  border-bottom: 1px solid ${theme.colors.gray300};
  padding: 15px;

  :last-child {
    border-bottom: none;
  }

  :hover:not(:has(.property-list-item:hover)) {
    background-color: ${theme.colors.bgHover};
  }
`;
