import { useTranslation } from 'react-i18next';
import useAppModal from 'hooks/useAppModal';
import { useQueryClient } from '@tanstack/react-query';
import useNotify from 'hooks/useNotify';
import { modalIds } from 'components/modal/Modal.constants';
import API from 'services/API';
import useAppMutation from 'hooks/useAppMutation';
import { PROPERTIES_FOR_PROPERTIES_BODY_BASE_QUERY_KEY } from 'pages/agencyProperties/body/useFetchPropertiesForPropertiesBody';
import { ConfirmationModalProps } from './Confirmation.types';
import { PropertyNameStyled } from './Confirmation.styles';

const useDeactivateModal = () => {
  const queryClient = useQueryClient();
  const { notifySuccess } = useNotify();
  const { t } = useTranslation();
  const { openConfirmModal, closeModal, setErrorConfirmModal } = useAppModal();

  const closeConfirmationModal = () => {
    closeModal(modalIds.confirm);
  };

  const { mutateAsync } = useAppMutation(
    ['deactivateProperty'],
    async ({ uid }: { uid: string; name: string }) => {
      const response = await API.post(
        `/api/internal/properties/${uid}/deactivate`,
      );
      return response.data;
    },
    {
      onSuccess: (_, { name }) => {
        notifySuccess(
          t('pageProperties.actions.deactivationSuccess', {
            name: name.length > 30 ? `${name.slice(0, 30)}...` : name,
          }),
        );
        closeConfirmationModal();
        queryClient.invalidateQueries(
          [PROPERTIES_FOR_PROPERTIES_BODY_BASE_QUERY_KEY],
          { exact: false },
        );
      },
      onError: (error: any) => {
        setErrorConfirmModal(error.response.data.apiErrorMessage);
      },
    },
  );

  const handleDeactivate = (uid: string, name: string) =>
    new Promise<void>((resolve) => {
      mutateAsync({ uid, name }).then(resolve);
    });

  const openDeactivateModal = ({ uid, name }: ConfirmationModalProps) => {
    openConfirmModal({
      body: (
        <>
          {t('pageProperties.actions.deactivatePropertyConfirmation')}{' '}
          <PropertyNameStyled>{name}</PropertyNameStyled>?
        </>
      ),
      onConfirm: () => handleDeactivate(uid, name),
      title: t('pageProperties.actions.common.areYouSure'),
      confirmLabel: t('pageProperties.actions.deactivate'),
    });
  };

  return {
    openDeactivateModal,
  };
};

export default useDeactivateModal;
