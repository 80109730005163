import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';
import theme from 'styles/theme';
import FormWithProvider from '../../../forms/form/Form';

export const LeadModalForm = styled(FormWithProvider).attrs({
  noValidate: true,
})``;

export const LeadRebookParagraph = styled.p`
  text-align: right;
  cursor: pointer;
  color: ${theme.colors.primary};
  margin-bottom: -30px;

  ${mediaQuery.sm} {
    margin-bottom: 0;
  }
`;
