import { useTranslation } from 'react-i18next';
import Loader from '../../../loader/Loader';
import {
  CreateLeadModalParams,
  LeadModalParams,
  UpdateLeadModalParams,
} from './LeadModal.types';
import leadFormSchema from './LeadModal.schema';
import LeadModalHeader from './LeadModalHeader';
import LeadModalBody from './LeadModalBody';
import LeadModalFooter from './LeadModalFooter';
import { LeadModalForm } from './LeadModal.styles';
import useLeadModalData from './useLeadModalData';
import useLeadModalFormInitialValue from './useLeadModalFormInitialValue';
import useLeadModalFormSubmit from './useLeadModalFormSubmit';
import useLeadForRebooking from './useLeadForRebooking';

const LeadModalFormWrapper = ({ params }: { params: LeadModalParams }) => {
  const { leadUid } = params as UpdateLeadModalParams;
  const createParams = params as CreateLeadModalParams;
  const { leadForRebookingUid } = createParams;
  const {
    isLoading,
    lead,
    leadTags,
    pinCode,
    reviewByHost,
    defaultPropertyUid,
    leadCustomData,
  } = useLeadModalData(leadUid);
  const { lead: leadForRebooking, isFetching: isFetchingLeadForRebooking } =
    useLeadForRebooking(leadForRebookingUid);
  const prepareFormInitialValue = useLeadModalFormInitialValue();
  const submitHandler = useLeadModalFormSubmit();
  const { t } = useTranslation();

  if (isLoading || isFetchingLeadForRebooking) {
    return <Loader />;
  }

  return (
    <LeadModalForm
      defaultValues={prepareFormInitialValue({
        lead,
        leadForRebooking,
        leadTags,
        params: createParams,
        pinCode,
        reviewByHost,
        leadCustomData,
        defaultPropertyUid,
      })}
      onSubmit={submitHandler}
      schema={leadFormSchema(t)}
    >
      <LeadModalHeader params={params} />
      <LeadModalBody />
      <LeadModalFooter />
    </LeadModalForm>
  );
};

export default LeadModalFormWrapper;
