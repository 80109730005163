import useAppModal from 'hooks/useAppModal';
import { Channel, ChannelProps, ChannelSettings } from 'models/Channels';
import PropertyChannelsWidgetEditLinkModal from './PropertyChannelsWidgetEditLinkModal';
import { editExternalLinkModalId } from './PropertyChannelsWidget.constants';
import {
  PropertyChannelIcon,
  PropertyChannelIconContainer,
  PropertyChannelLinkEditIcon,
  PropertyChannelNameContainer,
  PropertyChannelNameInactive,
  PropertyChannelsListItem,
} from './PropertyChannelsWidget.styles';

function findActiveChannelSettings(
  channelSettings: ChannelSettings[],
  channel: Channel,
) {
  return channelSettings?.find(
    ({ enumId, isActive }) => enumId === channel && isActive,
  );
}

const PropertyChannelsWidgetListItem = ({
  channel,
  agencyChannelSettings,
  propertyChannelSettings,
  propertyName,
  propertyUid,
}) => {
  const { openModal } = useAppModal();

  const agencyLevelSettings = findActiveChannelSettings(
    agencyChannelSettings,
    channel,
  );
  const propertyLevelSettings = findActiveChannelSettings(
    propertyChannelSettings,
    channel,
  );
  const isPropertyLevelActive = !!propertyLevelSettings;
  const isAgencyLevelActive = !!agencyLevelSettings;

  const {
    agencyChannelLink,
    iconURL,
    isPropertyLinkEditable,
    name,
    propertyChannelLink,
  } = ChannelProps[channel];

  const channelLink =
    isAgencyLevelActive || isPropertyLevelActive
      ? propertyChannelLink
      : agencyChannelLink;

  const propertyChannelExternalLink =
    isPropertyLevelActive &&
    propertyChannelSettings.find((ac) => ac.enumId === channel && ac.isActive)
      ?.externalLink;

  const editLinkHandler = () => {
    openModal({
      customContent: (
        <PropertyChannelsWidgetEditLinkModal
          channelName={name}
          propertyName={propertyName}
          propertyChannelExternalLink={propertyChannelExternalLink}
          propertyUid={propertyUid}
        />
      ),
      id: editExternalLinkModalId,
    });
  };

  return (
    <li key={channel}>
      <PropertyChannelsListItem>
        <PropertyChannelIconContainer>
          <a href={channelLink} rel="noreferrer" target="_blank">
            <PropertyChannelIcon
              $isActive={isAgencyLevelActive}
              src={iconURL}
            />
          </a>
        </PropertyChannelIconContainer>
        <PropertyChannelNameContainer>
          {propertyChannelExternalLink ? (
            <a
              href={propertyChannelExternalLink}
              rel="noreferrer"
              target="_blank"
            >
              {name}
            </a>
          ) : (
            <PropertyChannelNameInactive>{name}</PropertyChannelNameInactive>
          )}
          {isPropertyLinkEditable && isPropertyLevelActive && (
            <PropertyChannelLinkEditIcon onClick={editLinkHandler} />
          )}
        </PropertyChannelNameContainer>
      </PropertyChannelsListItem>
    </li>
  );
};

export default PropertyChannelsWidgetListItem;
