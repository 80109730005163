import { useContext, useEffect } from 'react';
import useAppQuery from 'hooks/useAppQuery';
import API from 'services/API';

import { Quote, QuoteOverride, QuoteResponse } from 'models/Quote';
import { useFormContext, useWatch } from 'react-hook-form';
import useAppUser from 'hooks/useAppUser';
import { getUnitType } from 'utils/lead/leadUtils';
import { format } from 'date-fns';
import LeadModalContext from './LeadModalContext';

function dropCleaningFeeIfFieldsAreNull(
  overrides: QuoteOverride,
  quote: Quote,
) {
  // If the cleaning fee is not set, we need to set it to the quote
  if (!overrides) {
    return overrides;
  }

  const cloned = { ...overrides };
  if (cloned?.fees?.cleaningFee?.netPrice == null) {
    cloned.fees.cleaningFee = { ...quote?.fees?.cleaningFee };
  }
  return cloned;
}

const useQuoteData = () => {
  const { setQuote, availableProperties, quote } = useContext(LeadModalContext);
  const {
    overrides,
    uid: leadUid,
    checkInDate,
    checkOutDate,
    adultCount,
    childrenCount,
    propertyUid,
  } = useWatch();
  const overridesAdjusted = dropCleaningFeeIfFieldsAreNull(overrides, quote);
  const { agencyUid } = useAppUser();
  const { setValue } = useFormContext();

  const { data: newQuote, isLoading: isQuoteLoading } = useAppQuery(
    [
      `get-quote-${leadUid}`,
      overridesAdjusted,
      leadUid,
      checkInDate,
      checkOutDate,
      adultCount,
      childrenCount,
    ],
    async () => {
      const unitType = getUnitType(availableProperties, propertyUid);

      const response = await API.post<QuoteResponse>('/api/v3/quotes', {
        leadUid: leadUid || undefined,
        overrides: overridesAdjusted,
        agencyUid,
        propertyUid: unitType?.uid || propertyUid,
        checkInDate: format(checkInDate, 'yyyy-MM-dd'),
        checkOutDate: format(checkOutDate, 'yyyy-MM-dd'),
        guests: parseInt(adultCount, 10) + parseInt(childrenCount, 10),
      });
      return response.data.quote;
    },
    {
      enabled: !!agencyUid && !!propertyUid && !!overridesAdjusted,
    },
  );

  // This initial quote will be triggered when the user update the  property or select a lead.
  // We need to do this call in order to get overrides from the property and set it to the quote
  // Once the override is set, we can use the newQuote to update the quote
  const { data: initialQuote, isLoading: isInitialQuoteLoading } = useAppQuery(
    [`get-quote-${propertyUid}`, propertyUid],
    async () => {
      const unitType = getUnitType(availableProperties, propertyUid);
      const response = await API.post<QuoteResponse>('/api/v3/quotes', {
        leadUid: leadUid || undefined,
        agencyUid,
        propertyUid: unitType?.uid || propertyUid,
        checkInDate: format(checkInDate, 'yyyy-MM-dd'),
        checkOutDate: format(checkOutDate, 'yyyy-MM-dd'),
        guests: parseInt(adultCount, 10) + parseInt(childrenCount, 10),
      });
      return response.data.quote;
    },
    {
      enabled: !!agencyUid && !!propertyUid,
    },
  );

  useEffect(() => {
    if (initialQuote) {
      setQuote(initialQuote);
      setValue('overrides', { ...initialQuote?.overrides });
    }
  }, [initialQuote]);

  useEffect(() => {
    if (newQuote) {
      setQuote(newQuote);
    }
  }, [newQuote]);

  return {
    isLoading: isQuoteLoading || isInitialQuoteLoading,
  };
};

export default useQuoteData;
