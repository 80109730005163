export enum PinCodeType {
  REMOTELOCK = 'REMOTELOCK',
  IGLOOHOME = 'IGLOOHOME',
  VIRTUALKEY = 'VIRTUALKEY',
  POINTCENTRAL = 'POINTCENTRAL',
  OTHER = 'OTHER',
}

export interface PinCodeLead {
  pinCode: string;
  pinCodeUrl: string;
  uid: string;
}

export interface PinCode {
  endDateTime: string;
  leadUid: string;
  lockType: PinCodeType;
  pinCode: string;
  pinCodeUrl: string;
  startDateTime: string;
  uid: string;
  lockName?: string;
}

export type GetPinCodesResponseTO = PinCode[];
