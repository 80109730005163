import { useContext } from 'react';
import CalendarContext from 'pages/stackedCalendar/CalendarContext';
import Loader from 'components/loader/Loader';
import useStackedCalendarVisibleProperties from '../stackedCalendar/useStackedCalendarVisibleProperties';
import Navigation from './navigation/Navigation';
import { CalendarHeaderWrapper, Container } from './Calendar.styles';
import StandaloneCalendarContextProvider from './CalendarContextProvider';
import BodyWrapper from './body/BodyWrapper';
import CalendarHeader from './header/CalendarHeader';

const Calendar = () => {
  const { selectedProperty, mode, propertiesMap } = useContext(CalendarContext);
  const visibleProperties = useStackedCalendarVisibleProperties();

  if (!selectedProperty) {
    return null;
  }

  // this relies on a side effect of stacked calendar data fetching and should be refactored when possible
  // propertiesMap[selectedProperty] cannot be checked as that map is an immutable reference now and does not change when new entries are added to it
  // visibleProperties[selectedProperty] cannot be checked as visibleProperties keys include also a business type
  // the additional !propertiesMap[selectedProperty] condition is required for MU units that might not be added to the visibleProperties collection
  if (
    !visibleProperties?.find((propertyUid) =>
      propertyUid.startsWith(selectedProperty),
    ) &&
    !propertiesMap[selectedProperty]
  ) {
    return <Loader />;
  }

  return (
    <StandaloneCalendarContextProvider>
      <CalendarHeaderWrapper>
        <CalendarHeader />
      </CalendarHeaderWrapper>
      <Container $mode={mode}>
        <Navigation />
        <BodyWrapper />
      </Container>
    </StandaloneCalendarContextProvider>
  );
};

export default Calendar;
