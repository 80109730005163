import useAppEvent from 'hooks/useAppEvent';
import useAppModal from 'hooks/useAppModal';
import useAppMutation from 'hooks/useAppMutation';
import useAppQuery from 'hooks/useAppQuery';
import useNotify from 'hooks/useNotify';
import { AppEventType } from 'models/Events';
import { useTranslation } from 'react-i18next';
import API from 'services/API';

interface UseJobActionsProps {
  modalId: string;
  jobUid?: string;
}

interface JobSavingPayload {
  status: string;
  propertyUid: string;
  startLocalDateTime: string;
  endLocalDateTime: string;
  notes: string;
  serviceUid: string;
  uid?: string;
}

const useJobActions = ({ jobUid, modalId }: UseJobActionsProps) => {
  const { notifySuccess, notifyError } = useNotify();
  const { closeModal } = useAppModal();
  const { t } = useTranslation();
  const { publish } = useAppEvent();

  const isUpdateType = !!jobUid;

  const { mutateAsync: createJob, isLoading: isCreateJobLoading } =
    useAppMutation((payload: JobSavingPayload) => {
      return API.post('/api/v3/jobs', payload, {
        headers: {
          'X-VERSION': '3.1',
        },
      })
        .then(({ data }) => {
          publish(AppEventType.JOB_CREATED, { job: data.job });
          notifySuccess(t('pageCalendar.stacked.jobCreated'));
          closeModal(modalId);
          return data.job;
        })
        .catch(() => {
          notifyError(t('pageCalendar.stacked.somethingWentWrong'));
        });
    });

  const { data: currentJob } = useAppQuery(
    ['currentJob'],
    async () => {
      const response = await API.get(`/api/v3/jobs/${jobUid}`, {
        headers: {
          'X-VERSION': '3.1',
        },
      });
      return response.data.job;
    },
    {
      enabled: isUpdateType,
    },
  );

  const { mutate: updateJob, isLoading: isUpdateJobLoading } = useAppMutation(
    (payload: JobSavingPayload) => {
      return API.put(`/api/v3/jobs/${jobUid}`, payload, {
        headers: {
          'X-VERSION': '3.1',
        },
      });
    },
    {
      onSuccess: ({ data }) => {
        publish(AppEventType.JOB_UPDATED, { job: data.job });
        notifySuccess(t('pageCalendar.stacked.jobUpdated'));
        closeModal(modalId);
      },
      onError: () => notifyError(t('pageCalendar.stacked.somethingWentWrong')),
    },
  );

  const { mutate: deleteJob, isLoading: isDeleteJobLoading } = useAppMutation(
    () => {
      return API.delete(`/api/v3/jobs/${jobUid}`);
    },
    {
      onSuccess: () => {
        publish(AppEventType.JOB_DELETED, { jobUid });
        notifySuccess(t('pageCalendar.stacked.jobDeleted'));
        closeModal(modalId);
      },
      onError: () => notifyError(t('pageCalendar.stacked.somethingWentWrong')),
    },
  );

  const isAnyRequestLoading =
    isCreateJobLoading || isUpdateJobLoading || isDeleteJobLoading;

  return {
    createJob,
    currentJob,
    updateJob,
    deleteJob,
    isAnyRequestLoading,
    isUpdateType,
  };
};

export default useJobActions;
