export enum Channel {
  'airbnb' = 'airbnb',
  'booking_com' = 'booking_com',
  'golightly' = 'golightly',
  'google' = 'google',
  'homads' = 'homads',
  'homeaway' = 'homeaway',
  'hometogo' = 'hometogo',
  'hvmi' = 'hvmi',
  'orbirental' = 'orbirental',
  'tripadvisor' = 'tripadvisor',
  'vacaymyway' = 'vacaymyway',
}

export interface ChannelSettings {
  enumId: Channel;
  externalLink?: string;
  isActive: boolean;
  name: string;
}

export type ChannelSettingsResponseTO = ChannelSettings[];

export interface ChannelSettingsUpdatePayload {
  channelName: string;
  link: string;
  propertyUid: string;
  successCallback?: Function;
}

export const ChannelProps = {
  [Channel.airbnb]: {
    enumId: Channel.airbnb,
    name: 'Airbnb',
    iconURL: '/img/channels/airbnb_icon.png',
    agencyChannelLink: '#/channels?_ref=airbnb_ref',
    propertyChannelLink: '#/channels/airbnb/sync',
    isPropertyLinkEditable: false,
  },
  [Channel.booking_com]: {
    enumId: Channel.booking_com,
    name: 'Booking.com',
    iconURL: '/img/bookingcom_favicon.ico',
    agencyChannelLink: '#/channels?_ref=bookingdotcom_ref',
    propertyChannelLink: '#/channels/bookingdotcom/sync',
    isPropertyLinkEditable: true,
  },
  [Channel.golightly]: {
    enumId: Channel.golightly,
    name: 'Golightly',
    iconURL: '/img/channels/golightly-icon.png',
    agencyChannelLink: '#/channels?_ref=golightly_ref',
    propertyChannelLink: '#/channels/default/sync?chname=Golightly',
    isPropertyLinkEditable: false,
  },
  [Channel.google]: {
    enumId: Channel.google,
    name: 'Google',
    iconURL: '/img/channels/google_travel_logo.png',
    agencyChannelLink: '#/channels?_ref=google_ref',
    propertyChannelLink: '#/channels/google/sync',
    isPropertyLinkEditable: true,
  },
  [Channel.homads]: {
    enumId: Channel.homads,
    name: 'Homads',
    iconURL: '/img/channels/homads-icon.ico',
    agencyChannelLink: '#/channels?_ref=homads_ref',
    propertyChannelLink: '#/channels/default/sync?chname=Homads',
    isPropertyLinkEditable: false,
  },
  [Channel.homeaway]: {
    enumId: Channel.homeaway,
    name: 'Vrbo',
    iconURL: '/img/channels/vrbo-icon.png',
    agencyChannelLink: '#/channels?_ref=homeaway_ref',
    propertyChannelLink: '#/channels/homeaway/sync',
    isPropertyLinkEditable: false,
  },
  [Channel.hometogo]: {
    enumId: Channel.hometogo,
    name: 'HomeToGo',
    iconURL: '/img/channels/hometogo_icon.png',
    agencyChannelLink: '#/channels?_ref=hometogo_ref',
    propertyChannelLink: '#/channels/default/sync?chname=Hometogo',
    isPropertyLinkEditable: false,
  },
  [Channel.hvmi]: {
    enumId: Channel.hvmi,
    name: 'HVMI',
    iconURL: '/img/channels/hvmi_favicon.png',
    agencyChannelLink: '#/channels?_ref=hvmi_ref',
    propertyChannelLink: '#/channels/default/sync?chname=HVMI',
    isPropertyLinkEditable: false,
  },
  [Channel.orbirental]: {
    enumId: Channel.orbirental,
    name: 'Hostfully',
    iconURL: '/img/favicon.ico',
    agencyChannelLink: '#/channels?_ref=hostfully_ref',
    propertyChannelLink: '#/channels/default/sync?chname=Orbirental',
    isPropertyLinkEditable: false,
  },
  [Channel.tripadvisor]: {
    enumId: Channel.tripadvisor,
    name: 'TripAdvisor',
    iconURL: '/img/channels/tripadvisor_icon.png',
    agencyChannelLink: '#/channels?_ref=tripadvisor_ref',
    propertyChannelLink: '#/channels/default/sync?chname=TripAdvisor',
    isPropertyLinkEditable: true,
  },
  [Channel.vacaymyway]: {
    enumId: Channel.vacaymyway,
    name: 'VacayMyWay',
    iconURL: '/img/channels/vacaymyway-icon.ico',
    agencyChannelLink: '#/channels?_ref=vacaymyway_ref',
    propertyChannelLink: '#/channels/default/sync?chname=VacayMyWay',
    isPropertyLinkEditable: false,
  },
};
