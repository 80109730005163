import styled from 'styled-components';
import { Glyphicon } from 'react-bootstrap';

const CloseIcon = styled(Glyphicon).attrs({
  glyph: 'remove',
})<{ disabled?: boolean }>`
  padding: 0.5em;
  position: absolute;
  top: 0.1em;
  right: 0.1em;
  z-index: 1100;

  ${(props) =>
    !props.disabled &&
    `
    cursor: pointer;
    
    :hover {
      background-color: #f5f5f5;
      border-radius: 50%;
    }
  `}
`;
export default CloseIcon;
