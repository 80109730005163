import { setLocale } from 'yup';

type LocaleObject = Parameters<typeof setLocale>[0];

const yupLocale: LocaleObject = {
  mixed: {
    default: {
      key: 'form.validation.invalid',
    },
    required: {
      key: 'form.validation.required',
    },
    notType: {
      key: 'form.validation.required',
    },
  },
  string: {
    email: {
      key: 'form.validation.invalidEmail',
    },
    min: ({ min }) => ({
      key: 'form.validation.min',
      values: { min },
    }),
    max: ({ max }) => ({
      key: 'form.validation.max',
      values: { max },
    }),
    matches: {
      key: 'form.validation.invalid',
    },
    length: ({ length }) => ({
      key: 'form.validation.length',
      values: { length },
    }),
  },
  number: {
    integer: {
      key: 'form.validation.integer',
    },
    min: ({ min }) => ({
      key: 'form.validation.numberMin',
      values: { min },
    }),
    max: ({ max }) => ({
      key: 'form.validation.numberMax',
      values: { max },
    }),
  },
  boolean: {},
};

export default yupLocale;
