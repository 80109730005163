import useFetchOrderViewInitData from 'components/domain/order/useFetchOrderViewInitData';
import useFetchLead, {
  LEAD_GQL_QUERY_BASE,
} from 'components/domain/lead/useFetchLead';
import useAppUser from '../../hooks/useAppUser';

const orderViewLeadFields = `
  assignee{bestProfile, uid, picture},
  availablePipelineActions,
  channel{bestProfile, channel},
  uid,
  firstName,
  lastName, 
  email, 
  leadType, 
  referrer, 
  status, 
  channel{bestProfile, channel},
  source,
  leadType,
  bookedDateTimeString,
  createdDateTimeString,
  checkInLocalDateTime, 
  checkOutLocalDateTime, 
  childrenCount, 
  adultCount, 
  petCount, 
  infantCount, 
  status,
  referrer,
  hasCompletedPreArrivalForm,
  agreementSigned,
  autoRefundFail,
  autoRefundOk,
  usePreAuthForDeposits,
  wasSecurityDepositReceived,
  wasSecurityDepositReturned,
  wereCheckInInstructionsSent,
  paidInFull,
  property{
    uid,
    name, 
    businessType, 
    mainPicture {
      largeThumbnail
    }
    pricing {
      fullPaymentTiming
    }
  },
  creator{type},
  countdown {
    color,
    countdownMinutes,
    countdownPercentage,
    overdue,
    visualCountdownValue
  },
  riskScore,
  order {
    balance,
    currency,
    securityDeposit,
    totalAmount,
    uid,
    includeSecurityDepositInTotal,
    balanceDueLocalDate,
    adjustmentItems {
      uid,
      transactionType,
      adjustmentType,
      amount,
      created,
      externalId,
      note
    },
    transactions {
      amount,
      autoRefundUtcDateTime,
      createdUtcDateTime,
      errorMessage,
      manual,
      notes,
      refundable,
      refundableAmount,
      status,
      transactionId,
      type,
      uid
    },
    services {
      title,
      grossPrice,
      quantity,
      quantityDescription
    },
    fees {
      cleaningFee{
        name,
        amountType,
        isEditable,
        netPrice,
        taxAmount,
        grossAmount,
        grossPrice,
        taxationRate,
      },
      otherFees{
        name,
        amountType,
        isEditable,
        netPrice,
        taxAmount,
        grossAmount,
        grossPrice,
        taxationRate,
      },
    },
    rent {
      rentBaseNetPrice,
      rentBreakdowns {
        nightlyBaseAmount,
        nightlyDate
      },
      extraGuestsBaseNetPrice,
      listPrice,
      rateMultiplier,
      rateMultiplierAmount,
      grossPrice,
      netPrice,
      taxationRate,
      taxAmount,
      discount {
        discountCode,
        discountType,
        amount,
        percent
      }
    },
    channelCommissionMetadata {
      commissionAmount
    },
  },
  hotel { 
    name,
    uid 
  },
  externalBookingID
`;

const useOrderViewInit = ({ orderUid }: { orderUid: string }) => {
  const { isEmployee } = useAppUser();

  const {
    isFetching: isOrderViewInitDataLoading,
    orderViewInitData,
    error,
  } = useFetchOrderViewInitData({
    orderUid,
  });

  const { lead, isFetching } = useFetchLead({
    leadUid: orderViewInitData?.leadUid,
    fields: orderViewLeadFields,
    enabled: !!orderViewInitData?.leadUid && isEmployee,
  });

  return {
    isLoading: isOrderViewInitDataLoading || isFetching,
    error,
    orderViewInitData,
    lead,
  };
};

export function getOrderViewLeadQueryKey(leadUid: string) {
  return [LEAD_GQL_QUERY_BASE, leadUid, orderViewLeadFields];
}

export default useOrderViewInit;
