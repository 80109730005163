import { useCallback } from 'react';
import {
  Checkbox as BSCheckbox,
  CheckboxProps as BSCheckboxProps,
} from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';

export interface CheckboxFieldProps extends BSCheckboxProps {
  name: string;
  indeterminate?: boolean;
}

/**
 * This is a wrapper around the react-bootstrap Checkbox component.
 * Used for checkboxes in forms used by React Hook Form.
 */
const CheckboxField = ({
  name,
  indeterminate,
  ...props
}: CheckboxFieldProps) => {
  const { control } = useFormContext();

  const refSetIndeterminate = useCallback(
    (element: HTMLInputElement) => {
      if (element) {
        element.indeterminate = indeterminate;
      }
    },
    [indeterminate],
  );

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onBlur, onChange, value, ref } }) => (
        <BSCheckbox
          id={name}
          data-testid={name}
          onBlur={onBlur}
          checked={value}
          onChange={onChange}
          inputRef={(element) => {
            refSetIndeterminate(element);
            return ref(element);
          }}
          {...props}
        />
      )}
    />
  );
};

CheckboxField.defaultProps = {
  indeterminate: undefined,
};

export default CheckboxField;
