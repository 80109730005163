import { SortingState } from '@tanstack/react-table';
import { OwnerAdjustment } from 'models/ownerAdjustments/OwnerAdjustment';
import { OwnerAdjustmentSchedule } from 'models/ownerAdjustments/OwnerAdjustmentSchedule';

const mapColumnToSortParam: {
  [key in keyof Partial<OwnerAdjustment & OwnerAdjustmentSchedule>]: string;
} = {
  created: 'SORT_BY_CREATED_ON_DATE',
  dateAccountedFor: 'SORT_BY_DATE_ACCOUNTED_FOR',
  name: 'SORT_BY_NAME',
  amount: 'SORT_BY_AMOUNT',
  nextScheduled: 'SORT_BY_NEXT_SCHEDULED',
  recurringExpensePeriod: 'SORT_BY_PERIOD',
  status: 'SORT_BY_STATUS',
};

const getSortParam = (sorting: SortingState) => {
  const [currentSort] = sorting;
  const { id, desc } = currentSort ?? {};
  const sortParam = mapColumnToSortParam[id];

  return sortParam
    ? { sort: sortParam, direction: desc ? 'DESC' : 'ASC' }
    : null;
};

export const getQueryParams = ({
  propertyUid,
  pageIndex,
  pageSize,
  sorting,
}: {
  propertyUid: string;
  pageIndex: number;
  pageSize: number;
  sorting: SortingState;
}) => {
  const { sort, direction } = getSortParam(sorting) ?? {};
  const params = new URLSearchParams();
  params.set('propertyUid', propertyUid);
  params.set('_offSet', `${pageIndex * pageSize}`);
  params.set('_limit', `${pageSize}`);
  if (sort && direction) {
    params.set('sort', sort);
    params.set('sortDirection', direction);
  }

  return params.toString();
};
