import { format } from 'date-fns';
import { TFunction, useTranslation } from 'react-i18next';
import { Lead } from '../../../models/Leads';
import {
  getFullName,
  getLabelSource,
  getLeadCheckInDate,
  getLeadCheckOutDate,
  getLeadDetailLocalizedIntValue,
  getLeadLocalizedNightsCount,
} from '../../../utils/lead/leadUtils';
import useFormatCurrency from '../../../hooks/useFormatCurrency';

function getGuestsDetails(
  { adultCount, childrenCount, infantCount, petCount }: Lead,
  t: TFunction,
) {
  return [
    getLeadDetailLocalizedIntValue(adultCount, 'adult', t),
    getLeadDetailLocalizedIntValue(childrenCount, 'child', t),
    getLeadDetailLocalizedIntValue(infantCount, 'infant', t),
    getLeadDetailLocalizedIntValue(petCount, 'pet', t),
  ]
    .filter((details) => !!details)
    .join(', ');
}

const LeadTooltipBookingDetails = ({ lead }: { lead: Lead }) => {
  const formatCurrency = useFormatCurrency();
  const { t } = useTranslation();

  const { source, order, ownerCommission } = lead;
  const fullName = getFullName(lead);
  const guestDetails = fullName ? getGuestsDetails(lead, t) : null;

  return (
    <>
      {!!fullName && (
        <>
          {`${getLabelSource(source)}: ${getFullName(lead)}`}
          <br />
        </>
      )}
      {`${format(
        getLeadCheckInDate(lead),
        t('pageCalendar.tooltip.dateFormat'),
      )} - ${format(
        getLeadCheckOutDate(lead),
        t('pageCalendar.tooltip.dateFormat'),
      )} (${getLeadLocalizedNightsCount(lead, t)})`}
      {!!guestDetails && (
        <>
          <br />
          {guestDetails}
        </>
      )}
      {!!order && (
        <>
          <br />
          {formatCurrency({
            value: order.totalAmount,
            currency: order.currency,
            options: {
              round: true,
              removeDecimals: true,
            },
          })}
        </>
      )}
      {!!ownerCommission && (
        <>
          <br />
          {formatCurrency({
            value: ownerCommission.amount,
            currency: ownerCommission.currency,
            options: {
              round: true,
              removeDecimals: true,
            },
          })}
        </>
      )}
    </>
  );
};

export default LeadTooltipBookingDetails;
