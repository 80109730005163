import { useTranslation } from 'react-i18next';
import { PropertyBase, PropertyBusinessType } from 'models/Properties';
import MasterUnitIcon from './icons/MasterUnitIcon.svg?react';
import MultiUnitIcon from './icons/MultiUnitIcon.svg?react';
import { PropertyLabelStyled } from './PropertyListItem.styles';

interface PropertyLabelProps {
  property: Pick<PropertyBase, 'businessType'> & {
    subUnits?: PropertyBase['subUnits'];
  };
}

const PropertyLabel = ({ property }: PropertyLabelProps) => {
  const { t } = useTranslation();

  if (property.businessType === PropertyBusinessType.HOTEL) {
    return (
      <PropertyLabelStyled>
        <MultiUnitIcon />
        {t('pageProperties.label.multiUnit')}
      </PropertyLabelStyled>
    );
  }

  if (property.subUnits?.length) {
    return (
      <PropertyLabelStyled>
        <MasterUnitIcon />
        {t('pageProperties.label.masterUnit')}
      </PropertyLabelStyled>
    );
  }

  return null;
};

export default PropertyLabel;
