import styled from 'styled-components';
import theme from 'styles/theme';

export const OrderViewPriceDetailsListWrapper = styled.div`
  border: 1px solid ${theme.colors.gray300};
  border-radius: 5px;
  margin-bottom: 32px;
  font-size: 16px;
`;

export const PriceDetailsList = styled.div``;

export const PriceDetailsListTotal = styled.div`
  background-color: #f3f3f7;
  font-weight: bold;

  > div:not(:last-child) {
    border-bottom: 1px solid ${theme.colors.gray500};
  }

  > div {
    margin: 0 0.5rem;
  }
`;
