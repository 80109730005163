import styled from 'styled-components';
import AsyncTypeAheadField from 'components/forms/typeAheadField/AsyncTypeAheadField';

export const LeadSearchFieldAsyncTypeahead = styled(AsyncTypeAheadField)`
  .dropdown-menu {
    width: 260px !important;
  }
`;

export const LeadPropertyPicture = styled.img.attrs({
  className: 'img-circle',
})`
  height: 50px;
  width: 50px;
`;

export const LeadSearchOption = styled.div`
  align-items: center;
  display: flex;
  padding: 0.4em;

  :hover {
    background-color: #f5f5f5;
  }
`;

export const LeadSearchDetails = styled.div`
  margin-left: 1em;
`;
