import styled from 'styled-components';

export const TDWithoutBorder = styled.td`
  border: none;
`;

export const EditableField = styled.span`
  border-bottom: dashed 1px #0088cc;
  cursor: pointer;

  :hover {
    border-bottom-width: 2px;
    font-style: normal;
  }
`;

export const AmountFieldContainer = styled.div`
  position: relative;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const ResetButton = styled.button`
  padding: 0;
  cursor: pointer;
  outline: none;
  background: none;
  border: 0;
  font-size: 21px;
  position: absolute;
  right: 9px;
  top: -1px;
`;

export const PreWarning = styled.td`
  background-color: rgba(252, 248, 227, 0.3);
`;
