import API from 'services/API';
import useAppQuery from 'hooks/useAppQuery';
import { GetPinCodesResponseTO, PinCode } from 'models/PinCode';

const useFetchPinCodes = (leadUid: string) => {
  const { data: pinCodes, isInitialLoading: isFetching } = useAppQuery(
    ['pin-code-lead', leadUid],
    async (): Promise<PinCode[]> => {
      const response = await API.get<GetPinCodesResponseTO>(
        `/v2/pincodes?leadUid=${leadUid}`,
      );
      return response.data;
    },
    {
      enabled: !!leadUid,
    },
  );

  return { isFetching, pinCodes };
};

export default useFetchPinCodes;
