import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';
import theme from 'styles/theme';

const getGlyphicon = (riskScore: number) => {
  if (riskScore >= 100) return 'ok-sign';
  if (riskScore >= 50) return 'question-sign';
  return 'remove-sign';
};

const getColor = (riskScore: number) => {
  if (riskScore >= 100) return theme.colors.hostfullyGreen;
  if (riskScore >= 50) return theme.colors.hostfullyYellow;
  return theme.colors.hostfullyRed;
};

export const RiskScoreTagGlyphicon = styled(Glyphicon).attrs(
  ({ $riskScore }: { $riskScore: number }) => ({
    glyph: getGlyphicon($riskScore),
  }),
)<{
  $riskScore: number;
}>``;

export const RiskScoreTag = styled.div<{
  $riskScore?: number;
}>`
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  display: flex;
  align-items: center;

  .glyphicon {
    margin-right: 3px;
  }

  ${mediaQuery.sm} {
    font-size: 12px;
  }

  ${(props) => props.$riskScore && `color: ${getColor(props.$riskScore)};`};
`;
