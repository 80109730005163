import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { SOURCES_WITHOUT_PAYOUT } from 'utils/lead/leadUtils.constant';
import { LeadSource, LeadStatus } from 'models/Leads';
import { Col } from 'react-bootstrap';
import useOrderViewFetchedData from '../useOrderViewFetchedData';
import { OrderViewTableHeader } from '../OrderView.styles';
import {
  OrderViewPriceDetailsListWrapper,
  PriceDetailsListTotal,
} from './OrderViewPriceDetails.styles';
import OrderViewPriceDetailsListItemTotal from './OrderViewPriceDetailsListItemTotal';
import Rent from './items/Rent';
import Taxes from './items/Taxes';
import Fees from './items/Fees';
import Services from './items/Services';
import SecurityDeposit from './items/SecurityDeposit';
import AdjustmentItems from './items/AdjustmentItems';
import AddOrderAdjustmentButton from './actions/AddOrderAdjustmentButton';

const OrderViewPriceDetailsList = () => {
  const { lead, orderUid } = useOrderViewFetchedData();
  const { t } = useTranslation();

  const {
    rent,
    fees,
    services,
    adjustmentItems,
    includeSecurityDepositInTotal,
    securityDeposit,
    totalAmount,
    balance,
    balanceDueLocalDate,
  } = lead.order;

  const { fullPaymentTiming } = lead.property.pricing;

  const isImported = lead && SOURCES_WITHOUT_PAYOUT.includes(lead.source);
  const isNewAirbnbLead =
    lead?.source === LeadSource.DIRECT_AIRBNB &&
    lead?.status === LeadStatus.NEW;

  return (
    <div>
      <OrderViewTableHeader>
        <Col xs={6}>
          <h4 data-testid="price-details-title">
            {t('pageOrder.priceDetails.sectionTitle')}
          </h4>
        </Col>
        <Col xs={6} className="text-right">
          {!isImported && (
            <AddOrderAdjustmentButton orderUid={orderUid} lead={lead} />
          )}
        </Col>
      </OrderViewTableHeader>

      <OrderViewPriceDetailsListWrapper data-testid="price-details-wrapper">
        {!!rent && <Rent isImported={isImported} />}
        {!isImported && <Taxes />}
        {!isImported &&
          !isNewAirbnbLead &&
          (fees.otherFees.length > 0 || fees.cleaningFee.grossAmount > 0) && (
            <Fees />
          )}
        {!isImported && services?.length > 0 && <Services />}
        {!isImported && adjustmentItems?.length > 0 && <AdjustmentItems />}
        {!isImported && includeSecurityDepositInTotal && !!securityDeposit && (
          <SecurityDeposit />
        )}

        <PriceDetailsListTotal data-testid="price-details-list-total">
          <OrderViewPriceDetailsListItemTotal
            data-testid="price-details-list-item-total-total"
            label={
              isImported
                ? t('componentOrder.payout')
                : t('componentOrder.total')
            }
            amount={totalAmount}
          />

          {totalAmount - balance > 0 && !isImported && (
            <OrderViewPriceDetailsListItemTotal
              data-testid="price-details-list-item-total-amountPaid"
              label={t('componentOrder.amountPaid')}
              amount={totalAmount - balance}
            />
          )}

          {!isImported && balance > 0 && (
            <OrderViewPriceDetailsListItemTotal
              data-testid="price-details-list-item-total-balance"
              label={t('componentOrder.balanceDue')}
              amount={balance}
              subtitle={
                balanceDueLocalDate &&
                t('componentOrder.balanceDueDate', {
                  date: format(parseISO(balanceDueLocalDate), 'MMM dd, yyyy'),
                  days: fullPaymentTiming,
                })
              }
            />
          )}
        </PriceDetailsListTotal>

        {!isImported &&
          !includeSecurityDepositInTotal &&
          securityDeposit > 0 && <SecurityDeposit />}
      </OrderViewPriceDetailsListWrapper>
    </div>
  );
};

export default OrderViewPriceDetailsList;
