import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const Container = styled.div``;

export const ContentWrapper = styled.div`
  max-width: 1180px;
  margin: 0 auto;
  padding: 20px;
`;

export const HeaderContainer = styled.div`
  width: calc(100vw - 40px);
  max-width: 1140px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h4 {
    font-size: 28px;
    color: #212529;
  }
`;

export const MobileLogo = styled.img`
  display: none;

  ${mediaQuery.md} {
    display: block;
  }
`;

export const DesktopLogo = styled.img`
  ${mediaQuery.md} {
    display: none;
  }
`;

export const Title = styled.h1`
  color: #2d2aa5;
  font-weight: bold;
  font-size: 40px;
  max-width: 856px;
  margin-top: 20px;
`;

export const TurnoWarning = styled.p`
  color: #2d2aa5;
  font-size: 30px;
  font-weight: 400;
  text-align: center;
  margin: 50px 0px 150px 0px;
`;
