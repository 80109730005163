import { useTranslation } from 'react-i18next';
import useOrderViewFetchedData from 'pages/orderView/useOrderViewFetchedData';
import { PriceDetailsList } from '../OrderViewPriceDetails.styles';
import OrderViewPriceDetailsListItem from '../OrderViewPriceDetailsListItem';

const SecurityDeposit = () => {
  const { lead } = useOrderViewFetchedData();
  const { t } = useTranslation();

  const { securityDeposit } = lead.order;

  return (
    <PriceDetailsList data-testid="price-details-list-deposit">
      <OrderViewPriceDetailsListItem
        title={t('componentOrder.deposit')}
        priceDetailsItems={[
          {
            label: t('componentOrder.securityDeposit'),
            amount: securityDeposit,
            isTotalAmount: false,
          },
        ]}
      />
    </PriceDetailsList>
  );
};

export default SecurityDeposit;
