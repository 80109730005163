import styled from 'styled-components';

export const JobViewWrapper = styled.span<{
  $isDone: boolean;
}>`
  z-index: 99;
  position: absolute;
  left: calc(20% - 8px);
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: #0066cc;
  cursor: pointer;
  top: 8px;
  ${({ $isDone }) => $isDone && `background-color: #33cc99;`}
`;
