import useFormatCurrency from 'hooks/useFormatCurrency';
import useOrderViewFetchedData from '../useOrderViewFetchedData';
import {
  OrderViewPriceDetailsListItemTotalSubtitle,
  OrderViewPriceDetailsListItemTotalTitle,
  OrderViewPriceDetailsListItemTotalWrapper,
} from './OrderViewPriceDetailsListItemTotal.styles';

const OrderViewPriceDetailsListItemTotal = ({
  label,
  amount,
  subtitle,
  ...props
}: {
  label: string;
  amount: number;
  subtitle?: string;
}) => {
  const formatCurrency = useFormatCurrency();
  const { lead } = useOrderViewFetchedData();
  const { currency } = lead.order;

  return (
    <OrderViewPriceDetailsListItemTotalWrapper {...props}>
      <OrderViewPriceDetailsListItemTotalTitle>
        <span data-testid="price-details-list-item-total-label">{label}</span>
        {subtitle && (
          <OrderViewPriceDetailsListItemTotalSubtitle data-testid="price-details-list-item-total-subtitle">
            {subtitle}
          </OrderViewPriceDetailsListItemTotalSubtitle>
        )}
      </OrderViewPriceDetailsListItemTotalTitle>
      <div
        data-testid="price-details-list-item-total-value"
        className="text-right"
      >
        {formatCurrency({ value: amount, currency })}
      </div>
    </OrderViewPriceDetailsListItemTotalWrapper>
  );
};

OrderViewPriceDetailsListItemTotal.defaultProps = {
  subtitle: null,
};

export default OrderViewPriceDetailsListItemTotal;
