import { useTranslation } from 'react-i18next';
import PageHeader from 'components/pageHeader/PageHeader';
import turnoLogo from '../../assets/images/partners/turnoLogo.svg';
import turnoLogoCircle from '../../assets/images/partners/turnoLogoCircle.svg';
import {
  Container,
  ContentWrapper,
  HeaderContainer,
  MobileLogo,
  DesktopLogo,
  Title,
  TurnoWarning,
} from './TurnoManagement.styles';
import BenefitsCards from './benefitsCards/BenefitsCards';
import TopRatedCleaner from './topRatedCleaner/TopRatedCleaner';
import ConnectSteps from './connectSteps/ConnectSteps';

const TurnoManagement = () => {
  const { t } = useTranslation();

  return (
    <>
      <Container>
        <ContentWrapper>
          <PageHeader
            title={
              <HeaderContainer>
                <h4>{t('pageTurnoManagement.turnoverManagement')}</h4>
                <DesktopLogo src={turnoLogo} alt="turno logo" />
                <MobileLogo src={turnoLogoCircle} alt="turno logo" />
              </HeaderContainer>
            }
          />
          <Title>{t('pageTurnoManagement.title')}</Title>
          <BenefitsCards />
          <TopRatedCleaner />
        </ContentWrapper>
      </Container>

      <ConnectSteps />

      <Container>
        <ContentWrapper>
          <TurnoWarning>{t('pageTurnoManagement.pleaseNote')}</TurnoWarning>
        </ContentWrapper>
      </Container>
    </>
  );
};

export default TurnoManagement;
