import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useFetchAllProperties from 'components/domain/property/useFetchAllProperties';
import useAppUser from 'hooks/useAppUser';
import { Property } from '../../models/Properties';

const propertySettingsGqlConditions = `
 offset: "0",
 limit: "9999",
 isActive: true,
 topLevelOnly: true,
 returnTopLevelUnitsWithMatchingSubLevels: true,
 sortStrategy: "SORT_BY_NAME"`;

const propertySettingsGqlFields = `
  uid,
  name,
  businessType,
  subUnits{
    uid,
    name,
    businessType,
  },
  unitTypes{
    uid,
    hotelUid
    name,
    businessType,
  }`;

function findMatchingChildProperty(property: Property, propertyUid: string) {
  if (!property) {
    return null;
  }

  const { subUnits, unitTypes } = property;

  return (
    subUnits?.find(({ uid }) => uid === propertyUid) ||
    unitTypes?.find(({ uid }) => uid === propertyUid)
  );
}

const usePropertySettingsInitData = () => {
  const { isEmployee } = useAppUser();
  const { uid: propertyUid } = useParams();

  const { allProperties, isFetching } = useFetchAllProperties({
    conditions: propertySettingsGqlConditions,
    enabled: isEmployee && !!propertyUid,
    fields: propertySettingsGqlFields,
    operationName: 'PropertySettings',
  });

  const matchedProperty = useMemo(
    () =>
      allProperties?.find(
        ({ uid, subUnits, unitTypes }) =>
          uid === propertyUid ||
          subUnits?.find(({ uid: subUnitUid }) => subUnitUid === propertyUid) ||
          unitTypes?.find(
            ({ uid: unitTypeUid }) => unitTypeUid === propertyUid,
          ),
      ),
    [allProperties, propertyUid],
  );

  const property =
    matchedProperty?.uid === propertyUid
      ? matchedProperty
      : findMatchingChildProperty(matchedProperty, propertyUid);

  return { allProperties, isLoading: isFetching, property };
};

export default usePropertySettingsInitData;
