import ThreadAvatar from '../threads/ThreadAvatar';
import useThreadParticipantBestProfile from '../threads/useThreadParticipantBestProfile';
import { InboxMessageAvatarContainer } from './InboxMessages.styles';

const InboxMessageAvatar = ({ threadUid }: { threadUid: string }) => {
  const bestProfile = useThreadParticipantBestProfile(threadUid);
  return (
    <InboxMessageAvatarContainer>
      <ThreadAvatar bestProfile={bestProfile} />
    </InboxMessageAvatarContainer>
  );
};

export default InboxMessageAvatar;
