import { Lead, LeadCircleColor, LeadSource, LeadStatus } from 'models/Leads';
import IconCheck from 'assets/images/icons/check.svg';
import IconEnvelope from 'assets/images/icons/envelope.svg';
import IconLegal from 'assets/images/icons/legal.svg';
import IconReturn from 'assets/images/icons/return.svg';
import IconReturnRed from 'assets/images/icons/return-red.svg';
import theme from 'styles/theme';
import { LeadCircleIconPosition } from './leadCircleIcon/LeadCircleIcon.types';

export const statusesApplicableForShowingIcon = [
  LeadStatus.BOOKED_BY_CUSTOMER,
  LeadStatus.BOOKED_BY_OWNER,
  LeadStatus.BOOKED_BY_AGENT,
  LeadStatus.BOOKED_EXTERNALLY,
  LeadStatus.PAID_IN_FULL,
  LeadStatus.STAY,
  LeadStatus.ARCHIVED,
];

interface IconInfo {
  src: string;
  alt: string;
  position: LeadCircleIconPosition;
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
  tooltipKey: string;
  shouldDisplay: (_lead: Lead) => boolean;
}

export const statusIndicatorAvailableIcons: IconInfo[] = [
  {
    src: IconLegal,
    alt: '',
    position: 'top-right',
    tooltipKey: 'rentalAgreementSigned',
    shouldDisplay: ({ agreementSigned }) => agreementSigned,
  },
  {
    src: IconEnvelope,
    alt: '',
    position: 'bottom-right',
    tooltipKey: 'checkInInstructionSent',
    shouldDisplay: ({ wereCheckInInstructionsSent }) =>
      wereCheckInInstructionsSent,
  },
  {
    src: IconCheck,
    alt: '',
    position: 'bottom-left',
    tooltipKey: 'preArrivalFormCompleted',
    shouldDisplay: ({ hasCompletedPreArrivalForm }) =>
      hasCompletedPreArrivalForm,
  },
  {
    src: IconReturnRed,
    alt: '',
    position: 'top-left',
    tooltipKey: 'autoSecurityDepositRefundFail',
    shouldDisplay: ({ autoRefundFail }) => autoRefundFail,
  },
  {
    src: IconReturn,
    alt: '',
    position: 'top-left',
    tooltipKey: 'autoSecurityDepositRefundOk',
    shouldDisplay: ({ autoRefundOk }) => autoRefundOk,
  },
];

export const statusIndicatorColorToHex = {
  [LeadCircleColor.BLUE]: theme.colors.hostfullyBlue,
  [LeadCircleColor.GREEN]: theme.colors.hostfullyGreen,
  [LeadCircleColor.ORANGE]: theme.colors.hostfullyYellow,
  [LeadCircleColor.RED]: theme.colors.hostfullyRed,
};

type StatusToIndicatorPropsType = {
  [status in LeadStatus]: {
    labelKey: string;
    labelKeyFactory?: (lead: Lead) => string;
    sourceSpecificProps?: {
      [source in LeadSource]?: {
        labelKey: string;
      };
    };
  };
};

export const statusToIndicatorPropsMap: StatusToIndicatorPropsType = {
  [LeadStatus.NEW]: {
    labelKey: 'inquiry',
  },
  [LeadStatus.ON_HOLD]: {
    labelKey: 'onHold',
  },
  [LeadStatus.HOLD_EXPIRED]: {
    labelKey: 'expired',
  },
  [LeadStatus.BOOKED_BY_AGENT]: {
    labelKey: 'checkInIn',
  },
  [LeadStatus.BOOKED_BY_CUSTOMER]: {
    labelKey: 'checkInIn',
  },
  [LeadStatus.BOOKED_BY_OWNER]: {
    labelKey: 'checkInIn',
  },
  [LeadStatus.QUOTE_SENT]: {
    labelKey: 'quoteSent',
    sourceSpecificProps: {
      [LeadSource.DIRECT_AIRBNB]: {
        labelKey: 'preApproved',
      },
    },
  },
  [LeadStatus.DECLINED]: {
    labelKey: 'declined',
  },
  [LeadStatus.CANCELLED]: {
    labelKey: 'cancelled',
  },
  [LeadStatus.IGNORED]: {
    labelKey: 'ignored',
  },
  [LeadStatus.ARCHIVED]: {
    labelKey: 'pastStay',
  },
  [LeadStatus.STAY]: {
    labelKey: 'checkOutIn',
  },
  [LeadStatus.PAID_IN_FULL]: {
    labelKey: 'checkInIn',
  },
  [LeadStatus.DUPLICATE]: {
    labelKey: 'duplicate',
  },
  [LeadStatus.SAMPLE]: {
    labelKey: 'sample',
  },
  [LeadStatus.BOOKED_EXTERNALLY]: {
    labelKey: 'checkInIn',
  },
  [LeadStatus.CLOSED_QUOTE]: {
    labelKey: 'closed',
  },
  [LeadStatus.CLOSED_HOLD]: {
    labelKey: 'closed',
  },
  [LeadStatus.PENDING]: {
    labelKey: 'pending',
    labelKeyFactory: ({ countdown }) =>
      (countdown?.countdownMinutes || countdown?.visualCountdownValue) &&
      'pendingCountdown',
  },
  [LeadStatus.CANCELLED_BY_TRAVELER]: {
    labelKey: 'cancelled',
  },
  [LeadStatus.CANCELLED_BY_OWNER]: {
    labelKey: 'cancelled',
  },
  [LeadStatus.BLOCKED]: null, // status indicator does not apply for blocks
};

export const statusIndicatorInactiveColor = theme.colors.gray300;
