export enum LanguageCode {
  DE = 'de',
  EN = 'en',
  EN_GB = 'en-GB',
  EN_US = 'en-US',
  FR = 'fr',
  PL = 'pl',
  PT = 'pt',
  ES = 'es',
}
