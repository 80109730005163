/* eslint-disable jsx-a11y/label-has-associated-control */
import { useContext, useEffect } from 'react';
import { isEqual, set } from 'date-fns';
import { useFormContext } from 'react-hook-form';
import DateTimePickerField from 'components/forms/dateTimePickerField/DateTimePickerField';
import TextField from 'components/forms/textField/TextField';
import { isLeadBooked, isLeadFieldDisabled } from 'utils/lead/leadUtils';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import LeadModalContext from '../../LeadModalContext';
import GuestInformation from './GuestInformation';
import {
  Container,
  RowSpacer,
  SideInfo,
} from './LeadModalStayDetailsTab.styles';
import PinCodeForm from './PinCodeForm';

const LeadModalStayDetailsTab = () => {
  const { lead } = useContext(LeadModalContext);
  const { t } = useTranslation();
  const {
    watch,
    setValue,
    formState: { defaultValues },
  } = useFormContext();

  const checkInDate = watch('checkInDate');
  const checkOutDate = watch('checkOutDate');
  const arrivalDate = watch('arrivalDate');
  const departureDate = watch('departureDate');

  useEffect(() => {
    if (checkInDate && !isEqual(checkInDate, defaultValues.checkInDate)) {
      const newDate = set(checkInDate, {
        hours: arrivalDate.getHours(),
        minutes: arrivalDate.getMinutes(),
      });

      setValue('arrivalDate', newDate);
    }

    if (checkOutDate && !isEqual(checkOutDate, defaultValues.checkOutDate)) {
      const newDate = set(checkOutDate, {
        hours: departureDate.getHours(),
        minutes: departureDate.getMinutes(),
      });

      setValue('departureDate', newDate);
    }
  }, [checkInDate, checkOutDate, setValue]);

  return (
    <Container>
      <Row>
        <Col xs={8} md={7}>
          <div>
            <div>
              <DateTimePickerField
                colSmInput={5}
                colSmLabel={3}
                label={t('componentLead.modal.stayDetailsTab.checkIn')}
                name="arrivalDate"
                disabled={isLeadFieldDisabled(lead, 'arrivalLocalDateTime')}
              />
              <DateTimePickerField
                colSmInput={4}
                name="arrivalDate"
                timeOnly
                disabled={isLeadFieldDisabled(lead, 'arrivalLocalDateTime')}
              />
            </div>

            <RowSpacer />

            <DateTimePickerField
              colSmInput={5}
              colSmLabel={3}
              label={t('componentLead.modal.stayDetailsTab.checkOut')}
              name="departureDate"
              disabled={isLeadFieldDisabled(lead, 'departureLocalDateTime')}
            />
            <DateTimePickerField
              colSmInput={4}
              name="departureDate"
              timeOnly
              disabled={isLeadFieldDisabled(lead, 'departureLocalDateTime')}
            />

            <RowSpacer />

            {isLeadBooked(lead) && <PinCodeForm />}

            <RowSpacer />

            <TextField
              name="extraNotes"
              label={t('componentLead.modal.stayDetailsTab.extraNotes')}
              placeholder={t(
                'componentLead.modal.stayDetailsTab.extraNotesPlaceholder',
              )}
              componentClass="textarea"
              colSmInput={9}
              colSmLabel={3}
              rows={6}
            />

            <hr />

            <TextField
              label={t('componentLead.modal.stayDetailsTab.flightNumber')}
              name="flightNumber"
              colSmInput={9}
              colSmLabel={3}
            />
            <RowSpacer />
            <TextField
              label={t('componentLead.modal.stayDetailsTab.reasonForTrip')}
              name="reasonForTrip"
              colSmInput={9}
              colSmLabel={3}
            />
          </div>
        </Col>
        <Col xs={4} md={4} mdOffset={1}>
          <SideInfo>
            <Row>
              <Col xs={12}>
                <p>
                  {t(
                    'componentLead.modal.stayDetailsTab.expectedCheckInOutProperty',
                  )}
                </p>
              </Col>
            </Row>
            <hr />
            <GuestInformation />
          </SideInfo>
        </Col>
      </Row>
    </Container>
  );
};

export default LeadModalStayDetailsTab;
