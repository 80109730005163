import { useContext, useEffect } from 'react';
import { addDays } from 'date-fns';
import useOpenJobModal from 'components/modal/jobModal/useOpenJobModal';
import useAppUser from 'hooks/useAppUser';
import { StackedCalendarMode } from 'pages/stackedCalendar/Calendar.types';
import CalendarContext from 'pages/stackedCalendar/CalendarContext';
import useCalendarMode from '../../../../stackedCalendar/useCalendarMode';
import useCalendarOpenLeadModal from '../../lead/useCalendarOpenLeadModal';
import useCalendarOpenPricingModal from '../../pricing/useCalendarOpenPricingModal';
import useCalendarBodySelection from './useCalendarBodySelection';
import { CalendarSelectionLimitProvider } from './CalendaBodySelection.types';
import useCalendarBodySelectionAllowed from './useCalendarBodySelectionAllowed';

function isInactiveDayCell(event) {
  return event.target.closest('div.body-inactive-day-cell');
}

function getJobItem(event) {
  return event.target.closest('span[data-job-uid]');
}

function getLeadItem(event) {
  return event.target.closest('div[data-lead-uid]');
}

const useCalendarBodyMouseEvents = (
  selectionLimitProvider: CalendarSelectionLimitProvider,
) => {
  const { isOwner } = useAppUser();
  const { resetSelection, handleMouseDown, handleMouseUp } =
    useCalendarBodySelection();
  const selectionAllowed = useCalendarBodySelectionAllowed();
  const { openEditLeadModal, openCreateLeadModal } = useCalendarOpenLeadModal();
  const { openPropertyPricingModal } = useCalendarOpenPricingModal();
  const { jobsMap, allProperties } = useContext(CalendarContext);
  const { updateJobModal } = useOpenJobModal({
    jobsMap,
    properties: allProperties,
  });
  const { isPricingMode, isBookingMode, isCombinedMode } = useCalendarMode();
  const isOperatingAsBookingMode = isBookingMode || isCombinedMode;

  useEffect(() => {
    const keydownHandler = ({ key }: KeyboardEvent) => {
      if (key === 'Escape') {
        resetSelection();
      }
    };

    document.addEventListener('keydown', keydownHandler);

    return () => {
      document.removeEventListener('keydown', keydownHandler);
    };
  }, [resetSelection]);

  const onMouseDown = (event) => {
    if (
      !selectionAllowed ||
      isInactiveDayCell(event) ||
      (isOperatingAsBookingMode && (getJobItem(event) || getLeadItem(event)))
    ) {
      return;
    }

    const bodyCell = event.target.closest('div.body-day-cell');
    if (bodyCell) {
      const {
        dataset: { dayDate, propertyUid },
      } = bodyCell;
      const date = new Date(Number(dayDate));
      const isUnitType = bodyCell.dataset.isUnitType === 'true';
      const isUnit = bodyCell.dataset.isUnit === 'true';

      const limit = selectionLimitProvider(date, propertyUid);
      if (
        (isUnitType && isOperatingAsBookingMode) ||
        (isUnit && isPricingMode)
      ) {
        return;
      }
      // no limit means there is no room for creating a selection
      if (limit) {
        handleMouseDown({
          min: date,
          max: isPricingMode ? date : addDays(date, 1),
          propertyUid,
          limit,
          mode: isPricingMode
            ? StackedCalendarMode.PRICING
            : StackedCalendarMode.BOOKING,
        });
      }
    }
  };

  const onMouseUp = (event) => {
    if (isInactiveDayCell(event)) {
      return;
    }

    const jobItem = getJobItem(event);
    if (isOperatingAsBookingMode && jobItem && !isOwner) {
      const jobUids: string[] = jobItem
        .getAttribute('data-job-uid')
        .split('--\u200c--');
      updateJobModal(jobUids);
      return;
    }

    const leadItem = getLeadItem(event);
    if (isOperatingAsBookingMode && leadItem) {
      openEditLeadModal(leadItem.getAttribute('data-lead-uid'));
      resetSelection();
      return;
    }

    const bodyCell = event.target.closest('div.body-day-cell');
    if (bodyCell) {
      const {
        dataset: { dayDate },
      } = bodyCell;
      const date = new Date(Number(dayDate));
      const isUnitType = bodyCell.dataset.isUnitType === 'true';
      const isUnit = bodyCell.dataset.isUnit === 'true';

      if (
        (isUnitType && isOperatingAsBookingMode) ||
        (isUnit && isPricingMode)
      ) {
        return;
      }

      handleMouseUp({
        actionCallback: (selectionData) => {
          if (isOperatingAsBookingMode) {
            resetSelection();
            openCreateLeadModal({
              propertyUid: selectionData.propertyUids[0],
              dateFrom: selectionData.from,
              dateTo: selectionData.to,
            });
          } else {
            openPropertyPricingModal({
              propertyUids: selectionData.propertyUids,
              dateFrom: selectionData.from,
              dateTo: selectionData.to,
            });
          }
        },
        date,
      });
    }
  };

  return { onMouseDown, onMouseUp };
};

export default useCalendarBodyMouseEvents;
