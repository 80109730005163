import {
  CreateLeadModalParams,
  UpdateLeadModalParams,
} from '../components/domain/lead/modal/LeadModal.types';
import { Job } from './Jobs';
import { LeadSavingResult } from './Leads';

export enum AppEventType {
  LEAD_CREATED = 'LEAD_CREATED',
  LEAD_DELETED = 'LEAD_DELETED',
  LEAD_UPDATED = 'LEAD_UPDATED',
  JOB_CREATED = 'JOB_CREATED',
  JOB_DELETED = 'JOB_DELETED',
  JOB_UPDATED = 'JOB_UPDATED',
  PROPERTY_PRICING_PERIODS_UPDATED = 'PROPERTY_PRICING_PERIODS_UPDATED',
  OPEN_CREATE_BLOCK_MODAL = 'OPEN_CREATE_BLOCK_MODAL', // TODO delete once modals merged
  OPEN_CREATE_JOB_MODAL = 'OPEN_CREATE_JOB_MODAL',
  OPEN_CREATE_LEAD_MODAL = 'OPEN_CREATE_LEAD_MODAL',
  OPEN_UPDATE_LEAD_MODAL = 'OPEN_UPDATE_LEAD_MODAL',
  POPOVER_TOGGLE = 'POPOVER_TOGGLE',
}

export interface AppEventPayload {}

export interface LeadCreatedEventPayload extends AppEventPayload {
  lead: LeadSavingResult;
}

export interface LeadDeletedEventPayload extends AppEventPayload {
  leadUid: string;
}

export interface LeadUpdatedEventPayload extends AppEventPayload {
  lead: LeadSavingResult;
}

export interface JobCreateEventPayload extends AppEventPayload {
  job: Job;
}

export interface JobUpdatedEventPayload extends AppEventPayload {
  job: Job;
}

export interface JobDeletedEventPayload extends AppEventPayload {
  jobUid: string;
}

export interface PropertyPricingPeriodsUpdatedEventPayload
  extends AppEventPayload {
  updatedPricingPeriods: {
    date: string;
    propertyUid: string;
  }[];
}

export interface PopoverToggleEventPayload extends AppEventPayload {
  popoverId: string;
}

export interface OpenCreateLeadModalEventPayload extends AppEventPayload {
  params: CreateLeadModalParams;
}

export interface OpenUpdateLeadModalEventPayload extends AppEventPayload {
  params: UpdateLeadModalParams;
}
