import styled from 'styled-components';

export const OrderViewPriceDetailsListItemTotalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 0.5rem;
`;

export const OrderViewPriceDetailsListItemTotalSubtitle = styled.div`
  margin-left: 0.5rem;
  font-weight: normal;
  font-size: 14px;
`;

export const OrderViewPriceDetailsListItemTotalTitle = styled.div`
  display: flex;
`;
