import { BreadcrumbItem } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import BookIconSvg from 'assets/images/icons/book.svg?react';
import CalendarIconSvg from 'assets/images/icons/calendar.svg?react';
import Button from 'components/button/Button';
import FormWithProvider from 'components/forms/form/Form';
import { Property, PropertySubUnit, PropertyUnitType } from 'models/Properties';
import { normalizeKey } from 'utils/localeUtils';
import PropertySettingsTabHeaderPropertySelector from './PropertySettingsTabHeaderPropertySelector';
import {
  PropertySettingsBreadcrumb,
  PropertySettingsTabHeaderButtons,
  PropertySettingsTabHeaderContainer,
} from './PropertySettingsTabHeader.styles';
import PropertySettingsTabHotelBreadcrumbItem from './PropertySettingsTabHotelBreadcrumbItem';
import usePropertyGuidebookLink from './usePropertyGuidebookLink';

const PropertySettingsTabHeader = ({
  activeTab,
  property,
}: {
  activeTab: string;
  property: Property | PropertySubUnit | PropertyUnitType;
}) => {
  const { uid: selectedPropertyUid } = property;
  const navigate = useNavigate();
  const guidebookLink = usePropertyGuidebookLink(selectedPropertyUid);
  const { t } = useTranslation();

  const goToCalendar = () => {
    navigate(`/calendar?propertyUid=${selectedPropertyUid}`);
  };

  return (
    <PropertySettingsTabHeaderContainer>
      <FormWithProvider onSubmit={() => {}} defaultValues={{}}>
        <PropertySettingsBreadcrumb>
          {/* TODO change link when GA */}
          <BreadcrumbItem href="#/properties-migrated">
            {t('common.pageTitles.properties')}
          </BreadcrumbItem>
          <PropertySettingsTabHotelBreadcrumbItem
            activeTab={activeTab}
            property={property}
          />
          <BreadcrumbItem active>
            <PropertySettingsTabHeaderPropertySelector
              activeTab={activeTab}
              currentPropertyUid={selectedPropertyUid}
            />
          </BreadcrumbItem>
          <BreadcrumbItem active>
            {t(normalizeKey(`pageProperty.tabNames.${activeTab}`))}
          </BreadcrumbItem>
        </PropertySettingsBreadcrumb>
      </FormWithProvider>
      <PropertySettingsTabHeaderButtons>
        <Button bsStyle="link" onClick={goToCalendar}>
          <CalendarIconSvg />
          {t('pageProperty.header.calendar')}
        </Button>
        {!!guidebookLink && (
          <Button bsStyle="link" href={guidebookLink} target="_blank">
            <BookIconSvg />
            {t('pageProperty.header.guidebook')}
          </Button>
        )}
      </PropertySettingsTabHeaderButtons>
    </PropertySettingsTabHeaderContainer>
  );
};

export default PropertySettingsTabHeader;
