import { Row } from 'react-bootstrap';
import styled from 'styled-components';
import theme from 'styles/theme';

export const Container = styled.div.attrs({
  className: 'container',
})`
  margin-bottom: 2rem;
`;

export const GuestName = styled.span`
  color: ${theme.colors.hostfullyGreen};
`;

export const OrderViewTableHeader = styled(Row)`
  button {
    padding-right: 0;
  }

  h4 {
    font-weight: 700;
    font-size: 20px;
  }
`;

export const OrderViewTitleWrapper = styled.div`
  display: flex;
  align-items: center;

  .tooltip-container {
    margin-left: 8px;
  }
`;
