import { Property } from 'models/Properties';
import useAppModal from '../../../../hooks/useAppModal';
import LeadModal from './LeadModal';
import {
  CreateLeadModalParams,
  UpdateLeadModalParams,
} from './LeadModal.types';

const createLeadModalId = 'create-lead-modal';
const updateLeadModalId = 'update-lead-modal';

const useOpenLeadModal = (availableProperties?: Property[]) => {
  const { openModal } = useAppModal();

  const openLeadModal = (
    params: CreateLeadModalParams | UpdateLeadModalParams,
  ) => {
    const modalId = (params as UpdateLeadModalParams).leadUid
      ? updateLeadModalId
      : createLeadModalId;

    openModal({
      customContent: (
        <LeadModal
          availableProperties={availableProperties}
          modalId={modalId}
          params={params}
        />
      ),
      id: modalId,
      size: 'lg',
    });
  };

  return {
    createLeadModal: (params: CreateLeadModalParams) => {
      openLeadModal(params);
    },
    updateLeadModal: (params: UpdateLeadModalParams) => {
      openLeadModal(params);
    },
  };
};

export default useOpenLeadModal;
