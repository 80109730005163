import { useTranslation } from 'react-i18next';
import useOrderViewFetchedData from 'pages/orderView/useOrderViewFetchedData';
import { Fee } from 'models/order/Fees';
import { PriceDetailsList } from '../OrderViewPriceDetails.styles';
import OrderViewPriceDetailsListItem from '../OrderViewPriceDetailsListItem';
import { PriceDetailsItem } from '../OrderViewPriceDetailsListItemRow';

const Taxes = () => {
  const { lead } = useOrderViewFetchedData();
  const { t } = useTranslation();

  const { rent, fees } = lead.order;

  const getTaxFees = (otherFees: Fee[]): PriceDetailsItem[] => {
    return otherFees?.reduce((acc: PriceDetailsItem[], fee: Fee) => {
      if (fee.taxAmount > 0) {
        const feeTaxItem: PriceDetailsItem = {
          label: t('componentOrder.otherFeeTax', {
            name: fee.name,
            tax: fee.taxationRate,
          }),
          amount: fee.taxAmount,
        };

        acc.push(feeTaxItem);
      }

      return acc;
    }, []);
  };

  const totalTaxes =
    fees.otherFees.reduce((acc, fee) => acc + fee.taxAmount, 0) +
    fees.cleaningFee.taxAmount +
    rent.taxAmount;

  if (!totalTaxes || totalTaxes <= 0) return null;

  return (
    !!rent && (
      <PriceDetailsList data-testid="price-details-list-taxes">
        <OrderViewPriceDetailsListItem
          title={t('componentOrder.taxes')}
          priceDetailsItems={[
            rent.taxAmount > 0 && {
              label: `${t('componentOrder.genericTaxes', {
                value: rent.taxationRate,
              })}`,
              amount: rent.taxAmount,
            },
            fees.cleaningFee.taxAmount > 0 && {
              label: t('componentOrder.cleaningFeeTaxes', {
                value: fees.cleaningFee.taxationRate,
              }),
              amount: fees.cleaningFee.taxAmount,
            },
            ...getTaxFees(fees?.otherFees),
            {
              label: t('componentOrder.totalTaxes'),
              amount: totalTaxes,
              isTotalAmount: true,
            },
          ]}
        />
      </PriceDetailsList>
    )
  );
};

export default Taxes;
