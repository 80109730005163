import { Lead } from 'models/Leads';
import LeadTooltip from 'components/domain/lead/LeadTooltip';
import { isCheckinVisible, isCheckoutVisible } from '../Calendar.utils';
import { LeadViewWrapper } from './LeadView.style';
import LeadViewInner from './LeadViewInner';

interface Props {
  lead: Lead;
  date: Date;
}

const LeadView = ({ lead, date }: Props) => {
  const { uid } = lead;
  return (
    <LeadViewWrapper
      data-lead-uid={uid}
      $date={date}
      $lead={lead}
      $isCheckinVisible={isCheckinVisible(date, lead)}
      $isCheckoutVisible={isCheckoutVisible(date, lead)}
    >
      <LeadTooltip lead={lead} />
      <LeadViewInner date={date} lead={lead} />
    </LeadViewWrapper>
  );
};

export default LeadView;
