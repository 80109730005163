import { useQueryClient } from '@tanstack/react-query';
import useAppMutation from 'hooks/useAppMutation';
import useNotify from 'hooks/useNotify';
import { useTranslation } from 'react-i18next';
import API from 'services/API';
import { PROPERTIES_FOR_PROPERTIES_BODY_BASE_QUERY_KEY } from '../body/useFetchPropertiesForPropertiesBody';

const useDuplicateAction = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { notifySuccess, notifyError } = useNotify();

  const { mutateAsync, isLoading } = useAppMutation(
    ['duplicateProperty'],
    async ({ uid }: { uid: string; name: string }) => {
      const response = await API.post(
        `/api/internal/properties/${uid}/duplicate`,
      );
      return response.data;
    },
    {
      onSuccess: (_, { name }) => {
        notifySuccess(
          t('pageProperties.actions.duplicationSuccess', {
            name: name.length > 30 ? `${name.slice(0, 30)}...` : name,
          }),
        );
        queryClient.invalidateQueries(
          [PROPERTIES_FOR_PROPERTIES_BODY_BASE_QUERY_KEY],
          { exact: false },
        );
      },
      onError: (error: any) => {
        notifyError(
          error.response.data.apiErrorMessage ||
            t('pageProperties.actions.duplicationError'),
        );
      },
    },
  );

  const handleDuplicate = ({ uid, name }: { uid: string; name: string }) => {
    if (!isLoading) {
      const allMenuItems = document.querySelectorAll<HTMLLIElement>(
        `#property-actions-container-${uid} li[role="presentation"]`,
      );
      allMenuItems.forEach((item) => {
        item.classList.add('disabled');
        item.style.pointerEvents = 'none';
      });
      mutateAsync({ uid, name }).finally(() => {
        document.body.click();
        allMenuItems.forEach((item) => {
          item.classList.remove('disabled');
          item.style.pointerEvents = 'auto';
        });
      });
    }
  };

  return { handleDuplicate, isDuplicating: isLoading };
};

export default useDuplicateAction;
