import { ModalFooter } from 'react-bootstrap';
import styled from 'styled-components';
import theme from 'styles/theme';

export const PropertyPermissionsEmployee = styled.div`
  align-items: center;
  display: flex;
  padding-left: 16px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  & .checkbox label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4px;
  }

  .employee-info {
    margin-left: 4px;

    .employee-name,
    .employee-role {
      margin: 0;
    }

    .employee-name {
      font-size: 16px;
      line-height: 24px;
    }

    .employee-role {
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

export const PropertyPermissionsModalContent = styled.div`
  .employees-list {
    h2 {
      font-size: 16px;
      line-height: 24px;
      padding: 0;
      margin: 0;
      margin-bottom: 16px;
    }

    padding: 16px;
  }

  hr {
    margin: 0;
  }

  .auto-assign-field {
    padding: 0 24px;
    margin: 24px 0;
    display: flex;
    align-items: center;

    .form-group {
      flex: 1;
      margin-bottom: 0;
    }
  }
`;

export const PropertyPermissionsModalFooter = styled(ModalFooter)`
  display: flex;
  justify-content: flex-end;

  & button:first-child {
    margin-right: auto;
  }

  & button.bulk-save {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

export const PropertyNameTitle = styled.span`
  color: ${theme.colors.hostfullyGreen};
`;

export const PropertyEmployeeItemPlaceholderStyled = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;
`;
