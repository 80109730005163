import { compare } from './sortUtils';

export function areArraysEqual(array1, array2): boolean {
  return (
    array1.every((val, idx) => val === array2[idx]) &&
    array2.every((val, idx) => val === array1[idx])
  );
}

export function sumAll(array: (number | undefined)[]): number {
  return array.reduce((result, current) => result + (current ?? 0), 0);
}

interface SortCriteria<T> {
  field: keyof T | ((item: T) => unknown);
  order: 'asc' | 'desc';
}

export function orderBy<T>(array: T[], criteria: SortCriteria<T>[]): T[] {
  return array.slice().sort((a, b) => {
    let result = 0;

    // eslint-disable-next-line no-restricted-syntax
    for (const { field, order } of criteria) {
      const valueA = typeof field === 'function' ? field(a) : a[field];
      const valueB = typeof field === 'function' ? field(b) : b[field];

      result = compare(valueA, valueB);

      if (result !== 0) {
        result = order === 'desc' ? result * -1 : result;
        break;
      }
    }

    return result;
  });
}

export function differenceWith<T1, T2>(
  array1: T1[],
  array2: T2[],
  comparator: (a: T1, b: T2) => boolean,
): T1[] {
  return array1.filter((a) => !array2.find((b) => comparator(a, b)));
}
