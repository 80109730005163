import { useEffect } from 'react';
import { Radio } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import GenericFormField from 'components/forms/genericFormField/GenericFormField';
import { FieldRendererProps } from 'components/forms/genericFormField/GenericFormField.types';
import { PropertyMultiSelectFieldProps } from '../PropertyMultiSelectField/PropertyMultiSelectField.types';
import { PROPERTIES_SELECTION } from './PropertyMultiSelectFilterField.constants';
import PropertyMultiSelectWithFilters from './PropertyMultiSelectWithFilters';
import { PropertyMultiSelectFilterFieldStyled } from './PropertyMultiSelectFilterField.styles';

export interface PropertyMultiSelectFilterFieldProps
  extends PropertyMultiSelectFieldProps {}

const PropertyMultiSelectFilterField = ({
  name,
  filterPropertyBy,
  graphQlFilters,
  ...props
}: PropertyMultiSelectFilterFieldProps) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const radioButtonValue = useWatch({ name });

  useEffect(() => {
    if (radioButtonValue === undefined) {
      setValue(name, PROPERTIES_SELECTION.ALL_PROPERTIES);
    }
  }, [radioButtonValue]);

  const fieldRenderer = ({
    field: { ref, ...fieldProps },
  }: FieldRendererProps) => (
    <PropertyMultiSelectFilterFieldStyled>
      <Radio
        {...fieldProps}
        id={`${name}-allProperties`}
        value={PROPERTIES_SELECTION.ALL_PROPERTIES}
        inputRef={ref}
        defaultChecked
      >
        {t('form.propertyMultiSelectFilterField.allProperties')}
      </Radio>

      <Radio
        {...fieldProps}
        id={`${name}-selectedProperties`}
        value={PROPERTIES_SELECTION.SELECTED_PROPERTIES}
        inputRef={ref}
      >
        {t('form.propertyMultiSelectFilterField.selectedProperties')}
      </Radio>

      {radioButtonValue === PROPERTIES_SELECTION.SELECTED_PROPERTIES && (
        <PropertyMultiSelectWithFilters
          name={name}
          graphQlFilters={graphQlFilters}
          filterPropertyBy={filterPropertyBy}
        />
      )}
    </PropertyMultiSelectFilterFieldStyled>
  );

  return (
    <GenericFormField
      colSmInput={12}
      name={name}
      fieldRenderer={fieldRenderer}
      {...props}
    />
  );
};

export default PropertyMultiSelectFilterField;
