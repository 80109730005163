function internalServerError(error: any) {
  return error?.response?.status >= 500;
}

function hasErrorCode(error: any) {
  return error?.response?.data?.apiErrorCode;
}

function hasErrorMessage(error: any) {
  return error?.response?.data?.apiErrorMessage;
}

export function extractErrorMessage(error: any): string {
  if (internalServerError(error)) {
    return 'common.apiResponses.internalServerError';
  }
  if (hasErrorCode(error)) {
    return `common.apiErrorCodes.${error?.response?.data?.apiErrorCode}`;
  }
  if (hasErrorMessage(error)) {
    return error?.response?.data?.apiErrorMessage;
  }
  return 'common.apiResponses.invalidRequest';
}
