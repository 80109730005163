import { createGlobalStyle } from 'styled-components';
import SourceSansProLight from 'assets/fonts/SourceSansPro-Light.ttf';
import SourceSansProRegular from 'assets/fonts/SourceSansPro-Regular.ttf';
import theme from './theme';

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: SourceSansPro_light;
    src: url(${SourceSansProLight});
  }
  @font-face {
    font-family: SourceSansPro_regular;
    src: url(${SourceSansProRegular});
  } 

  html {
    font-size: 14px;
    font-family: 'Source Sans Pro', sans-serif;
  }

  body {
    font-family: 'Source Sans Pro', sans-serif;
    color: ${theme.colors.fontDark} !important;
  }
`;

export default GlobalStyles;
