import { useEffect } from 'react';
import useAppModal from 'hooks/useAppModal';
import { Property } from 'models/Properties';
import { AppEventType } from '../../../models/Events';
import useAppEvent from '../../../hooks/useAppEvent';
import BlockModal from './BlockModal';

interface CreateBlockModal {
  propertyUid: string;
  dateFrom: Date;
  dateTo: Date;
}

interface UpdateBlockModal {
  blockUid: string;
}

const useOpenBlockModal = ({
  allProperties,
  listenerCreationNotAllowed,
}: {
  allProperties?: Property[];
  listenerCreationNotAllowed?: boolean;
}) => {
  const { openModal } = useAppModal();
  const { subscribe, unsubscribe } = useAppEvent();

  const createBlockModal = ({
    propertyUid,
    dateFrom,
    dateTo,
  }: CreateBlockModal) => {
    const modalId = 'calendar-block-create-modal';
    openModal({
      customContent: (
        <BlockModal
          modalId={modalId}
          propertyUid={propertyUid}
          allProperties={allProperties}
          dateFrom={dateFrom}
          dateTo={dateTo}
        />
      ),
      id: modalId,
    });
  };

  const updateBlockModal = ({ blockUid }: UpdateBlockModal) => {
    const modalId = 'calendar-block-update-modal';

    openModal({
      customContent: (
        <BlockModal
          modalId={modalId}
          blockUid={blockUid}
          allProperties={allProperties}
        />
      ),
      id: modalId,
    });
  };

  // TODO delete once modals merged
  useEffect(() => {
    if (listenerCreationNotAllowed) {
      return () => {};
    }

    const openModalListener = ({
      detail: payload,
    }: CustomEvent<CreateBlockModal>) => {
      createBlockModal(payload);
    };

    subscribe(AppEventType.OPEN_CREATE_BLOCK_MODAL, openModalListener);

    return () => {
      unsubscribe(AppEventType.OPEN_CREATE_BLOCK_MODAL, openModalListener);
    };
  }, [createBlockModal]);

  return {
    createBlockModal,
    updateBlockModal,
  };
};

export default useOpenBlockModal;
