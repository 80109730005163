import { Channel } from 'models/Channels';

export const channelsToShow = [
  Channel.airbnb,
  Channel.orbirental,
  Channel.booking_com,
  Channel.homeaway,
  Channel.google,
  Channel.hvmi,
  Channel.tripadvisor,
  Channel.hometogo,
  Channel.golightly,
  Channel.vacaymyway,
  Channel.homads,
];

export const editExternalLinkModalId =
  'property-channel-edit-external-link-modal';
