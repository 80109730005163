import { useContext } from 'react';
import { Button, Col } from 'react-bootstrap';
import { Loader } from 'react-bootstrap-typeahead';
import { canEditQuote, canForceFullPayment } from 'utils/lead/leadUtils';
import CheckboxField from 'components/forms/checkboxField/CheckboxField';
import { useTranslation } from 'react-i18next';
import { LeadSource, LeadStatus } from 'models/Leads';
import { useFormContext, useWatch } from 'react-hook-form';
import { QuoteFee, QuoteFeeOverride } from 'models/Quote';
import { FeeAmountType } from 'models/PropertyFees';
import { SOURCES_ORDER_NOT_EDITABLE } from 'utils/lead/leadUtils.constant';
import useFeatures from 'hooks/useFeatures';
import LeadModalContext from '../../../LeadModalContext';
import useQuoteData from '../../../useQuoteData';
import Rent from './fields/Rent';
import Cleaning from './fields/Cleaning';
import Fees from './fields/Fees';
import Services from './fields/Services';
import SecurityDeposit from './fields/SecurityDeposit';
import Total from './fields/Total';
import { TableQuote } from './LeadModalDetailsOrder.styles';
import OrderPropertyFees from './OrderPropertyFees';
import AdjustmentItems from './fields/AdjustmentItems';

const LeadModalDetailsOrder = () => {
  const { quote, lead, setQuote } = useContext(LeadModalContext);
  const { t } = useTranslation();
  const { isLoading: isQuoteLoading } = useQuoteData();
  const { setValue, getValues } = useFormContext();
  const { uid: leadUid } = useWatch();
  const { allowOrderViewWebapp } = useFeatures();

  if (!quote) return <Loader />;

  const updateMode = !!leadUid;

  const isEditable =
    !updateMode ||
    (lead && !SOURCES_ORDER_NOT_EDITABLE[lead.source] && canEditQuote(lead));
  const isAPayout = lead && SOURCES_ORDER_NOT_EDITABLE[lead.source];

  const isNewAirbnbLead =
    lead?.source === LeadSource.DIRECT_AIRBNB &&
    lead?.status === LeadStatus.NEW;

  const addNewLineItem = () => {
    const otherFees = getValues('overrides.fees.otherFees');

    setQuote({
      ...quote,
      fees: {
        ...quote.fees,
        otherFees: [
          ...quote.fees.otherFees,
          {
            name: `New item ${otherFees.length}`,
            amountType: 'AMOUNT',
            isEditable: true,
            netPrice: 0,
            taxationRate: 0,
            taxAmount: 0,
            grossPrice: 0,
          } as QuoteFee,
        ],
      },
    });

    otherFees.push({
      name: `New item ${otherFees.length}`,
      amountType: FeeAmountType.AMOUNT,
      netPrice: 0,
      taxationRate: 0,
      enabled: true,
      removed: false,
      amount: 0,
      feeId: null,
      feeUid: null,
    } as QuoteFeeOverride);

    setValue('overrides.fees.otherFees', otherFees);
  };

  return (
    <div data-testid="lead-order">
      {updateMode && lead.order?.uid && (
        <Col xsOffset={7} xs={5}>
          <div className="text-right" data-testid="view-order-link">
            <a
              type="button"
              className="btn btn-link"
              target="_blank"
              href={
                allowOrderViewWebapp
                  ? `#/order/${lead.order.uid}`
                  : `#/order-view?o=${lead.order.uid}`
              }
              rel="noreferrer"
            >
              {t('componentOrder.viewOrder')}
            </a>
          </div>
        </Col>
      )}

      <div>
        {updateMode && canForceFullPayment(lead) && (
          <Col xs={7} data-testid="force-payment">
            <CheckboxField name="force100PercentPaymentAtReservation">
              {t('componentOrder.forcePaymentAtReservation')}
            </CheckboxField>
          </Col>
        )}

        <Col
          xsOffset={!updateMode || (lead && !canForceFullPayment(lead)) ? 7 : 0}
          xs={5}
        >
          {isEditable && !isAPayout && (
            <div className="text-right">
              <Button
                onClick={addNewLineItem}
                data-testid="add-new-item-button"
                className="btn btn-link"
              >
                <span
                  style={{ top: 0, fontSize: 'x-small' }}
                  className="glyphicon glyphicon-plus"
                />{' '}
                {t('componentOrder.addLineItem')}
              </Button>
            </div>
          )}
        </Col>
      </div>

      <br />

      <Col xs={12}>
        <TableQuote className="table" $isQuoteLoading={isQuoteLoading}>
          <tbody>
            {quote.rent && (
              <Rent isEditable={isEditable} isAPayout={isAPayout} />
            )}

            {!isAPayout && quote.fees?.cleaningFee && !isNewAirbnbLead && (
              <Cleaning isEditable={isEditable} />
            )}

            {!isAPayout && !!quote.fees?.otherFees.length && (
              <Fees isEditable={isEditable} />
            )}

            {!isAPayout && !!quote.services?.length && <Services />}

            {!isAPayout &&
              !!quote.securityDeposit &&
              quote.includeSecurityDepositInTotal && (
                <SecurityDeposit isEditable={isEditable} />
              )}

            {!isAPayout && !!quote.adjustmentItems?.length && (
              <AdjustmentItems />
            )}

            {!!quote.totalPrice && <Total isAPayout={isAPayout} />}

            {!isAPayout &&
              !!quote.securityDeposit &&
              !quote.includeSecurityDepositInTotal && (
                <SecurityDeposit isEditable={isEditable} />
              )}
          </tbody>
        </TableQuote>
        {!isAPayout &&
          (!updateMode ||
            lead.source === LeadSource.ORBIRENTAL_FORM ||
            lead.source === LeadSource.ORBIRENTAL_WIDGET ||
            lead.source === LeadSource.ORBIRENTAL_API) && (
            <OrderPropertyFees isEditable={isEditable} />
          )}

        {quote?.adjustments.find((a) => !a.isPaid) && (
          <Col xs={12}>
            <div className="form-group">
              <div className="alert alert-warning" role="alert">
                <strong>Warning!</strong> Your order has changed
              </div>
            </div>
          </Col>
        )}
      </Col>
    </div>
  );
};

export default LeadModalDetailsOrder;
