import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';
import useAppMutation from '../../../../hooks/useAppMutation';
import API from '../../../../services/API';
import useAppUser from '../../../../hooks/useAppUser';
import InboxMessagesContext from '../InboxMessagesContext';
import {
  MessageAttachment,
  MessageAttachmentType,
  MessageCreatorType,
  MessageSenderType,
  MessageToSend,
  MessagingChannel,
  SendMessageResponseTO,
} from '../../../../models/inbox/Messages';
import InboxContext from '../../InboxContext';
import { normalizeKey } from '../../../../utils/localeUtils';
import { MessagingChannelPriorities } from './InboxMessageEditor.constants';

interface SendMessagePayload {
  message: MessageToSend;
  cleanUpEditor: () => void;
}

enum MessageValidationError {
  AIRBNB_ATTACHMENT_AND_CONTENT_PRESENT = 'AIRBNB_ATTACHMENT_AND_CONTENT_PRESENT',
  AIRBNB_MORE_THAN_ONE_ATTACHMENT = 'AIRBNB_MORE_THAN_ONE_ATTACHMENT',
  AIRBNB_UNSUPPORTED_ATTACHMENT_FORMAT = 'AIRBNB_UNSUPPORTED_ATTACHMENT_FORMAT',
  EMPTY = 'EMPTY',
}

const supportedAirbnbAttachmentFormats = [
  MessageAttachmentType.IMAGE_GIF,
  MessageAttachmentType.IMAGE_JPEG,
  MessageAttachmentType.IMAGE_PNG,
];

function validateMessageContent({
  attachments,
  channel,
  messageHtmlContent,
}: {
  attachments?: MessageAttachment[];
  channel: MessagingChannel;
  messageHtmlContent: HTMLElement;
}) {
  if (
    !messageHtmlContent.innerText.trim() &&
    (!attachments?.length ||
      channel === MessagingChannel.SMS ||
      channel === MessagingChannel.WHATSAPP)
  ) {
    return MessageValidationError.EMPTY;
  }

  if (channel === MessagingChannel.AIRBNB) {
    if (messageHtmlContent.innerText.trim() && attachments?.length) {
      return MessageValidationError.AIRBNB_ATTACHMENT_AND_CONTENT_PRESENT;
    }

    if (attachments?.length > 1) {
      return MessageValidationError.AIRBNB_MORE_THAN_ONE_ATTACHMENT;
    }

    const unsupportedAttachment = attachments?.find(
      ({ type }) => !supportedAirbnbAttachmentFormats.includes(type),
    );

    if (unsupportedAttachment) {
      return MessageValidationError.AIRBNB_UNSUPPORTED_ATTACHMENT_FORMAT;
    }
  }

  return null;
}

export function getMessageChannelForSending(
  messageChannel: MessagingChannel,
  availableMessagingChannels: MessagingChannel[],
) {
  if (messageChannel === MessagingChannel.AUTO) {
    return availableMessagingChannels.reduce((channel1, channel2) => {
      const firstHasPriorityOverSecond =
        MessagingChannelPriorities[channel1] >
        MessagingChannelPriorities[channel2];
      return firstHasPriorityOverSecond ? channel1 : channel2;
    }, availableMessagingChannels[0]);
  }

  return messageChannel;
}

function getMessageSenderDetails(appUser) {
  const { agencyUid, isEmployee, isGuest, isOwner, userUid } = appUser;

  if (isEmployee) {
    return {
      senderType: MessageSenderType.AGENCY,
      senderUid: agencyUid,
      creatorType: MessageCreatorType.PM,
      creatorUid: userUid,
    };
  }

  if (isGuest) {
    return {
      senderType: MessageSenderType.GUEST,
      senderUid: userUid,
      creatorType: MessageCreatorType.GUEST,
      creatorUid: userUid,
    };
  }

  if (isOwner) {
    return {
      senderType: MessageSenderType.OWNER,
      senderUid: userUid,
      creatorType: MessageCreatorType.OWNER,
      creatorUid: userUid,
    };
  }

  throw Error(`Not implemented user type: ${appUser.userType}`);
}

const useSendInboxMessage = () => {
  const appUser = useAppUser();
  const { t } = useTranslation();
  const { onMessageSent } = useContext(InboxMessagesContext);
  const { activeThread, threadAvailableMessagingChannelsMap } =
    useContext(InboxContext);
  const [errorMessage, setErrorMessage] = useState<string>();
  const { uid: threadUid } = activeThread || {};

  const { mutate: sendMessageHandler, isLoading: isSending } = useAppMutation(
    ['sendMessageHandler'],
    ({ message }: SendMessagePayload) => API.post(`/v3/messages`, message),
    {
      onSuccess: (
        { data: { message } }: AxiosResponse<SendMessageResponseTO>,
        { cleanUpEditor }: SendMessagePayload,
      ) => {
        onMessageSent(message);
        cleanUpEditor();
      },
      onError: () => {
        setErrorMessage(t('pageInbox.messages.editor.error_SERVER_ERROR'));
      },
    },
  );

  const sendMessage = useCallback(
    (
      messageChannel: MessagingChannel,
      messageHtmlContent: HTMLElement,
      cleanUpEditor: () => void,
      attachments?: MessageAttachment[],
    ) => {
      const channel = getMessageChannelForSending(
        messageChannel,
        threadAvailableMessagingChannelsMap[threadUid],
      );

      const validationError = validateMessageContent({
        attachments,
        channel,
        messageHtmlContent,
      });
      if (validationError) {
        setErrorMessage(
          t(normalizeKey(`pageInbox.messages.editor.error_${validationError}`)),
        );
        return;
      }

      const { creatorType, creatorUid, senderType, senderUid } =
        getMessageSenderDetails(appUser);

      const message = {
        attachments,
        content: {
          text: messageHtmlContent.innerHTML,
        },
        channel,
        creator: {
          creatorType,
          creatorUid,
        },
        senderDetails: {
          senderType,
          senderUid,
        },
        threadUid,
      };

      setErrorMessage(null);
      sendMessageHandler({ message, cleanUpEditor });
    },
    [appUser, threadUid, threadAvailableMessagingChannelsMap],
  );

  return { isSending, errorMessage, sendMessage };
};

export default useSendInboxMessage;
