import API from '../../../../services/API';
import { trimAndLimitCharactersNumber } from '../../../../utils/stringUtils';
import {
  LeadSearchFieldProps,
  LeadSearchFoundOption,
} from './LeadSearchField.types';
import {
  LeadPropertyPicture,
  LeadSearchDetails,
  LeadSearchFieldAsyncTypeahead,
  LeadSearchOption,
} from './LeadSearchField.styles';

const LeadSearchField: React.FC<LeadSearchFieldProps> = ({
  align,
  name,
  ...props
}) => {
  const searchHandler = async (query) => {
    return API.get(
      `/search_leads_json.jsp?q=${encodeURIComponent(query)}`,
    ).then(({ data: leads }) => leads);
  };

  return (
    <LeadSearchFieldAsyncTypeahead
      align={align}
      name={name}
      labelKey={(option) => `${option.bestProfile}`}
      searchHandler={searchHandler}
      renderMenuItemChildren={(option: LeadSearchFoundOption) => (
        <LeadSearchOption data-testid={`found-lead-${option.uid}`}>
          <LeadPropertyPicture alt="" src={option.Property?.picture} />
          <LeadSearchDetails>
            <div>{option.bestProfile}</div>
            <div className="text-muted">
              {trimAndLimitCharactersNumber({ text: option.email, limit: 25 })}
            </div>
            <div className="text-muted">
              {option.checkinDate} - {option.checkoutDate}
            </div>
          </LeadSearchDetails>
        </LeadSearchOption>
      )}
      {...props}
    />
  );
};

export default LeadSearchField;
