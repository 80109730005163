import { Glyphicon } from 'react-bootstrap';
import Button from 'components/button/Button';
import {
  UploadFieldFileListItemStyled,
  UploadFieldFileListStyled,
} from './UploadFieldFileList.styles';

interface UploadFieldFileListProps<T extends File | unknown> {
  className?: string;
  files: T[];
  isDisabled: boolean;
  getName: (arg: T) => string;
  getUrl: (arg: T) => string;
  onDelete: (arg: T) => void;
}

const UploadFieldFileList = <T,>({
  files,
  className,
  isDisabled,
  getName,
  getUrl,
  onDelete,
}: UploadFieldFileListProps<T>) => {
  return (
    <UploadFieldFileListStyled data-testid="file-list" className={className}>
      {files.map((file, index) => (
        <UploadFieldFileListItemStyled
          // eslint-disable-next-line react/no-array-index-key
          key={`${index}-${getName(file)}`}
          $isDisabled={isDisabled}
          data-testid="file-list-item"
        >
          {isDisabled && <span>{getName(file)}</span>}
          {!isDisabled && (
            <a href={getUrl(file)} target="_blank" rel="noreferrer">
              {getName(file)}
            </a>
          )}

          <Button
            data-testid="delete-file-button"
            bsStyle="link"
            onClick={() => onDelete(file)}
            disabled={isDisabled}
          >
            <Glyphicon glyph="trash" className="small" />
          </Button>
        </UploadFieldFileListItemStyled>
      ))}
    </UploadFieldFileListStyled>
  );
};

UploadFieldFileList.defaultProps = {
  className: '',
};

export default UploadFieldFileList;
