import { LoadingPlaceholderStyled } from 'components/loader/LoadingPlaceholder.styles';
import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const AnalyticsListWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  ${mediaQuery.xs} {
    flex-wrap: wrap;
  }
`;

export const AnalyticsListItemPlaceHolder = styled(LoadingPlaceholderStyled)`
  border-radius: 15px;
  height: 100%;
`;

export const AnalyticsListItemWrapper = styled.div<{
  backgroundColor: string;
  isLoading?: boolean;
}>`
  flex: 0 1 19%;
  height: 125px;
  border: 1px solid #ced4da;
  border-radius: 15px;
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  padding: 8px 0px;
  color: #737373;

  ${({ backgroundColor }) =>
    backgroundColor &&
    `
    background-color: ${backgroundColor};
  `}

  ${({ isLoading }) =>
    isLoading &&
    `
    padding: 0px;
  `}

  
  ${mediaQuery.xs} {
    flex: 0 1 45%;
    margin-bottom: 25px;
    height: 115px;

    &:nth-child(5) {
      margin: auto;
    }
  }
`;

export const AnalyticsListItemHeader = styled.span`
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: 400;

  span {
    margin-left: 5px;
  }

  ${mediaQuery.xs} {
    font-size: 16px;
  }
`;

export const AnalyticsListItemValue = styled.span`
  font-size: 35px;
  font-weight: 400;

  ${mediaQuery.xs} {
    font-size: 24px;
  }
`;

export const AnalyticsListItemPercentage = styled.span`
  font-size: 16px;
  font-weight: 400;
  height: 25px;

  ${mediaQuery.xs} {
    font-size: 16px;
  }
`;

export const AnalyticsListItemPositivePercentage = styled.span`
  color: #31b48d;
  position: relative;
  font-size: 20px;

  .glyphicon {
    font-size: 14px;
    margin-right: 5px;
  }
`;

export const AnalyticsListItemNegativePercentage = styled(
  AnalyticsListItemPositivePercentage,
)`
  color: #ff7254;
`;
