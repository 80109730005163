import { useState } from 'react';
import useFormatCurrency from 'hooks/useFormatCurrency';
import ToggleButton from 'components/button/ToggleButton';
import InfoIcon from 'components/icons/InfoIcon';
import useOrderViewFetchedData from '../useOrderViewFetchedData';
import {
  OrderViewPriceDetailsButtonWrapper,
  OrderViewPriceDetailsListItemRowWrapper,
} from './OrderViewPriceDetailsListItemRow.styles';
import EditOrderAdjustmentButton from './actions/EditOrderAdjustmentButton';
import DeleteOrderAdjustmentButton from './actions/DeleteOrderAdjustmentButton';

export enum AmountType {
  AMOUNT = 'AMOUNT',
  PERCENTAGE = 'PERCENTAGE',
}

export interface PriceDetailsItem {
  label: string;
  tooltip?: string;
  amount: number;
  amountType?: AmountType;
  isTotalAmount?: boolean;
  breakdowns?: Pick<PriceDetailsItem, 'label' | 'amount'>[];
  onEdit?: () => void;
  onDelete?: () => void;
}

const OrderViewPriceDetailsListItemRow = ({
  label,
  tooltip,
  amount,
  amountType,
  isTotalAmount,
  breakdowns,
  onEdit,
  onDelete,
  ...props
}: PriceDetailsItem) => {
  const formatCurrency = useFormatCurrency();
  const { lead } = useOrderViewFetchedData();
  const { currency } = lead.order;
  const [showBreakdown, setShowBreakdown] = useState(false);

  return (
    <div {...props}>
      <OrderViewPriceDetailsListItemRowWrapper
        $hasBorder={!isTotalAmount}
        $isCapitalized={isTotalAmount}
        $isBold={isTotalAmount}
      >
        <div data-testid="price-details-list-item-title">
          <span>{label}</span>
          {tooltip && (
            <InfoIcon
              id="property-channel-widget-info"
              tooltipPlacement="bottom"
              tooltipText={tooltip}
            />
          )}
          <span>
            {breakdowns.length > 0 && (
              <ToggleButton
                data-testid="price-details-list-item-toggle-button"
                isExpanded={showBreakdown}
                toggleVertically
                onClick={() => setShowBreakdown(!showBreakdown)}
              />
            )}
          </span>
          <OrderViewPriceDetailsButtonWrapper>
            {onEdit && <EditOrderAdjustmentButton onEdit={onEdit} />}
            {onDelete && <DeleteOrderAdjustmentButton onDelete={onDelete} />}
          </OrderViewPriceDetailsButtonWrapper>
        </div>
        <div
          className="text-right"
          data-testid="price-details-list-item-amount"
        >
          {amountType === AmountType.AMOUNT
            ? formatCurrency({ value: amount, currency })
            : `${amount}%`}
        </div>
      </OrderViewPriceDetailsListItemRowWrapper>
      {showBreakdown &&
        breakdowns.map((breakdown) => (
          <OrderViewPriceDetailsListItemRowWrapper
            key={breakdown.label}
            $isChildren
            $hasBorder
            $isBold={false}
          >
            <div>{breakdown.label} </div>
            <div className="text-right">
              {formatCurrency({
                value: breakdown.amount,
                currency,
              })}
            </div>
          </OrderViewPriceDetailsListItemRowWrapper>
        ))}
    </div>
  );
};

OrderViewPriceDetailsListItemRow.defaultProps = {
  isTotalAmount: false,
  breakdowns: [],
  amountType: AmountType.AMOUNT,
  tooltip: null,
  onEdit: null,
  onDelete: null,
};

export default OrderViewPriceDetailsListItemRow;
