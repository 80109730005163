import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useFormContext, useWatch } from 'react-hook-form';
import FilterIcon from 'components/filters/FilterIcon';
import usePrevious from 'hooks/usePrevious';
import { isDeepEqual } from 'utils/objectUtils';
import PropertyMultiSelectField from '../PropertyMultiSelectField/PropertyMultiSelectField';
import StatusSelectField from '../StatusSelectField';
import TagsMultiSelectField from '../TagsMultiSelectField';
import { PropertyMultiSelectFieldProps } from '../PropertyMultiSelectField/PropertyMultiSelectField.types';
import { FilterContainerStyled } from './PropertyMultiSelectFilterField.styles';

interface PropertyMultiSelectWithFiltersProps {
  name: string;
  graphQlFilters?: PropertyMultiSelectFieldProps['graphQlFilters'];
  filterPropertyBy?: PropertyMultiSelectFieldProps['filterPropertyBy'];
}

const PropertyMultiSelectWithFilters = ({
  name,
  graphQlFilters,
  filterPropertyBy,
}: PropertyMultiSelectWithFiltersProps) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { setValue } = useFormContext();
  const statusFieldName = `${name}Status`;
  const tagsFieldName = `${name}Tags`;
  const propertiesFieldName = `${name}Properties`;
  const status = useWatch({ name: statusFieldName });
  const tags = useWatch({ name: tagsFieldName }) ?? [];
  const previousStatus = usePrevious(status);
  const previousTags = usePrevious(tags);
  const handleFilterClick = () => setIsCollapsed((prev) => !prev);

  // Clear selected properties when changing filters, so that it doesn't keep
  // properties that no longer applies to the current filters
  if (
    (status !== undefined && previousStatus !== status) ||
    (!isDeepEqual(tags, []) && !isDeepEqual(previousTags, tags))
  ) {
    setValue(propertiesFieldName, []);
  }

  return (
    <>
      <FilterIcon
        isFilterOpen={!isCollapsed}
        filterCounter={undefined}
        onClick={handleFilterClick}
      />

      <FilterContainerStyled isCollapsed={isCollapsed}>
        <Row>
          <Col xs={6}>
            <StatusSelectField
              name={statusFieldName}
              colSmLabel={3}
              colSmInput={9}
            />
          </Col>
          <Col xs={6}>
            <TagsMultiSelectField
              name={tagsFieldName}
              colSmLabel={3}
              colSmInput={9}
            />
          </Col>
        </Row>
      </FilterContainerStyled>

      <PropertyMultiSelectField
        name={propertiesFieldName}
        colSmInput={12}
        graphQlFilters={{ tags, status, ...graphQlFilters }}
        filterPropertyBy={filterPropertyBy}
        isSelectAllEnabled
      />
    </>
  );
};

PropertyMultiSelectWithFilters.defaultProps = {
  graphQlFilters: undefined,
  filterPropertyBy: undefined,
};

export default PropertyMultiSelectWithFilters;
