import { useContext, useMemo } from 'react';
import { format, isWeekend } from 'date-fns';
import { Job } from 'models/Jobs';
import CalendarContext from '../../CalendarContext';
import { CalendarLead, DayCellData } from '../../Calendar.types';
import useStackedCalendarPropertyDayData from '../../useStackedCalendarPropertyDayData';

function getLoadingCellData(dayDate: Date) {
  return {
    dayDate,
    isLoading: true,
    isWeekend: isWeekend(dayDate),
    leads: null,
    jobs: null,
  };
}

const useDayCellData = ({
  dayDate,
  propertyUid,
}): { dayData: DayCellData; leads?: CalendarLead[]; jobs?: Job[] } => {
  const { leadsMap, jobsMap } = useContext(CalendarContext);
  const daysMapKey = format(dayDate, 'yyyy-MM-dd');
  const existingDaysData = useStackedCalendarPropertyDayData({
    daysMapKey,
    propertyUid,
  });
  const dayData = existingDaysData || getLoadingCellData(dayDate);

  return useMemo(() => {
    const leadsUid = dayData?.leads;
    const jobsUid = dayData?.jobs;
    const leads = leadsUid?.map((leadUid: string) => leadsMap[leadUid]);
    const jobs = jobsUid?.map((jobUid: string) => jobsMap[jobUid]);

    return {
      leads,
      dayData,
      jobs,
    };
  }, [dayData]);
};

export default useDayCellData;
