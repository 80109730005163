import { Badge } from 'react-bootstrap';
import styled from 'styled-components';
import theme from 'styles/theme';

export const PriceBadgeStyled = styled(Badge)`
  align-items: center;
  background-color: ${theme.colors.hostfullyGreen};
  border-radius: 5px;
  display: inline-flex;
  font-size: inherit;
  height: 100%;
`;

export const UpgradePlanModalPricePerPropertyStyled = styled.section`
  padding: 8px;

  p {
    margin: 0;
  }

  p:last-child {
    font-size: 14px;
  }
`;

export const UpgradePlanModalStyled = styled.div`
  font-size: 16px;

  .content {
    color: #495057;

    p:first-child {
      font-size: 24px;
    }

    p:last-child {
      font-size: 16px;
    }
  }

  .terms {
    color: ${theme.colors.gray500};
    font-size: 14px;
    text-align: right;
  }

  form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  section {
    margin: 10px 0;
  }

  table {
    height: 1px;
    margin: 0;

    .column-description {
      width: 70%;
    }
    .column-price {
      width: 30%;
    }

    .body-cell {
      border-color: ${theme.colors.light};

      &:has(${PriceBadgeStyled}) {
        padding: 0;
      }
    }

    .body-cell-price {
      text-align: right;
    }

    caption {
      font-weight: 400;
    }

    tbody {
      background-color: #fff3cd80;
    }

    tfoot {
      font-size: 14px;
    }
  }
`;
