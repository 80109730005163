import { useTranslation } from 'react-i18next';
import { isBefore } from 'date-fns';
import { Transaction } from 'models/Transactions';
import { getTransactionCreatedDate } from 'utils/tests/transaction/transactionUtils';
import useOrderViewFetchedData from '../useOrderViewFetchedData';
import {
  TransactionList,
  TransactionListEmptyMessage,
  TransactionListWrapper,
} from './OrderViewTransactionsList.styles';
import OrderViewTransactionsListItem from './OrderViewTransactionsListItem';

function transactionsSorter(
  transaction1: Transaction,
  transaction2: Transaction,
) {
  const createdDate1 = getTransactionCreatedDate(transaction1);
  const createdDate2 = getTransactionCreatedDate(transaction2);
  return isBefore(createdDate1, createdDate2) ? 1 : -1;
}

const OrderViewTransactionsList = () => {
  const { lead } = useOrderViewFetchedData();
  const { t } = useTranslation();

  const transactions = lead?.order?.transactions || [];

  return (
    <TransactionListWrapper data-testid="transactions-list">
      {!transactions.length && (
        <TransactionListEmptyMessage data-testid="transactions-list-empty-message">
          {t('pageOrder.transactions.emptyMessage')}
        </TransactionListEmptyMessage>
      )}
      {!!transactions.length && (
        <TransactionList>
          {transactions.toSorted(transactionsSorter).map((transaction) => (
            <OrderViewTransactionsListItem
              key={transaction.uid}
              transaction={transaction}
            />
          ))}
        </TransactionList>
      )}
    </TransactionListWrapper>
  );
};

export default OrderViewTransactionsList;
