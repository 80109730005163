import { useContext } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useAppEvent from 'hooks/useAppEvent';
import useAppModal from 'hooks/useAppModal';
import { AppEventType } from 'models/Events';
import { LeadStatus } from 'models/Leads';
import { openInboxForLead } from 'utils/inbox/inboxUtils';
import { canSendMessage } from 'utils/lead/leadUtils';
import { normalizeKey } from 'utils/localeUtils';
import LeadChannelIcon from '../LeadChannelIcon';
import LeadCircle from '../status/LeadCircle';
import LeadModalContext from './LeadModalContext';
import {
  CreateJobButton,
  LeadModalChannelIconContainer,
  LeadModalHeaderContainer,
  LeadModalHeaderSection,
  LeadModalHeaderStyled,
  SendMessageButton,
  StatusButton,
  StatusButtonsContainer,
} from './LeadModalHeader.styles';
import LeadModalHeaderTitle from './LeadModalHeaderTitle';
import LeadModalHeaderTags from './LeadModalHeaderTags';
import { LeadModalParams } from './LeadModal.types';

const newLeadAvailableStatuses = [
  LeadStatus.NEW,
  LeadStatus.ON_HOLD,
  LeadStatus.BOOKED_BY_AGENT,
  LeadStatus.BLOCKED,
];

const LeadModalHeader = ({
  params: { jobCreationNotAllowed },
}: {
  params: LeadModalParams;
}) => {
  const { t } = useTranslation();
  const { publish } = useAppEvent();
  const { closeModal } = useAppModal();
  const { lead, modalId } = useContext(LeadModalContext);
  const { setValue } = useFormContext();

  const {
    checkInDate,
    checkOutDate,
    propertyUid,
    status: currentStatus,
    uid: leadUid,
  } = useWatch();
  const updateMode = !!leadUid;

  const sendMessageHandler = () => {
    openInboxForLead(leadUid);
  };
  const statusButtonClickHandler = (status: LeadStatus) => {
    // TODO delete once modals merged
    if (status === LeadStatus.BLOCKED) {
      closeModal(modalId);
      publish(AppEventType.OPEN_CREATE_BLOCK_MODAL, {
        dateFrom: checkInDate,
        dateTo: checkOutDate,
        propertyUid,
      });
    } else {
      setValue('status', status);
    }
  };

  const onCreateJobClick = () => {
    closeModal(modalId);
    publish(AppEventType.OPEN_CREATE_JOB_MODAL, {
      jobDate: checkInDate,
      propertyUid,
    });
  };

  return (
    <LeadModalHeaderStyled>
      <LeadModalHeaderContainer>
        <LeadModalHeaderSection>
          {updateMode && lead && (
            <>
              <LeadCircle lead={lead} />
              <LeadModalChannelIconContainer>
                <LeadChannelIcon channel={lead.channel?.channel} />
              </LeadModalChannelIconContainer>
            </>
          )}
          <LeadModalHeaderTitle />
          <LeadModalHeaderTags />
        </LeadModalHeaderSection>

        <LeadModalHeaderSection>
          {!jobCreationNotAllowed && (
            <CreateJobButton onClick={onCreateJobClick}>
              {t('componentLead.modal.createJob')}
            </CreateJobButton>
          )}
          {updateMode && lead && canSendMessage(lead) && (
            <SendMessageButton onClick={sendMessageHandler}>
              {t('componentLead.modal.sendMessage')}
            </SendMessageButton>
          )}
          {!updateMode && (
            <StatusButtonsContainer>
              {newLeadAvailableStatuses.map((status) => (
                <StatusButton
                  $fill={status === currentStatus}
                  $status={status}
                  key={status}
                  onClick={() => statusButtonClickHandler(status)}
                >
                  {t(
                    normalizeKey(`componentLead.modal.newLeadStatus.${status}`),
                  )}
                </StatusButton>
              ))}
            </StatusButtonsContainer>
          )}
        </LeadModalHeaderSection>
      </LeadModalHeaderContainer>
    </LeadModalHeaderStyled>
  );
};

export default LeadModalHeader;
