import styled from 'styled-components';

export const FieldDescriptionStyled = styled.span`
  font-weight: bold;
`;

export const BulkSaveTitleStyled = styled.span`
  font-weight: bold;
`;

export const BulkSavePropertiesModalStyled = styled.div`
  section {
    display: flex;
    flex-direction: column;
  }

  ul {
    padding-left: 30px;
  }

  li {
    list-style-type: circle;
    margin: 10px 0;
  }
`;

export const BulkSavePropertiesModalFooterStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
