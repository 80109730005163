export enum CustomDataFieldType {
  LONG_TEXT = 'LONG_TEXT',
  TEXT = 'TEXT',
}

export interface CustomDataField {
  agencyUid: string;
  uid: string;
  type: CustomDataFieldType;
  name: string;
  variable: string;
}
