import { BaseSyntheticEvent } from 'react';
import { UseFormSetError } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { queryClient } from 'App';
import { LEAD_GQL_QUERY_BASE } from 'components/domain/lead/useFetchLead';
import useCreateSecurityDepositCharge from 'components/domain/transaction/useCreateSecurityDepositCharge';
import FormWithProvider from 'components/forms/form/Form';
import useAppModal from 'hooks/useAppModal';
import { Lead } from 'models/Leads';
import { TransactionStatus, TransactionType } from 'models/Transactions';
import { normalizeKey } from 'utils/localeUtils';
import {
  AdditionalChargeFormValues,
  AdditionalChargeModalParams,
} from './AdditionalChargeModal.types';
import AdditionalChargeModalFormElements from './AdditionalChargeModalFormElements';

const additionalChargeFormSchema = (maxAmount: number, t: TFunction) => ({
  amount: Yup.number()
    .positive()
    .max(
      maxAmount,
      t(
        'pageOrder.transactions.actions.additionalCharge.error.maxAmountExceeded',
        { maxAmount },
      ),
    )
    .required(),
});

function getMaxAmount(lead: Lead) {
  const {
    order: { securityDeposit, transactions },
  } = lead;

  const alreadyChargedAmount =
    transactions
      ?.filter(
        ({ status, type }) =>
          status === TransactionStatus.SUCCESS &&
          type === TransactionType.SECURITY_DEPOSIT_CHARGE,
      )
      ?.reduce((acc, { amount }) => acc + amount, 0) || 0;

  return Math.max(securityDeposit - alreadyChargedAmount, 0);
}

const AdditionalChargeModal = ({
  modalId,
  params: { lead },
}: {
  modalId: string;
  params: AdditionalChargeModalParams;
}) => {
  const { closeModal } = useAppModal();
  const { createSecurityDepositCharge } = useCreateSecurityDepositCharge();
  const { t } = useTranslation();

  const {
    order: { uid: orderUid },
    uid: leadUid,
  } = lead;

  const onCancel = () => {
    closeModal(modalId);
  };

  const onSubmit = async (
    { amount }: AdditionalChargeFormValues,
    event: BaseSyntheticEvent | undefined,
    setError: UseFormSetError<AdditionalChargeFormValues>,
  ) => {
    await createSecurityDepositCharge({
      payload: {
        amount,
        orderUid,
      },
      successCallback: () => {
        queryClient.invalidateQueries([LEAD_GQL_QUERY_BASE, leadUid]);
        closeModal(modalId);
      },
      errorCallback: ({ response: { data } }) => {
        const errorCode = data?.apiErrorCode;

        setError('root', {
          message: t([
            normalizeKey(
              `pageOrder.transactions.actions.additionalCharge.error.api.${errorCode}`,
            ),
            'common.apiResponses.internalServerError',
          ]),
        });
        queryClient.invalidateQueries([LEAD_GQL_QUERY_BASE, leadUid]); // invalidate to display possible created failed transactions
      },
    });
  };

  const maxAmount = getMaxAmount(lead);

  const formDefaultValues = {
    amount: maxAmount,
  };

  return (
    <FormWithProvider
      defaultValues={formDefaultValues}
      noValidate
      onSubmit={onSubmit}
      schema={additionalChargeFormSchema(maxAmount, t)}
    >
      <AdditionalChargeModalFormElements lead={lead} onCancel={onCancel} />
    </FormWithProvider>
  );
};

export default AdditionalChargeModal;
