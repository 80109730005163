import { useTranslation } from 'react-i18next';
import useOrderViewFetchedData from 'pages/orderView/useOrderViewFetchedData';
import { Service } from 'models/order/Service';
import { PriceDetailsList } from '../OrderViewPriceDetails.styles';
import OrderViewPriceDetailsListItem from '../OrderViewPriceDetailsListItem';
import { PriceDetailsItem } from '../OrderViewPriceDetailsListItemRow';

const Services = () => {
  const { lead } = useOrderViewFetchedData();
  const { t } = useTranslation();

  const { services } = lead.order;

  const getServices = (otherServices: Service[]): PriceDetailsItem[] => {
    return otherServices.map((service: Service) => ({
      label: service.title,
      amount: service.grossPrice,
    }));
  };

  return (
    <PriceDetailsList data-testid="price-details-list-services">
      <OrderViewPriceDetailsListItem
        title={t('componentOrder.services')}
        priceDetailsItems={[
          ...getServices(services),
          {
            label: t('componentOrder.totalServices'),
            amount: services.reduce((acc, fee) => acc + fee.grossPrice, 0),
            isTotalAmount: true,
          },
        ]}
      />
    </PriceDetailsList>
  );
};

export default Services;
