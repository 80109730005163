import { useTranslation } from 'react-i18next';
import useOpenOrderAdjustmentModal from 'components/domain/order/useOpenOrderAdjustmentModal';
import { Lead } from 'models/Leads';
import Button from '../../../../components/button/Button';

const AddOrderAdjustmentButton = ({
  orderUid,
  lead,
}: {
  orderUid: string;
  lead: Lead;
}) => {
  const { t } = useTranslation();
  const { createOrderAdjustmentModal } = useOpenOrderAdjustmentModal();

  const addNewOrderAdjustment = () => {
    createOrderAdjustmentModal({
      orderUid,
      lead,
    });
  };

  return (
    <Button
      onClick={addNewOrderAdjustment}
      data-testid="add-new-order-adjustment-button"
      bsStyle="link"
    >
      {t('componentOrder.addOrderAdjustment')}
    </Button>
  );
};

export default AddOrderAdjustmentButton;
