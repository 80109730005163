import { Trans, useTranslation } from 'react-i18next';
import useOrderViewFetchedData from 'pages/orderView/useOrderViewFetchedData';
import { AdjustmentItem } from 'models/order/AdjustmentItem';
import useOpenOrderAdjustmentModal from 'components/domain/order/useOpenOrderAdjustmentModal';
import { queryClient } from 'App';
import useAppModal from 'hooks/useAppModal';
import { LEAD_GQL_QUERY_BASE } from 'components/domain/lead/useFetchLead';
import { TextHighlightGreen } from 'components/text/TextHighlightGreen';
import useFormatCurrency from 'hooks/useFormatCurrency';
import useDeleteOrderAdjustment from 'components/domain/orderAdjustment/useDeleteOrderAdjustment';
import { PriceDetailsList } from '../OrderViewPriceDetails.styles';
import OrderViewPriceDetailsListItem from '../OrderViewPriceDetailsListItem';
import { PriceDetailsItem } from '../OrderViewPriceDetailsListItemRow';

const AdjustmentItems = () => {
  const { openConfirmModal } = useAppModal();
  const { lead, orderUid } = useOrderViewFetchedData();
  const { t } = useTranslation();
  const { updateOrderAdjustmentModal } = useOpenOrderAdjustmentModal();
  const { deleteOrderAdjustment } = useDeleteOrderAdjustment({ orderUid });
  const formatCurrency = useFormatCurrency();

  const { adjustmentItems, currency } = lead.order;

  const getAdjustmentItems = (otherAdjustments): PriceDetailsItem[] => {
    return otherAdjustments.map((adjustment: AdjustmentItem) => {
      const { adjustmentType, amount, uid } = adjustment;

      return {
        label: t(`componentOrder.adjustmentType.${adjustmentType}`),
        amount,
        onEdit: () =>
          updateOrderAdjustmentModal({
            orderUid,
            lead,
            adjustmentItem: adjustment,
          }),
        onDelete: () =>
          openConfirmModal({
            title: t('componentOrderAdjustment.deleteModal.title'),
            body: (
              <Trans
                i18nKey="componentOrderAdjustment.deleteModal.body"
                values={{
                  adjustmentType: t(
                    `componentOrderAdjustment.adjustmentType.${adjustmentType}`,
                  ),
                  adjustmentAmount: formatCurrency({
                    value: amount,
                    currency,
                  }),
                }}
                components={{ highlight: <TextHighlightGreen /> }}
              />
            ),
            confirmColor: 'danger',
            onConfirm: () => {
              deleteOrderAdjustment({
                uid,
                successCallback: () => {
                  queryClient.invalidateQueries([
                    LEAD_GQL_QUERY_BASE,
                    lead.uid,
                  ]);
                },
              });
            },
          }),
      };
    });
  };

  return (
    <PriceDetailsList data-testid="price-details-list-adjustment-items">
      <OrderViewPriceDetailsListItem
        key="adjustments-total-amount"
        title={t('componentOrder.adjustments')}
        priceDetailsItems={[
          ...getAdjustmentItems(adjustmentItems),
          {
            label: t('componentOrder.totalAdjustments'),
            amount: adjustmentItems.reduce(
              (acc, adjustmentItem) => acc + adjustmentItem.amount,
              0,
            ),
            isTotalAmount: true,
          },
        ]}
      />
    </PriceDetailsList>
  );
};

export default AdjustmentItems;
