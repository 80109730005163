import { useTranslation } from 'react-i18next';
import { Lead, LeadStatus } from 'models/Leads';
import { LeadV3Source } from 'models/LeadsV3';
import { PinCode } from 'models/PinCode';
import { ReviewByHost } from 'models/Reviews';
import { Tag } from 'models/Tags';
import { getCountryCode } from 'utils/languageUtils';
import {
  getLeadArrivalDate,
  getLeadCheckInDate,
  getLeadCheckOutDate,
  getLeadDepartureDate,
} from 'utils/lead/leadUtils';
import { CustomData } from 'models/CustomData';
import { CreateLeadModalParams } from './LeadModal.types';

const useLeadModalFormInitialValue = () => {
  const {
    i18n: { language },
  } = useTranslation();

  return ({
    lead,
    leadForRebooking,
    leadTags,
    params,
    pinCode,
    reviewByHost,
    leadCustomData,
    defaultPropertyUid,
  }: {
    lead: Lead | undefined;
    leadForRebooking: Lead | undefined;
    leadTags: Tag[] | undefined;
    params: CreateLeadModalParams;
    pinCode: PinCode | undefined;
    reviewByHost: ReviewByHost | undefined;
    leadCustomData: CustomData[] | undefined;
    defaultPropertyUid: string | undefined;
  }) => {
    if (lead) {
      const {
        address,
        address2,
        adultCount,
        assignee,
        cellPhoneNumber,
        childrenCount,
        city,
        countryCode,
        creationMetadata,
        email,
        externalBookingID,
        extraNotes,
        firstName,
        flightNumber,
        guest,
        infantCount,
        lastName,
        notes,
        petCount,
        phoneNumber,
        preferredLocale,
        property: { uid: propertyUid },
        reasonForTrip,
        rentalAgreementURL,
        reviewByHost: leadReviewByHost,
        secondaryEmail,
        source,
        state,
        status,
        uid,
        zipCode,
        order,
        unitUid,
      } = lead;
      const checkInDate = getLeadCheckInDate(lead);
      const checkOutDate = getLeadCheckOutDate(lead);
      const arrivalDate = getLeadArrivalDate(lead);
      const departureDate = getLeadDepartureDate(lead);

      return {
        address,
        address2,
        adultCount,
        arrivalDate,
        assigneeUid: assignee?.uid,
        cellPhoneNumber,
        childrenCount,
        checkInDate,
        checkOutDate,
        city,
        countryCode: countryCode || getCountryCode(language) || 'US',
        departureDate,
        email,
        extraNotes,
        firstName,
        flightNumber,
        infantCount,
        lastName,
        notes,
        phoneNumber,
        petCount,
        pinCode,
        preferredLocale,
        propertyUid,
        reasonForTrip,
        reviewByHost: reviewByHost || leadReviewByHost,
        secondaryEmail,
        source,
        state,
        status,
        tags: leadTags,
        customData: leadCustomData,
        uid,
        overrides: null,
        zipCode,
        guest,
        rentalAgreementURL,
        externalBookingID,
        creationMetadata,
        force100PercentPaymentAtReservation:
          order?.force100PercentPaymentAtReservation,
        unitUid,
      };
    }

    const { dateFrom, dateTo, propertyUid } = params;
    const {
      address,
      address2,
      adultCount,
      cellPhoneNumber,
      childrenCount,
      city,
      countryCode,
      email,
      extraNotes,
      firstName,
      flightNumber,
      infantCount,
      lastName,
      notes,
      petCount,
      phoneNumber,
      preferredLocale,
      property,
      reasonForTrip,
      secondaryEmail,
      state,
      zipCode,
    } = leadForRebooking || {};

    return {
      adultCount: adultCount || 1,
      arrivalDate: dateFrom,
      checkInDate: dateFrom,
      checkOutDate: dateTo,
      childrenCount: childrenCount || 0,
      countryCode: countryCode || getCountryCode(language) || 'US',
      departureDate: dateTo,
      infantCount: infantCount || 0,
      petCount: petCount || 0,
      propertyUid: property?.uid || propertyUid || defaultPropertyUid,
      source: LeadV3Source.HOSTFULLY_UI,
      status: leadForRebooking
        ? LeadStatus.BOOKED_BY_AGENT
        : LeadStatus.ON_HOLD,
      overrides: null,
      address,
      address2,
      cellPhoneNumber,
      city,
      email,
      extraNotes,
      firstName,
      flightNumber,
      lastName,
      notes,
      phoneNumber,
      preferredLocale,
      reasonForTrip,
      secondaryEmail,
      state,
      zipCode,
    };
  };
};

export default useLeadModalFormInitialValue;
