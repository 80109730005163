import { useWatch } from 'react-hook-form';
import { classNames } from 'utils/classNameUtils';
import { TextFieldCharacterCounterContainer } from './TextFieldCharacterCounter.styles';

const TextFieldCharacterCounter = ({
  name,
  maxCharacters,
}: {
  name: string;
  maxCharacters: number;
}) => {
  const currentValue = useWatch({ name });
  const currentCount = currentValue?.length || 0;

  return (
    <TextFieldCharacterCounterContainer data-testid="character-counter">
      <span
        className={classNames({ 'text-danger': currentCount > maxCharacters })}
      >
        {currentCount}
      </span>
      {` / ${maxCharacters}`}
    </TextFieldCharacterCounterContainer>
  );
};

export default TextFieldCharacterCounter;
