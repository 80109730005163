import { useTranslation } from 'react-i18next';
import useNotify from './useNotify';

const useCopyToClipboard = () => {
  const { t } = useTranslation();
  const { notifySuccess, notifyError } = useNotify();

  const copyToClipBoard = async (link: string, message?: string) => {
    try {
      await navigator.clipboard.writeText(link);
      if (message) {
        notifySuccess(message);
      }
    } catch (err) {
      notifyError(t('common.somethingWentWrong'));
    }
  };

  return { copyToClipBoard };
};

export default useCopyToClipboard;
