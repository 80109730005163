export const LEGACY_PAGES_PRIVATE = [
  {
    pageId: 'CALENDAR',
    path: 'calendar',
    iframeSrc: '/agency_calendar.jsp',
  },
  {
    pageId: 'DASHBOARD',
    path: 'dashboard',
    iframeSrc: '/dashboard_agency.jsp',
  },
  {
    pageId: 'PIPELINE',
    path: 'pipeline',
    iframeSrc: '/pipeline.jsp',
  },
  {
    pageId: 'PROPERTIES',
    path: 'properties',
    iframeSrc: '/properties.jsp',
  },
  {
    pageId: 'PUBLISHING',
    path: 'publishing',
    iframeSrc: '/publishing.jsp',
  },
  {
    pageId: 'REPORTS',
    path: 'reports',
    iframeSrc: '/reports/index.jsp',
  },
  {
    pageId: 'CALENDAR_IMPORT',
    path: 'calendar-import',
    iframeSrc: '/calendarImport.jsp',
  },
  {
    pageId: 'INTEGRATIONS',
    path: 'integrations',
    iframeSrc: '/integrations.jsp',
  },
  {
    pageId: 'CHANNELS',
    path: 'channels',
    iframeSrc: '/channels.jsp',
  },
  {
    pageId: 'MY_TEMPLATES',
    path: 'my-templates',
    iframeSrc: '/my-templates.jsp',
  },
  {
    pageId: 'OWNERS',
    path: 'owners',
    iframeSrc: '/owners.jsp',
  },
  {
    pageId: 'AGENCY_SETTINGS',
    path: 'agency-settings',
    iframeSrc: '/agency.jsp',
  },
  {
    pageId: 'PROPERTY_ADD',
    path: 'property/add',
    iframeSrc: '/property_add.jsp',
  },
  {
    pageId: 'PROPERTY_EDIT',
    path: 'property',
    iframeSrc: '/property_edit.jsp',
  },
  {
    pageId: 'CHANNELS_SYNC_DEFAULT',
    path: 'channels/default/sync',
    iframeSrc: '/channels/default/synch.jsp',
  },
  {
    pageId: 'CHANNELS_SYNC_AIRBNB',
    path: 'channels/airbnb/sync',
    iframeSrc: '/channels/airbnb/synch.jsp',
  },
  {
    pageId: 'CHANNELS_SYNC_BOOKING_COM',
    path: 'channels/bookingdotcom/sync',
    iframeSrc: '/channels/bookingdotcom/synch.jsp',
  },
  {
    pageId: 'CHANNELS_SYNC_VRBO',
    path: 'channels/vrbo/sync',
    iframeSrc: '/channels/homeaway/synch.jsp',
  },
  {
    pageId: 'SETTINGS',
    path: 'settings',
    iframeSrc: '/settings.jsp',
  },
  {
    pageId: 'TURNOVER_MANAGEMENT',
    path: 'turnover-management',
    iframeSrc: '/turnover_management.jsp',
  },
  {
    pageId: 'CLIENT_INFO',
    path: 'client',
    iframeSrc: '/client.jsp',
  },
  {
    pageId: 'CLIENTS',
    path: 'clients',
    iframeSrc: '/my-clients.jsp',
  },
  {
    pageId: 'ORDER_VIEW',
    path: 'order-view',
    iframeSrc: '/order_view.jsp',
  },
  {
    pageId: 'UNIT_TYPE',
    path: 'unit-type',
    iframeSrc: '/unit_type_edit.jsp',
  },
  {
    pageId: 'HOTEL_ADD',
    path: 'hotel/add',
    iframeSrc: '/hotel_add.jsp',
  },
  {
    pageId: 'HOTEL_EDIT',
    path: 'hotel',
    iframeSrc: '/hotel_edit.jsp',
  },

  // Guests:
  {
    pageId: 'DASHBOARD_GUEST',
    path: 'guest/dashboard',
    iframeSrc: '/g/dashboard_guest.jsp',
  },
  {
    pageId: 'ACCOUNT_PREFERENCES_GUEST',
    path: 'guest/account',
    iframeSrc: '/g/account.jsp',
  },
  {
    pageId: 'GUEST_BOOKING_VIEW',
    path: 'guest/booking-view',
    iframeSrc: '/g/booking_view.jsp',
  },
  // Service Provider:
  {
    pageId: 'DASHBOARD_SERVICE_PROVIDER',
    path: 'service-provider/dashboard',
    iframeSrc: '/sp/dashboard_serviceprovider.jsp',
  },
  {
    pageId: 'PROPERTIES_SERVICE_PROVIDER',
    path: 'service-provider/properties',
    iframeSrc: '/sp/properties.jsp',
  },
  {
    pageId: 'ACCOUNT_SERVICE_PROVIDER',
    path: 'service-provider/account',
    iframeSrc: '/sp/account.jsp',
  },
  {
    pageId: 'HOTEL_EDIT',
    path: 'hotel',
    iframeSrc: '/hotel_edit.jsp',
  },
];

export const LEGACY_PAGES_PUBLIC = [
  {
    pageId: 'SIGNUP',
    path: 'signup',
    iframeSrc: '/onboarding/agency/wizard/signup.jsp',
  },
  {
    pageId: 'SIGNUP_BOARDING',
    path: 'signup-boarding',
    iframeSrc: '/onboarding/agency/wizard/boarding.jsp',
  },
];

export const LEGACY_PAGES = [...LEGACY_PAGES_PRIVATE, ...LEGACY_PAGES_PUBLIC];

export const LEGACY_PAGES_SRC_TO_PATH = LEGACY_PAGES.reduce(
  (previousValue, currentValue) => {
    return {
      ...previousValue,
      [currentValue.iframeSrc.substring(1)]: currentValue.path,
    };
  },
  {},
);

export const LEGACY_PAGES_PARAM_BASED_PATH_PROVIDERS = {
  'layer.jsp': (page: string) => {
    if (page === 'discounts') {
      return 'discount-codes';
    }

    return page || 'inbox'; // 'inbox' and 'third-party-agency' have identical paths; defaults to inbox
  },
};
