import { useContext } from 'react';
import TextField from 'components/forms/textField/TextField';
import { CustomDataFieldType } from 'models/CustomDataField';
import LeadModalContext from '../../LeadModalContext';
import { LeadDataFormRow } from './LeadModalDataTab.styles';

const LeadCustomData = () => {
  const { leadCustomData } = useContext(LeadModalContext);

  return (
    <>
      {leadCustomData.map((customData, i) => (
        <LeadDataFormRow key={customData.customDataField.uid}>
          <TextField
            label={customData.customDataField.name}
            name={`customData[${i}].text`}
            colSmInput={5}
            colSmLabel={3}
            row={
              customData.customDataField.type === CustomDataFieldType.LONG_TEXT
                ? 3
                : 1
            }
            componentClass={
              customData.customDataField.type === CustomDataFieldType.LONG_TEXT
                ? 'textarea'
                : 'input'
            }
            maxLength={
              customData.customDataField.type === CustomDataFieldType.TEXT
                ? 255
                : ''
            }
          />
        </LeadDataFormRow>
      ))}
    </>
  );
};

export default LeadCustomData;
