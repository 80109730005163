import styled from 'styled-components';
import theme from 'styles/theme';

export const PageHeaderContainer = styled.div`
  margin-bottom: 32px;

  h1 {
    display: flex;
    align-items: end;
    justify-content: space-between;
    margin: 0;

    span {
      font-size: 2rem;
      font-weight: 300;
      line-height: 1.1em;
    }

    span.green {
      color: ${theme.colors.hostfullyGreen};
    }

    .form-group {
      margin-bottom: 0;
    }
  }

  h2 {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.1em;
    margin: 12px 0 0 0;
  }

  .divider {
    margin: 16px 0;
    height: 1px;
    background-color: #e0e0e0;
  }

  .help-block {
    font-size: 14px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: #3333338c;
  }

  .page-header-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & > * {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    a {
      font-size: 14px;
    }
  }
`;
